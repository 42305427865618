import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Base} from './base';

@Injectable({
    providedIn: 'root'
})

export class MerchantService {
    api_url = Base.api_url;

    constructor(public http: HttpClient) {
    }

    public merchants(): Observable<any> {
        return this.http.get(this.api_url + '/users/merchants', this.requestHeader());
    }

    public merchantRequest(data): Observable<any> {
        return this.http.post(this.api_url + '/users/merchantship_request', data, this.requestHeader());
    }

    private requestHeader() {
        return {headers: {Authorization: `Bearer ${Base.getAuthToken()}`}};
    }
}
