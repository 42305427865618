import { Component, OnInit, Input } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {RatingService} from '../../../services/rating.service';
import {HelperService} from '../../../services/helper.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  @Input() form: any;
  @Input() user_id: any;
  @Input() username: any;
  review = {rating: 0, reviewable_type: 'User', reviewable_id: '', comment: ''};
  reviews = [];

  constructor(public modalCtrl: ModalController, private rateService: RatingService, public helperService: HelperService) { }

  ngOnInit() {
    if(this.form) {
      this.newReview();
    }
    else {
      this.loadReviews();
    }
  }

  public closeModal() {
    this.modalCtrl.dismiss({});
  }

  public submitReview() {
    if(this.review.rating > 0) {
      this.rateService.create(this.review).subscribe(resp => {
        if(resp.success) {
          this.modalCtrl.dismiss({score: resp.score});
          this.helperService.showAlert('Thank you for your review', 'success');
        }
        else {
          this.helperService.showAlert(resp.message);
        }
      }, err => {
        this.helperService.showAlert('Unable to submit review this moment!');
      });
    }
  }

  public loadReviews() {
    this.rateService.index(this.user_id).subscribe(resp => {
      this.reviews = resp.reviews;
      this.review.rating = resp.score;
    }, err => {
      this.helperService.showToast('Unable to load reviews this moment');
    });
  }

  public newReview() {
    this.rateService.new(this.user_id).subscribe(resp => {
      this.review = resp.review;
    }, err => {});
  }

  public rateIt(number) {
    this.review.rating = number;
  }

}
