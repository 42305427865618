export class Base {
  static api_url = "http://webapi.inweapp.com/api/v1";
  static base_url = "http://webapi.inweapp.com/";
  // static api_url = 'https://api2.inweapp.com/api/v1';
  // static base_url = 'https://api2.inweapp.com/';
  // static api_url = 'http://staging.api.inweapp.com/api/v1';
  // static base_url = 'http://staging.api.inweapp.com/';
  // static api_url = "http://192.168.0.147:3000/api/v1";
  // static base_url = "http://192.168.0.147:3000/";

  static getAuthToken() {
    let auth = JSON.parse(window.localStorage.getItem("auth"));
    return auth ? auth.auth_token : "";
  }

  static requestHeader() {
    return { headers: { Authorization: `Bearer ${Base.getAuthToken()}` } };
  }
}
