import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { Camera } from "@ionic-native/camera/ngx";
import { TimeAgoPipe } from "./time-ago-pipe/time-ago-pipe";
import { HashTagPipe } from "../pipe/hashtag";
import { BackgroundMode } from "@ionic-native/background-mode/ngx";
import { Network } from "@ionic-native/network/ngx";
import "hammerjs";
import { HammerModule } from "@angular/platform-browser";
import { CustomErrorHandler } from "../services/errorhandler.service";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicSelectableModule } from "ionic-selectable";
import { HelperService } from "../services/helper.service";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { PostService } from "../services/post.service";
import { GiftService } from "../services/gift.service";
import { ChatRoomService } from "../services/chat-room.service";
import { ChatService } from "../services/chat.service";
import { EmailService } from "../services/email.service";
import { Device } from "@ionic-native/device/ngx";


import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HomePage } from "./home/home.page";
import { WelcomeComponent } from "./welcome/welcome.component";
import { AuthGuard } from "../services/auth.guard";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { ProfileComponent } from "./profile/profile.component";
import { NotificationComponent } from "./notification/notification.component";
import { NotificationListComponent } from "./notification/notification-list/notification-list.component";
import { MentionsComponent } from "./notification/mentions/mentions.component";
import { UsersComponent } from "./users/users.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { EditProfilePage } from "./edit-profile/edit-profile.page";
import { FriendsComponent } from "./friends/friends.component";
import { FeedComponent } from "./feed/feed.component";
import { AccountComponent } from "./account/account.component";
import { ExploreComponent } from "./explore/explore.component";
import { FriendRequestComponent } from "./friends/request/request.component";
import { FriendListComponent } from "./friends/list/list.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { TransferCoinComponent } from "./transfer-coin/transfer-coin.component";
import { AnnouncementsComponent } from "./announcements/announcements.component";
import { GiftStoresComponent } from "./gift-stores/gift-stores.component";
import { GiftDetailsComponent } from "./gift-stores/details/details.component";
import { ChatRoomsComponent } from "./chat-rooms/chat-rooms.component";
import { RoomComponent } from "./chat-rooms/room/room.component";
import { EmojiPickerComponent } from "./component/emoji-picker/emoji-picker.component";
import { RoomSearchComponent } from "./chat-rooms/search/search.component";
import { SettingsComponent } from "./settings/settings.component";
import { ParticipantsComponent } from "./chat-rooms/participants/participants.component";
import { CommandComponent } from "./command/command.component";
import { AnnouncementDetailsComponent } from "./announcements/details/details.component";
import { EmailsComponent } from "./emails/emails.component";
import { EmailDetailsComponent } from "./emails/details/details.component";
import { ComposeComponent } from "./emails/compose/compose.component";
import { RoomInfoComponent } from "./chat-rooms/room-info/room-info.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ChatLayoutComponent } from "./chat-layout/chat-layout.component";
import { PopoverComponent } from "./component/popover/popover.component";
import { AccountActivationComponent } from "./account-activation/account-activation.component";
import { ResendActivationComponent } from "./resend-activation/resend-activation.component";
import { RatingComponent } from "./user-profile/rating/rating.component";
import { CreateRoomComponent } from "./chat-rooms/create-room/create-room.component";
import { SecurityQuestionComponent } from "./settings/security-question/security-question.component";
import { MerchantPanelComponent } from "./merchant-panel/merchant-panel.component";
import { LeaderBoardComponent } from "./leader-board/leader-board.component";
import { ProgressLevelComponent } from "./progress-level/progress-level.component";
import { PeopleComponent } from "./people/people.component";
import { PeopleListComponent } from "./people/people-list/people-list.component";
import { BlockListComponent } from "./settings/block-list/block-list.component";
import { BlockService } from "../services/block.service";
import { MyHammerConfig } from "../services/myhammerconfig";
import { LeaderBoardDetailsComponent } from "./leader-board-details/leader-board-details.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { UpdateOnlineStatusComponent } from "./component/update-online-status/update-online-status.component";
import { GiftsComponent } from "./gifts/gifts.component";
import { FootprintsComponent } from "./footprints/footprints.component";
import { RoomSectionComponent } from "./chat-rooms/room-section/room-section.component";
import { RoomActionsComponent } from "./component/room-actions/room-actions.component";
import { LegendCampaignComponent } from "./legend-campaign/legend-campaign.component";
import {
  InjectableRxStompConfig,
  RxStompService,
  rxStompServiceFactory,
} from "@stomp/ng2-stompjs";
import { myRxStompConfig } from "./my-rx-stomp.config";

import { EmojiPipe } from "src/pipe/emoji_pipe";
import { FeedOptionComponent } from "./component/feed-option/feed-option.component";
@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    WelcomeComponent,
    LoginComponent,
    SignupComponent,
    ProfileComponent,
    NotificationComponent,
    NotificationListComponent,
    MentionsComponent,
    LeaderBoardComponent,
    UsersComponent,
    ForgotPasswordComponent,
    ImageViewerComponent,
    EditProfilePage,
    FriendsComponent,
    FeedComponent,
    AccountComponent,
    ExploreComponent,
    FriendRequestComponent,
    FriendListComponent,
    UserProfileComponent,
    TransferCoinComponent,
    AnnouncementsComponent,
    GiftStoresComponent,
    SecurityQuestionComponent,
    GiftDetailsComponent,
    ChatRoomsComponent,
    RoomComponent,
    EmojiPickerComponent,
    RoomSearchComponent,
    HashTagPipe,
    EmojiPipe,
    TimeAgoPipe,
    RoomInfoComponent,
    CreateRoomComponent,
    SettingsComponent,
    ParticipantsComponent,
    CommandComponent,
    AnnouncementDetailsComponent,
    EmailsComponent,
    EmailDetailsComponent,
    ComposeComponent,
    MerchantPanelComponent,
    FeedbackComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    ChatLayoutComponent,
    PopoverComponent,
    AccountActivationComponent,
    ResendActivationComponent,
    RatingComponent,
    ProgressLevelComponent,
    PeopleComponent,
    PeopleListComponent,
    BlockListComponent,
    LeaderBoardDetailsComponent,
    AlertsComponent,
    GiftsComponent,
    FootprintsComponent,
    RoomSectionComponent,
    UpdateOnlineStatusComponent,
    RoomActionsComponent,
    FeedOptionComponent,
    LegendCampaignComponent,
  ],
  entryComponents: [
    ImageViewerComponent,
    EmojiPickerComponent,
    ComposeComponent,
    RoomInfoComponent,
    PopoverComponent,
    RoomSearchComponent,
    RatingComponent,
    CreateRoomComponent,
    SecurityQuestionComponent,
    EmailDetailsComponent,
    ProgressLevelComponent,
    PeopleListComponent,
    BlockListComponent,
    FeedOptionComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    IonicSelectableModule,
  ],
  providers: [
    StatusBar,
    AuthGuard,
    HelperService,
    AuthService,
    SplashScreen,
    Camera,
    UserService,
    PostService,
    GiftService,
    ChatRoomService,
    ChatService,
    EmailService,
    BackgroundMode,
    Device,
    BlockService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    Network,
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: InjectableRxStompConfig,
      useValue: myRxStompConfig,
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
