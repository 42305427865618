import {
  Component,
  ViewChild,
  forwardRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IonSlides, PopoverController } from "@ionic/angular";
import { TransferService } from "src/services/transfer.service";
import { EmojiService } from "../../../services/emoji.service";
import { SendStickerComponent } from "../send-sticker/send-sticker.component";

export const EMOJI_PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EmojiPickerComponent),
  multi: true,
};
@Component({
  selector: "emoji-picker",
  templateUrl: "./emoji-picker.component.html",
  styleUrls: ["./emoji-picker.component.scss"],
})
export class EmojiPickerComponent implements ControlValueAccessor {
  @Input() chatRoomId: number;
  @Output() select = new EventEmitter(); // add this
  @ViewChild("emojiSlides") emojiSlides: IonSlides;
  emojiTabs = [];
  emojiItems = [];
  activeTab = 0;
  _content: string;
  isClicked = false;
  _onChanged = (_: any) => {};
  _onTouched = (_: any) => {};

  constructor(
    private emojiService: EmojiService,
    public popoverController: PopoverController,
    public transferService: TransferService
  ) {
    this.emojiTabs = this.emojiService.getEmojiTabs();
    this.emojiItems = this.emojiService.getEmojis();
  }

  changeSlide(event) {
    event.stopPropagation();
    this.isClicked = false;
  }

  ngAfterViewInit() {
    this.updateSlider();
  }

  updateSlider() {
    setTimeout(() => {
      this.emojiSlides.update();
    }, 5000);
  }

  changeTab(index: number): void {
    this.isClicked = true;
    if (index < 7) {
      this.activeTab = index;
      this.emojiSlides.slideTo(this.activeTab);
    }
  }

  goToPrevSlide() {
    if (this.activeTab > 0 && !this.isClicked) {
      --this.activeTab;
    }
  }

  goToNextSlide() {
    if (this.activeTab < 5 && !this.isClicked) {
      ++this.activeTab;
    }
  }

  writeValue(obj: any): void {
    this._content = obj;
  }

  registerOnChange(fn: any): void {
    this._onChanged = fn;
    this.setValue(this._content);
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setValue(val: any): any {
    this._content += val;
    if (this._content) {
      this._onChanged(this._content);
      this.select.emit(val); // emit the event on click event
    }
  }

  async onLongPress(event, imgPath: string) {
    imgPath = imgPath.replace("/assets/emoji/", "").replace(".png", "");

    const name = imgPath.split("_")[0];

    if (name === "uni") {
      return;
    }

    const popover = await this.popoverController.create({
      component: SendStickerComponent,
      event,
      cssClass: "send-sticker-option",
      componentProps: { chatRoomId: this.chatRoomId, imgPath },
    });

    await popover.present();
  }
}
