import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Base } from "./base";

@Injectable({
  providedIn: "root",
})
export class PostService {
  apiUrl = Base.api_url;
  isLoggedIn: boolean;

  constructor(public http: HttpClient) {}

  public all(page): Observable<any> {
    return this.http.get(
      this.apiUrl + "/posts?page=" + page,
      this.requestHeader()
    );
  }

  public create(data): Observable<any> {
    return this.http.post(
      this.apiUrl + "/posts",
      { post: data },
      this.requestHeader()
    );
  }

  public comment(post): Observable<any> {
    return this.http.post(
      this.apiUrl + "/posts/" + post.id + "/comments",
      { comment: { content: post.new_comments } },
      this.requestHeader()
    );
  }

  public like(post_id): Observable<any> {
    return this.http.post(
      this.apiUrl + "/posts/" + post_id + "/likes",
      { post_id: post_id },
      this.requestHeader()
    );
  }

  public report(data): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/users/feedback`,
      data,
      this.requestHeader()
    );
  }

  public delete(postId): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}/posts/${postId}`,
      this.requestHeader()
    );
  }

  private requestHeader() {
    return { headers: { Authorization: `Bearer ${Base.getAuthToken()}` } };
  }
}
