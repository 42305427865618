import { Component, OnInit, Input } from '@angular/core';
import { EmailService } from '../../../services/email.service';
import {HelperService} from '../../../services/helper.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Base} from '../../../services/base';
import {LoaderService} from '../../../services/loader.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class EmailDetailsComponent implements OnInit {
  email: any;
  show_header = false;
  @Input() email_id: string;

  constructor(public emailService: EmailService, public helperService: HelperService, public spinner: LoaderService,
              public route: ActivatedRoute, public router: Router, private modalCtrl: ModalController) { }

  ngOnInit() {
    this.loadEmail(this.email_id);
  }

  public loadEmail(id) {
    this.spinner.show('', 'Loading email...');
    this.emailService.show(id).subscribe(resp => {
      this.spinner.hide();
      this.email = resp.email;
    }, err => {
      this.spinner.hide();
      this.helperService.showToast('Unable to load email');
    });
  }

  public delete(id) {
    this.spinner.show('', 'Deleting...');
    this.emailService.remove(id).subscribe(resp => {
      this.spinner.hide();
      if(resp.success) {
        this.closeModal({email: this.email_id});
      }
      else {
        this.helperService.showToast('Unable to delete email');
      }
    },err => {
      this.spinner.hide();
      this.helperService.showToast('Unable to delete email');
    });
  }

  public sendReply(is_reply) {
    this.router.navigate(['/emails/new'], {queryParams: {email_id: this.email.id, reply: is_reply}});
    this.closeModal();
  }

  public closeModal(data = {}) {
    this.modalCtrl.dismiss(data);
  }
}
