export class App {
  static role(id) {
    switch(id) {
        case 0:
            return 'owner';
        case 1:
            return 'admin';
        case 2:
            return 'mentor';
        case 3:
            return 'member';
        default:
            return 'member';
    }
  }
}
