import { Injectable, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventService {
  dispatcher: EventEmitter<any> = new EventEmitter();
  notificationDispatcher: EventEmitter<any> = new EventEmitter();
  chatRoomDispatcher: EventEmitter<any> = new EventEmitter();
  chatNotificationDispatcher: EventEmitter<any> = new EventEmitter();
  updateUser: EventEmitter<any> = new EventEmitter();
  update_balance = new Subject();
  friends = new Subject();

  constructor() {}

  emitAuthEvent(data) {
    this.dispatcher.emit(data);
  }

  emitChatRoom(type, chat_room = {}) {
    this.chatRoomDispatcher.emit({ type: type, room: chat_room });
  }

  emitChatNotification(room_id) {
    this.chatNotificationDispatcher.emit(room_id);
  }

  emitNotificationEvent(data) {
    this.notificationDispatcher.emit(data);
  }

  emitUpdateUser(user) {
    this.updateUser.emit(user);
  }

  getEmitter() {
    return this.dispatcher;
  }
}
