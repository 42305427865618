import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { ChatRoomService } from "../../../services/chat-room.service";
import { HelperService } from "../../../services/helper.service";
import { EventService } from "../../../services/event.service";
import { LoaderService } from "../../../services/loader.service";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.scss"],
})
export class PopoverComponent implements OnInit {
  @Input() user_id: string;
  @Input() username: string;

  constructor(
    private router: Router,
    public popover: PopoverController,
    public chatRoom: ChatRoomService,
    private spinner: LoaderService,
    public helperService: HelperService,
    public eventService: EventService
  ) {}

  ngOnInit() {}

  public transferCoin() {
    this.popover.dismiss();
    this.router.navigate(["/transfer-coin"], {
      queryParams: { username: this.username },
    });
  }

  public sendEmail() {
    this.popover.dismiss();
    this.router.navigate(["/emails/new"], {
      queryParams: { username: this.username },
    });
  }

  public gotoProfile() {
    this.popover.dismiss();
    this.router.navigateByUrl("/profile/" + this.user_id);
  }

  async goChat() {
    this.popover.dismiss();

    this.chatRoom.personal_chat(this.username).subscribe(
      (resp) => {
        if (resp.success) {
          let active_rooms = this.helperService.rooms();
          let active_room = active_rooms.filter(
            (room) => room.id == resp.chat_room.id
          );
          if (active_room.length > 0) {
            this.eventService.emitChatRoom("exist", active_room[0]);
          } else {
            this.helperService.addRoom(resp.chat_room);
          }
        } else {
          this.helperService.showToast(resp.errors);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to create chat this moment");
      }
    );
  }
}
