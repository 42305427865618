import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  AlertController,
  ModalController,
  PopoverController,
} from "@ionic/angular";
import { ParticipantsComponent } from "src/app/chat-rooms/participants/participants.component";
import { RoomInfoComponent } from "src/app/chat-rooms/room-info/room-info.component";
import { ChatService } from "src/services/chat.service";
import { HelperService } from "src/services/helper.service";
import { LoaderService } from "src/services/loader.service";
import { UserService } from "src/services/user.service";

@Component({
  selector: "app-room-actions",
  templateUrl: "./room-actions.component.html",
  styleUrls: ["./room-actions.component.scss"],
})
export class RoomActionsComponent implements OnInit {
  @Input() chatRoom: any;
  @Input() username: string;

  constructor(
    public router: Router,
    public spinnerDialog: LoaderService,
    public userService: UserService,
    public helperService: HelperService,
    public chatService: ChatService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private popoverController: PopoverController
  ) {}

  ngOnInit() {}

  async dismissClick() {
    await this.popoverController.dismiss();
  }

  async roomInfo() {
    this.dismissClick();
    this.modalCtrl
      .create({
        component: RoomInfoComponent,
        componentProps: {
          room_id: this.chatRoom.id,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }

  public loadBalance() {
    this.dismissClick();
    this.userService.balance().subscribe(
      (resp) => {
        this.checkBalance(resp.balance);
      },
      (err) => {
        this.helperService.showToast("Unable to load balance this moment");
      }
    );
  }

  async checkBalance(amount) {
    this.dismissClick();
    const alert = await this.alertCtrl.create({
      header: "Account balance",
      message: "Your available balance is " + amount,
      mode: "ios",
      buttons: ["ok"],
    });
    await alert.present();
  }

  public loadParticipants(kick = false) {
    this.dismissClick();
    this.modalCtrl
      .create({
        component: ParticipantsComponent,
        cssClass: "half-screen-modal",
        componentProps: {
          room_id: this.chatRoom.id,
          name: this.chatRoom.name,
          kick: kick,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }

  public leaveRoom() {
    this.dismissClick();
    this.spinnerDialog.show("", "Leaving room");
    this.chatService.leave(this.chatRoom.id).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.helperService.removeRoom(this.chatRoom.id);
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        if (err.status === 404) {
          this.helperService.removeRoom(this.chatRoom.id);
        } else {
          this.helperService.showToast(
            "Unable to leave the room, Please try after sometimes!"
          );
        }
      }
    );
  }

  public viewProfile() {
    this.router.navigateByUrl("/profile/" + this.username);
  }

  public closeChat() {
    this.dismissClick();
    this.spinnerDialog.show("", "Closing chat...");
    this.chatService.closeChat(this.chatRoom.id).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.helperService.removeRoom(this.chatRoom.id);
        } else {
          this.helperService.showToast(resp.errors);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showToast("Unable to close chat this moment!");
      }
    );
  }
}
