import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Base } from "./base";
import { ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { EventService } from "./event.service";
import { HelperService } from "./helper.service";
import { ChatRoomService } from "./chat-room.service";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  api_url = Base.api_url;

  constructor(
    public http: HttpClient,
    private toastController: ToastController,
    private router: Router,
    private helperService: HelperService,
    private chatRoomService: ChatRoomService
  ) {}

  public send(data, id): Observable<any> {
    return this.http.post(
      this.api_url + "/chat_rooms/" + id + "/send_message",
      data,
      this.requestHeader()
    );
  }

  public leave(id): Observable<any> {
    return this.http.post(
      this.api_url + "/chat_rooms/" + id + "/leave",
      { room_id: id },
      this.requestHeader()
    );
  }

  public closeChat(room_id): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/" + room_id + "/close",
      this.requestHeader()
    );
  }

  async chatPopup(username, room_id) {
    const toast = await this.toastController.create({
      message: username + " sent you a message!",
      position: "bottom",
      color: "secondary",
      buttons: [
        {
          side: "end",
          icon: "chatboxes",
          text: "Chat",
          handler: () => {
            this.chatRoomService.personal_chat(username).subscribe(
              (resp) => {
                resp.success
                  ? this.helperService.addRoom(resp.chat_room)
                  : this.helperService.showToast(resp.errors);
              },
              (err) => {
                this.helperService.showToast(
                  "Unable join private chat this moment"
                );
              }
            );
          },
        },
        {
          icon: "close",
          role: "cancel",
          handler: () => {},
        },
      ],
    });
    toast.present();
  }

  public removeMessage(chatroom_id, message_id, delete_for): Observable<any> {
    return this.http.get(
      `${this.api_url}/chat_rooms/${chatroom_id}/delete_message/${message_id}/${delete_for}`,
      Base.requestHeader()
    );
  }

  public messageReaction(chatroom_id, message_id, react_type) {
    let data = { message_id: message_id, type: react_type }
    return this.http.post(
      `${this.api_url}/chat_rooms/${message_id}/reaction?chatroom_id=${chatroom_id}`,
      data,
      Base.requestHeader()
    );
  }

  private requestHeader() {
    return { headers: { Authorization: `Bearer ${Base.getAuthToken()}` } };
  }

  public checkCreateQueue(name) {
    return this.http.post(`${this.api_url}/check_create_queue`,  {q_name: name}, this.requestHeader())
  }
}
