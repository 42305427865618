import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TransferService {
  constructor() {}

  private statusUpdateSubject = new Subject<any>();
  private stickerData = new Subject<any>();
  private feedDelete = new Subject<any>();

  public setStatusSubject(appearance: string): void {
    this.statusUpdateSubject.next({ appearance });
  }

  public getStatusSubject(): Observable<any> {
    return this.statusUpdateSubject;
  }

  public setStickerData(data: any): void {
    this.stickerData.next(data);
  }

  public getStickerData(): Observable<any> {
    return this.stickerData;
  }

  public setFeedDelete(id: number): void {
    this.feedDelete.next({ id });
  }

  public getFeedDelete(): Observable<any> {
    return this.feedDelete;
  }
}
