import { Component, OnInit } from "@angular/core";
import { HelperService } from "../../services/helper.service";
import { LoaderService } from "../../services/loader.service";
import { UserService } from "../../services/user.service";
import { ModalController } from "@ionic/angular";
import { PeopleListComponent } from "./people-list/people-list.component";

@Component({
  selector: "app-people",
  templateUrl: "./people.component.html",
  styleUrls: ["./people.component.scss"],
})
export class PeopleComponent implements OnInit {
  people = [];
  peopleData = [
    {
      title: "Executive Board",
      type: "executive",
      imgUrl: "/assets/images/Executive.svg",
    },
    {
      title: "Country Representative",
      type: "staff",
      imgUrl: "/assets/images/staff.png",
    },
    {
      title: "Admin",
      type: "admin",
      imgUrl: "/assets/images/admin.png",
    },
    {
      title: "Mentor",
      type: "mentor",
      imgUrl: "/assets/images/mentor.png",
    },
    {
      title: "Merchant",
      type: "merchant",
      imgUrl: "/assets/images/merchant.png",
    },
    {
      title: "Shield Profile",
      type: "shield",
      imgUrl: "/assets/images/Shield.svg",
    },
    {
      title: "Top Level Users",
      type: "top_level",
      imgUrl: "/assets/images/Top Level.svg",
    },
    {
      title: "Legend Profile",
      type: "legend",
      imgUrl: "/assets/images/Legend.svg",
    },
  ];

  constructor(
    private helperService: HelperService,
    private userService: UserService,
    private spinner: LoaderService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  public loadPeople(title: string, type: string, page: number) {
    this.spinner.show("", `Loading ${title}...`);
    this.userService.people(type, page).subscribe(
      (resp) => {
        this.spinner.hide();
        this.people = resp.people;
        this.peopleList(title, type);
      },
      (err) => {
        this.spinner.hide();
        this.helperService.showToast("Unable to load people this moment!");
      }
    );
  }

  async peopleList(title, type) {
    const modal = await this.modalCtrl.create({
      component: PeopleListComponent,
      componentProps: {
        users: this.people,
        title,
        type,
      },
    });
    await modal.present();
  }
}
