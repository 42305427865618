import { InjectableRxStompConfig } from "@stomp/ng2-stompjs";

export const myRxStompConfig: InjectableRxStompConfig = {
  // Which server?
  brokerURL: "ws://159.89.196.51:15674/ws",
  // brokerURL: "ws://192.168.0.147:15674/ws",

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: "inwe_admin",
    passcode: "inwerbt2!",
  },

  // connectHeaders: {
  //   login: "tuhin_mq",
  //   passcode: "1122",
  // },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 20, // Typical value 0 - disabled
  heartbeatOutgoing: 500, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 500,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  // debug: (msg: string): void => {
  //   console.log(new Date(), msg);
  // },
};
