import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Base } from "./base";

@Injectable({
  providedIn: "root",
})
export class EmailService {
  api_url = Base.api_url;

  constructor(public http: HttpClient) {}

  public emails(type: string, page: number): Observable<any> {
    return this.http.get(
      `${this.api_url}/emails?type=${type}&page=${page}`,
      this.requestHeader()
    );
  }

  public unread(): Observable<any> {
    return this.http.get(this.api_url + "/emails/unread", this.requestHeader());
  }

  public send(data): Observable<any> {
    return this.http.post(
      this.api_url + "/emails",
      { email: data },
      this.requestHeader()
    );
  }

  public show(id): Observable<any> {
    return this.http.get(this.api_url + "/emails/" + id, this.requestHeader());
  }

  public remove(id): Observable<any> {
    return this.http.delete(
      this.api_url + "/emails/" + id,
      this.requestHeader()
    );
  }

  private requestHeader() {
    return { headers: { Authorization: `Bearer ${Base.getAuthToken()}` } };
  }
}
