import { ErrorHandler, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Base } from "./base";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomErrorHandler implements ErrorHandler {
  api_url = Base.api_url;

  constructor(public http: HttpClient) {}

  handleError(err: any): void {
    // this.sendNotification(err).subscribe(resp => {
    //     console.log('Sent exception notifier');
    //     console.log(err);
    // }, err => {
    //     console.log(err);
    // });
  }

  public sendNotification(error): Observable<any> {
    let errors = {
      subject: error,
      body: error,
    };
    if (typeof error === "object") {
      errors.body = error.stack.toString();
      errors.subject = error.message;
    }
    return this.http.post(this.api_url + "/error_handlers", { error: errors });
  }
}
