import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { HelperService } from "../../services/helper.service";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-friends",
  templateUrl: "./friends.component.html",
  styleUrls: ["./friends.component.scss"],
})
export class FriendsComponent implements OnInit {
  friend_request_count = 0;

  constructor(
    public usersProvider: UserService,
    public helperService: HelperService,
    public spinnerDialog: LoaderService,
    public router: Router
  ) {}

  ngOnInit() {
    this.loadFriendRequest();
  }

  public searchFriend() {
    this.router.navigateByUrl("/users");
  }

  public loadFriendRequest() {
    this.spinnerDialog.show("", "Loading friend list");
    this.usersProvider.friendRequest().subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        this.friend_request_count = resp.requested_friends.length;
      },
      (err) => {
        this.spinnerDialog.hide();
      }
    );
  }
}
