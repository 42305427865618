import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/services/loader.service";
import { UserService } from "src/services/user.service";

@Component({
  selector: "app-footprints",
  templateUrl: "./footprints.component.html",
  styleUrls: ["./footprints.component.scss"],
})
export class FootprintsComponent implements OnInit {
  loadMoreData: boolean = false;
  footprints = [];

  constructor(
    private userService: UserService,
    public spinnerDialog: LoaderService
  ) {}

  ngOnInit() {
    this.onGetFootprints();
  }

  onGetFootprints(): void {
    this.spinnerDialog.show("", "Loading footprints...");
    this.userService.getFootprints().subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        this.footprints = resp.footprints;
      },
      (err) => {
        this.spinnerDialog.hide();
      }
    );
  }
}
