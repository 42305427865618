import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { HelperService } from "../../services/helper.service";
import { LoaderService } from "../../services/loader.service";

declare var window: any;

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  userDetails = {
    login: "",
  };
  send_token = false;
  reset_password_token = "";
  reset_password = {
    password: "",
    password_confirmation: "",
  };

  isenabled = false;
  email = false;
  toast: any;

  constructor(
    public router: Router,
    public authProvider: AuthService,
    private spinnerDialog: LoaderService,
    public authService: AuthService,
    public helperService: HelperService
  ) {}

  ionViewWillEnter() {
    this.userDetails.login = "";
  }

  ngOnInit() {
    const auth = localStorage.getItem("auth");
    if (auth) {
      this.router.navigateByUrl("/home");
    }
  }

  forgotPassword() {
    this.spinnerDialog.show("", "Sending...");
    this.authService.forgotPassword(this.userDetails.login).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.helperService.showAlert(
            "Send you an email with forgot password instructions!",
            "success"
          );
          this.userDetails.login = "";
          this.send_token = true;
        } else {
          this.helperService.showAlert(resp.message);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showAlert(
          "Unable to send forgot password instruction this moment!"
        );
      }
    );
  }

  public submitResetPassword() {
    if (!this.reset_password_token) {
      this.helperService.showAlert("Enter reset password token");
    } else if (!this.reset_password.password) {
      this.helperService.showAlert("Enter new password");
    } else if (!this.reset_password.password_confirmation) {
      this.helperService.showAlert("Confirm new password");
    } else if (
      this.reset_password.password != this.reset_password.password_confirmation
    ) {
      this.helperService.showAlert(
        "New password and confirm password does't match"
      );
    } else {
      this.resetPassword();
    }
  }

  public resetPassword() {
    this.spinnerDialog.show("", "Sending...");
    this.authService
      .resetPassword(this.reset_password, this.reset_password_token)
      .subscribe(
        (resp) => {
          this.spinnerDialog.hide();
          if (resp.success) {
            this.helperService.showAlert(
              "Password changes successfully. Please login!",
              "success"
            );
            this.reset_password_token = "";
          } else {
            this.helperService.showAlert(resp.message);
          }
        },
        (err) => {
          this.spinnerDialog.hide();
          this.helperService.showAlert("Unable to reset password this moment!");
        }
      );
  }

  // Email validation
  emailInput(value) {
    this.isenabled = !(
      this.userDetails.login === "" || this.userDetails.login.length <= 3
    );
  }
}
