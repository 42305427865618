import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {HelperService} from '../../services/helper.service';
import {MerchantService} from '../../services/merchant.service';
import {AlertController} from '@ionic/angular';

@Component({
    selector: 'app-merchant-panel',
    templateUrl: './merchant-panel.component.html',
    styleUrls: ['./merchant-panel.component.scss'],
})
export class MerchantPanelComponent implements OnInit {
    user: any;
    merchants = [];
    expire: any;
    tags = [];
    trail_received = 0;
    mentor: any;

    constructor(private authService: AuthService, public helperService: HelperService,
                private merchantService: MerchantService, private alertCtrl: AlertController) {
    }

    ngOnInit() {
        this.getUser();
    }

    getUser() {
        this.authService.getCurrentUser().subscribe(resp => {
            if (resp.success) {
                this.user = resp.profile;
                this.getMerchants();
            }
        }, err => {
            this.helperService.showToast('Unable to load merchant panel');
        });
    }

    getMerchants() {
        this.merchantService.merchants().subscribe(resp => {
            if (resp.success) {
                this.merchants = resp.merchants;
                this.expire = resp.expire;
                this.trail_received = resp.trail_received;
                this.tags = resp.tags;
                this.mentor = resp.mentor;
            }
        }, err => {
            this.helperService.showToast('Unable to load merchant list');
        });
    }

    async requestForm(username = '') {
        const alert = await this.alertCtrl.create({
            header: 'Merchant Request!',
            message: 'Type username and amount you want to send',
            mode: 'ios',
            inputs: [
                {
                    name: 'username',
                    placeholder: 'Username',
                    value: username
                },
                {
                    name: 'amount',
                    type: 'number',
                    placeholder: 'Amount'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    handler: data => {
                    }
                },
                {
                    text: 'Send',
                    handler: data => {
                        if (data.username && data.amount) {
                            this.merchantRequest(data, alert);
                        } else {
                            this.helperService.showAlert('Please input valid username and amount');
                        }
                        return false;
                    }
                }
            ]
        });
        await alert.present();
    }

    merchantRequest(data, alert) {
        this.merchantService.merchantRequest(data).subscribe(resp => {
            if (resp.success) {
                this.appendToList(resp.merchant);
                alert.dismiss();
                this.helperService.showToast(resp.message, 'success');
            } else {
                this.helperService.showAlert(resp.message);
            }
        }, err => {
            this.helperService.showToast('Unable to send merchant request');
        });
    }

    appendToList(new_request) {
        for (let i = 0; i < this.merchants.length; i++) {
            if (this.merchants[i].id == new_request.id) {
                this.merchants[i] = new_request;
                return true;
            }
        }
        this.merchants.push(new_request);
    }

}
