export const emojiItems = [
  {
    imgPath: "/assets/emoji/uni_0.png",
    emojis: [
      {
        imgPath: "/assets/emoji/uni_0.png",
        unicode: "0x1F100",
        regx: /:\)/g,
        shortCode: ":)",
        convertedCode: "🄀",
      },
      {
        imgPath: "/assets/emoji/uni_1.png",
        unicode: "0x1F101",
        shortCode: ">:(",
        convertedCode: "🄁",
      },
      {
        imgPath: "/assets/emoji/uni_2.png",
        unicode: "0x1F102",
        shortCode: ":|",
        convertedCode: "🄂",
      },
      {
        imgPath: "/assets/emoji/uni_3.png",
        unicode: "0x1F103",
        shortCode: ":D",
        convertedCode: "🄃",
      },
      {
        imgPath: "/assets/emoji/uni_4.png",
        unicode: "0x1F104",
        shortCode: ":p",
        convertedCode: "🄄",
      },
      {
        imgPath: "/assets/emoji/uni_5.png",
        unicode: "0x1F105",
        shortCode: ">:",
        convertedCode: "🄅",
      },
      {
        imgPath: "/assets/emoji/uni_6.png",
        unicode: "0x1F106",
        shortCode: ':"(',
        convertedCode: "🄆",
      },
      {
        imgPath: "/assets/emoji/uni_7.png",
        unicode: "0x1F107",
        shortCode: "(toomuchlove)",
        convertedCode: "🄇",
      },
      {
        imgPath: "/assets/emoji/uni_8.png",
        unicode: "0x1F108",
        shortCode: ":-)",
        convertedCode: "🄈",
      },
      {
        imgPath: "/assets/emoji/uni_9.png",
        unicode: "0x1F109",
        shortCode: ":8)",
        convertedCode: "🄉",
      },
      {
        imgPath: "/assets/emoji/uni_10.png",
        unicode: "0x1F110",
        shortCode: "(gentle)",
        convertedCode: "🄐",
      },
      {
        imgPath: "/assets/emoji/uni_11.png",
        unicode: "0x1F111",
        shortCode: ":o",
        convertedCode: "🄑",
      },
      {
        imgPath: "/assets/emoji/uni_12.png",
        unicode: "0x1F112",
        shortCode: ":~o",
        convertedCode: "🄒",
      },
      {
        imgPath: "/assets/emoji/uni_13.png",
        unicode: "0x1F113",
        shortCode: ";)",
        convertedCode: "🄓",
      },
      {
        imgPath: "/assets/emoji/uni_14.png",
        unicode: "0x1F114",
        shortCode: "^.^",
        convertedCode: "🄔",
      },
      {
        imgPath: "/assets/emoji/uni_15.png",
        unicode: "0x1F115",
        shortCode: ":(",
        convertedCode: "🄕",
      },
      {
        imgPath: "/assets/emoji/uni_16.png",
        unicode: "0x1F116",
        shortCode: "~.–",
        convertedCode: "🄖",
      },
      {
        imgPath: "/assets/emoji/uni_17.png",
        unicode: "0x1F117",
        shortCode: ":\\",
        convertedCode: "🄗",
      },
      {
        imgPath: "/assets/emoji/uni_18.png",
        unicode: "0x1F118",
        shortCode: "(whythat)",
        convertedCode: "🄘",
      },
      {
        imgPath: "/assets/emoji/uni_19.png",
        unicode: "0x1F119",
        shortCode: ">.<",
        convertedCode: "🄙",
      },
      {
        imgPath: "/assets/emoji/uni_20.png",
        unicode: "0x1F120",
        shortCode: ":/",
        convertedCode: "🄠",
      },
      {
        imgPath: "/assets/emoji/uni_21.png",
        unicode: "0x1F121",
        shortCode: ":'(",
        convertedCode: "🄡",
      },
      {
        imgPath: "/assets/emoji/uni_22.png",
        unicode: "0x1F122",
        shortCode: "(silent)",
        convertedCode: "🄢",
      },
      {
        imgPath: "/assets/emoji/uni_23.png",
        unicode: "0x1F123",
        shortCode: "/;)",
        convertedCode: "🄣",
      },
      {
        imgPath: "/assets/emoji/uni_24.png",
        unicode: "0x1F124",
        shortCode: "(exhausted)",
        convertedCode: "🄤",
      },
      {
        imgPath: "/assets/emoji/uni_25.png",
        unicode: "0x1F125",
        shortCode: "(LOL)",
        convertedCode: "🄥",
      },
      {
        imgPath: "/assets/emoji/uni_26.png",
        unicode: "0x1F126",
        shortCode: ":'(",
        convertedCode: "🄦",
      },
      {
        imgPath: "/assets/emoji/uni_27.png",
        unicode: "0x1F127",
        shortCode: "(oh-no)",
        convertedCode: "🄧",
      },
      {
        imgPath: "/assets/emoji/uni_28.png",
        unicode: "0x1F128",
        shortCode: "(flirt)",
        convertedCode: "🄨",
      },
      {
        imgPath: "/assets/emoji/uni_29.png",
        unicode: "0x1F129",
        shortCode: "(doh)",
        convertedCode: "🄩",
      },
      {
        imgPath: "/assets/emoji/uni_30.png",
        unicode: "0x1F130",
        shortCode: "(rofl)",
        convertedCode: "🄰",
      },
      {
        imgPath: "/assets/emoji/uni_31.png",
        unicode: "0x1F131",
        shortCode: "(notme)",
        convertedCode: "🄱",
      },
      {
        imgPath: "/assets/emoji/uni_32.png",
        unicode: "0x1F132",
        shortCode: "(cuddle)",
        convertedCode: "🄲",
      },
      {
        imgPath: "/assets/emoji/uni_33.png",
        unicode: "0x1F133",
        shortCode: "(look)",
        convertedCode: "🄳",
      },
      {
        imgPath: "/assets/emoji/uni_34.png",
        unicode: "0x1F134",
        shortCode: "(eh)",
        convertedCode: "🄴",
      },
      {
        imgPath: "/assets/emoji/uni_35.png",
        unicode: "0x1F135",
        shortCode: "(attitude)",
        convertedCode: "🄵",
      },
      {
        imgPath: "/assets/emoji/uni_36.png",
        unicode: "0x1F136",
        shortCode: "(bum)",
        convertedCode: "🄶",
      },
      {
        imgPath: "/assets/emoji/uni_37.png",
        unicode: "0x1F137",
        shortCode: "(confused)",
        convertedCode: "🄷",
      },
      {
        imgPath: "/assets/emoji/uni_38.png",
        unicode: "0x1F138",
        shortCode: "(droll)",
        convertedCode: "🄸",
      },
      {
        imgPath: "/assets/emoji/uni_39.png",
        unicode: "0x1F139",
        shortCode: "(vomit)",
        convertedCode: "🄹",
      },
      {
        imgPath: "/assets/emoji/uni_40.png",
        unicode: "0x1F140",
        shortCode: "(poo)",
        convertedCode: "🅀",
      },
      {
        imgPath: "/assets/emoji/uni_41.png",
        unicode: "0x1F141",
        shortCode: "(devil)",
        convertedCode: "🅁",
      },
      {
        imgPath: "/assets/emoji/uni_42.png",
        unicode: "0x1F142",
        shortCode: "(angel)",
        convertedCode: "🅂",
      },
      {
        imgPath: "/assets/emoji/uni_43.png",
        unicode: "0x1F143",
        shortCode: "(vamp)",
        convertedCode: "🅃",
      },
      {
        imgPath: "/assets/emoji/uni_44.png",
        unicode: "0x1F144",
        shortCode: "(alien)",
        convertedCode: "🅄",
      },
      {
        imgPath: "/assets/emoji/uni_45.png",
        unicode: "0x1F145",
        shortCode: "(scary)",
        convertedCode: "🅅",
      },
      {
        imgPath: "/assets/emoji/uni_46.png",
        unicode: "0x1F146",
        shortCode: "(kiss)",
        convertedCode: "🅆",
      },
      {
        imgPath: "/assets/emoji/uni_47.png",
        unicode: "0x1F147",
        shortCode: "(hug)",
        convertedCode: "🅇",
      },
      {
        imgPath: "/assets/emoji/uni_48.png",
        unicode: "0x1F148",
        shortCode: "(love)",
        convertedCode: "🅈",
      },
      {
        imgPath: "/assets/emoji/uni_49.png",
        unicode: "0x1F149",
        shortCode: "(broken_heart)",
        convertedCode: "🅉",
      },
      {
        imgPath: "/assets/emoji/uni_50.png",
        unicode: "0x1F150",
        shortCode: "(pink_hearts)",
        convertedCode: "🅐",
      },
      {
        imgPath: "/assets/emoji/uni_51.png",
        unicode: "0x1F151",
        shortCode: "(ring)",
        convertedCode: "🅑",
      },
      {
        imgPath: "/assets/emoji/uni_52.png",
        unicode: "0x1F152",
        shortCode: "(gift)",
        convertedCode: "🅒",
      },
      {
        imgPath: "/assets/emoji/uni_53.png",
        unicode: "0x1F153",
        shortCode: "(teddybear)",
        convertedCode: "🅓",
      },
      {
        imgPath: "/assets/emoji/uni_54.png",
        unicode: "0x1F154",
        shortCode: "(rose)",
        convertedCode: "🅔",
      },
      {
        imgPath: "/assets/emoji/uni_55.png",
        unicode: "0x1F155",
        shortCode: "(flowers)",
        convertedCode: "🅕",
      },
      {
        imgPath: "/assets/emoji/uni_56.png",
        unicode: "0x1F156",
        shortCode: "(heart)",
        convertedCode: "🅖",
      },
      {
        imgPath: "/assets/emoji/uni_57.png",
        unicode: "0x1F157",
        shortCode: "(music)",
        convertedCode: "🅗",
      },
      {
        imgPath: "/assets/emoji/uni_58.png",
        unicode: "0x1F158",
        shortCode: "(balloons)",
        convertedCode: "🅘",
      },
      {
        imgPath: "/assets/emoji/uni_59.png",
        unicode: "0x1F159",
        shortCode: "(cupid)",
        convertedCode: "🅙",
      },
      {
        imgPath: "/assets/emoji/uni_60.png",
        unicode: "0x1F160",
        shortCode: "(bell)",
        convertedCode: "🅠",
      },
      {
        imgPath: "/assets/emoji/uni_61.png",
        unicode: "0x1F161",
        shortCode: "(call_me)",
        convertedCode: "🅡",
      },
      {
        imgPath: "/assets/emoji/uni_62.png",
        unicode: "0x1F162",
        shortCode: "(girl)",
        convertedCode: "🅢",
      },
      {
        imgPath: "/assets/emoji/uni_63.png",
        unicode: "0x1F163",
        shortCode: "(boy)",
        convertedCode: "🅣",
      },
      {
        imgPath: "/assets/emoji/uni_64.png",
        unicode: "0x1F164",
        shortCode: "(beauty)",
        convertedCode: "🅤",
      },
      {
        imgPath: "/assets/emoji/uni_65.png",
        unicode: "0x1F165",
        shortCode: "(dinosaur)",
        convertedCode: "🅥",
      },
      {
        imgPath: "/assets/emoji/uni_66.png",
        unicode: "0x1F166",
        shortCode: "(snake)",
        convertedCode: "🅦",
      },
      {
        imgPath: "/assets/emoji/uni_67.png",
        unicode: "0x1F167",
        shortCode: "(ox)",
        convertedCode: "🅧",
      },
      {
        imgPath: "/assets/emoji/uni_68.png",
        unicode: "0x1F168",
        shortCode: "(chicken)",
        convertedCode: "🅨",
      },
      {
        imgPath: "/assets/emoji/uni_69.png",
        unicode: "0x1F169",
        shortCode: "(cow)",
        convertedCode: "🅩",
      },
      {
        imgPath: "/assets/emoji/uni_70.png",
        unicode: "0x1F170",
        shortCode: "(dog)",
        convertedCode: "🅰",
      },
      {
        imgPath: "/assets/emoji/uni_71.png",
        unicode: "0x1F171",
        shortCode: "(horse)",
        convertedCode: "🅱",
      },
      {
        imgPath: "/assets/emoji/uni_72.png",
        unicode: "0x1F172",
        shortCode: "(monkey)",
        convertedCode: "🅲",
      },
      {
        imgPath: "/assets/emoji/uni_73.png",
        unicode: "0x1F173",
        shortCode: "(mouse)",
        convertedCode: "🅳",
      },
      {
        imgPath: "/assets/emoji/uni_74.png",
        unicode: "0x1F174",
        shortCode: "(rabbit)",
        convertedCode: "🅴",
      },
      {
        imgPath: "/assets/emoji/uni_75.png",
        unicode: "0x1F175",
        shortCode: "(tiger)",
        convertedCode: "🅵",
      },
      {
        imgPath: "/assets/emoji/uni_76.png",
        unicode: "0x1F176",
        shortCode: "(soccer)",
        convertedCode: "🅶",
      },
      {
        imgPath: "/assets/emoji/uni_77.png",
        unicode: "0x1F177",
        shortCode: "(batsman)",
        convertedCode: "🅷",
      },
      {
        imgPath: "/assets/emoji/uni_78.png",
        unicode: "0x1F178",
        shortCode: "(winner)",
        convertedCode: "🅸",
      },
      {
        imgPath: "/assets/emoji/uni_79.png",
        unicode: "0x1F179",
        shortCode: "(kick)",
        convertedCode: "🅹",
      },
    ],
  },
  {
    imgPath: "/assets/emoji/uni_102.png",
    emojis: [
      {
        imgPath: "/assets/emoji/uni_80.png",
        unicode: "0x1F180",
        shortCode: "(workout)",
        convertedCode: "🆀",
      },
      {
        imgPath: "/assets/emoji/uni_81.png",
        unicode: "0x1F181",
        shortCode: "(forgive)",
        convertedCode: "🆁",
      },
      {
        imgPath: "/assets/emoji/uni_82.png",
        unicode: "0x1F182",
        shortCode: "(punch_left)",
        convertedCode: "🆂",
      },
      {
        imgPath: "/assets/emoji/uni_83.png",
        unicode: "0x1F183",
        shortCode: "(punch_right)",
        convertedCode: "🆃",
      },
      {
        imgPath: "/assets/emoji/uni_84.png",
        unicode: "0x1F184",
        shortCode: "(handshake)",
        convertedCode: "🆄",
      },
      {
        imgPath: "/assets/emoji/uni_85.png",
        unicode: "0x1F185",
        shortCode: "(excellent)",
        convertedCode: "🆅",
      },
      {
        imgPath: "/assets/emoji/uni_86.png",
        unicode: "0x1F186",
        shortCode: "(thump_up)",
        convertedCode: "🆆",
      },
      {
        imgPath: "/assets/emoji/uni_87.png",
        unicode: "0x1F187",
        shortCode: "(punch)",
        convertedCode: "🆇",
      },
      {
        imgPath: "/assets/emoji/uni_88.png",
        unicode: "0x1F188",
        shortCode: "(finger_1)",
        convertedCode: "🆈",
      },
      {
        imgPath: "/assets/emoji/uni_89.png",
        unicode: "0x1F189",
        shortCode: "(finger_2)",
        convertedCode: "🆉",
      },
      {
        imgPath: "/assets/emoji/uni_90.png",
        unicode: "0x1F190",
        shortCode: "(finger_3)",
        convertedCode: "🆐",
      },
      {
        imgPath: "/assets/emoji/uni_91.png",
        unicode: "0x1F191",
        shortCode: "(finger_4)",
        convertedCode: "🆑",
      },
      {
        imgPath: "/assets/emoji/uni_92.png",
        unicode: "0x1F192",
        shortCode: "(finger_5)",
        convertedCode: "🆒",
      },
      {
        imgPath: "/assets/emoji/uni_93.png",
        unicode: "0x1F193",
        shortCode: "(finger_6)",
        convertedCode: "🆓",
      },
      {
        imgPath: "/assets/emoji/uni_94.png",
        unicode: "0x1F194",
        shortCode: "(finger_7)",
        convertedCode: "🆔",
      },
      {
        imgPath: "/assets/emoji/uni_95.png",
        unicode: "0x1F195",
        shortCode: "(finger_8)",
        convertedCode: "🆕",
      },
      {
        imgPath: "/assets/emoji/uni_96.png",
        unicode: "0x1F196",
        shortCode: "(beer)",
        convertedCode: "🆖",
      },
      {
        imgPath: "/assets/emoji/uni_97.png",
        unicode: "0x1F197",
        shortCode: "(cake)",
        convertedCode: "🆗",
      },
      {
        imgPath: "/assets/emoji/uni_98.png",
        unicode: "0x1F198",
        shortCode: "(drink)",
        convertedCode: "🆘",
      },
      {
        imgPath: "/assets/emoji/uni_99.png",
        unicode: "0x1F199",
        shortCode: "(ice_cream)",
        convertedCode: "🆙",
      },
      {
        imgPath: "/assets/emoji/uni_100.png",
        unicode: "0x1F200",
        shortCode: "(g_earpod)",
        convertedCode: "🈀",
      },
      {
        imgPath: "/assets/emoji/uni_101.png",
        unicode: "0x1F201",
        shortCode: "(g_confused)",
        convertedCode: "🈁",
      },
      {
        imgPath: "/assets/emoji/uni_102.png",
        unicode: "0x1F202",
        shortCode: "(g_hi)",
        convertedCode: "🈂",
      },
      {
        imgPath: "/assets/emoji/uni_103.png",
        unicode: "0x1F203",
        shortCode: "(g_boo)",
        convertedCode: "🈃",
      },
      {
        imgPath: "/assets/emoji/uni_104.png",
        unicode: "0x1F204",
        shortCode: "(g_umm)",
        convertedCode: "🈄",
      },
      {
        imgPath: "/assets/emoji/uni_105.png",
        unicode: "0x1F205",
        shortCode: "(g_look)",
        convertedCode: "🈅",
      },
      {
        imgPath: "/assets/emoji/uni_106.png",
        unicode: "0x1F206",
        shortCode: "(g_ninja)",
        convertedCode: "🈆",
      },
      {
        imgPath: "/assets/emoji/uni_107.png",
        unicode: "0x1F207",
        shortCode: "(star)",
        convertedCode: "🈇",
      },
      {
        imgPath: "/assets/emoji/uni_108.png",
        unicode: "0x1F208",
        shortCode: "(sun)",
        convertedCode: "🈈",
      },
      {
        imgPath: "/assets/emoji/uni_109.png",
        unicode: "0x1F279",
        shortCode: "(rainbow)",
        convertedCode: "🉹",
      },
      {
        imgPath: "/assets/emoji/uni_110.png",
        unicode: "0x1F280",
        shortCode: "(cloud)",
        convertedCode: "🊀",
      },
      {
        imgPath: "/assets/emoji/uni_111.png",
        unicode: "0x1F281",
        shortCode: "(light)",
        convertedCode: "🊁",
      },
      {
        imgPath: "/assets/emoji/uni_112.png",
        unicode: "0x1F282",
        shortCode: "(alien_laugh)",
        convertedCode: "🊂",
      },
      {
        imgPath: "/assets/emoji/uni_113.png",
        unicode: "0x1F283",
        shortCode: "alien_smile",
        convertedCode: "🊃",
      },
      {
        imgPath: "/assets/emoji/uni_114.png",
        unicode: "0x1F284",
        shortCode: "(alien_confused)",
        convertedCode: "🊄",
      },
      {
        imgPath: "/assets/emoji/uni_115.png",
        unicode: "0x1F285",
        shortCode: "(alien_angry)",
        convertedCode: "🊅",
      },
      {
        imgPath: "/assets/emoji/uni_116.png",
        unicode: "0x1F286",
        shortCode: "(alien_cool)",
        convertedCode: "🊆",
      },
      {
        imgPath: "/assets/emoji/uni_117.png",
        unicode: "0x1F287",
        shortCode: "(alien_cry)",
        convertedCode: "🊇",
      },
      {
        imgPath: "/assets/emoji/uni_118.png",
        unicode: "0x1F288",
        shortCode: "(alien_hot)",
        convertedCode: "🊈",
      },
      {
        imgPath: "/assets/emoji/uni_119.png",
        unicode: "0x1F289",
        shortCode: "(alien_lol)",
        convertedCode: "🊉",
      },
      {
        imgPath: "/assets/emoji/uni_120.png",
        unicode: "0x1F290",
        shortCode: "(heart_laugh)",
        convertedCode: "🊐",
      },
      {
        imgPath: "/assets/emoji/uni_121.png",
        unicode: "0x1F291",
        shortCode: "(heart_omg)",
        convertedCode: "🊑",
      },
      {
        imgPath: "/assets/emoji/uni_122.png",
        unicode: "0x1F292",
        shortCode: "(heart_burn)",
        convertedCode: "🊒",
      },
      {
        imgPath: "/assets/emoji/uni_123.png",
        unicode: "0x1F293",
        shortCode: "(heart_wink)",
        convertedCode: "🊓",
      },
      {
        imgPath: "/assets/emoji/uni_124.png",
        unicode: "0x1F294",
        shortCode: "(heart-mood-off)",
        convertedCode: "🊔",
      },
      {
        imgPath: "/assets/emoji/uni_125.png",
        unicode: "0x1F295",
        shortCode: "(sad_dog)",
        convertedCode: "🊕",
      },
      {
        imgPath: "/assets/emoji/uni_126.png",
        unicode: "0x1F296",
        shortCode: "(angry_dog)",
        convertedCode: "🊖",
      },
      {
        imgPath: "/assets/emoji/uni_127.png",
        unicode: "0x1F297",
        shortCode: "(happy_dog)",
        convertedCode: "🊗",
      },
      {
        imgPath: "/assets/emoji/uni_128.png",
        unicode: "0x1F298",
        shortCode: "ghost_cry)",
        convertedCode: "🊘",
      },
      {
        imgPath: "/assets/emoji/uni_129.png",
        unicode: "0x1F299",
        shortCode: "(ghost_laugh)",
        convertedCode: "🊙",
      },
      {
        imgPath: "/assets/emoji/uni_130.png",
        unicode: "0x1F300",
        shortCode: "(ghost_dizzy)",
        convertedCode: "🌀",
      },
      {
        imgPath: "/assets/emoji/uni_131.png",
        unicode: "0x1F301",
        shortCode: "(ghost_confused)",
        convertedCode: "🌁",
      },
      {
        imgPath: "/assets/emoji/uni_132.png",
        unicode: "0x1F302",
        shortCode: "(bear_smile)",
        convertedCode: "🌂",
      },
      {
        imgPath: "/assets/emoji/uni_133.png",
        unicode: "0x1F303",
        shortCode: "(bear_sad)",
        convertedCode: "🌃",
      },
      {
        imgPath: "/assets/emoji/uni_134.png",
        unicode: "0x1F304",
        shortCode: "(bear_shock)",
        convertedCode: "🌄",
      },
      {
        imgPath: "/assets/emoji/uni_135.png",
        unicode: "0x1F305",
        shortCode: "(bear_laugh)",
        convertedCode: "🌅",
      },
      {
        imgPath: "/assets/emoji/uni_136.png",
        unicode: "0x1F306",
        shortCode: "(bear_cry)",
        convertedCode: "🌆",
      },
      {
        imgPath: "/assets/emoji/uni_137.png",
        unicode: "0x1F307",
        shortCode: "(bear_ok)",
        convertedCode: "🌇",
      },
      {
        imgPath: "/assets/emoji/uni_138.png",
        unicode: "0x1F308",
        shortCode: "(clap)",
        convertedCode: "🌈",
      },
      {
        imgPath: "/assets/emoji/uni_139.png",
        unicode: "0x1F309",
        shortCode: "(ahem)",
        convertedCode: "🌉",
      },
      {
        imgPath: "/assets/emoji/uni_140.png",
        unicode: "0x1F310",
        shortCode: "((damn)",
        convertedCode: "🌐",
      },
      {
        imgPath: "/assets/emoji/uni_141.png",
        unicode: "0x1F311",
        shortCode: "(omg)",
        convertedCode: "🌑",
      },
      {
        imgPath: "/assets/emoji/uni_142.png",
        unicode: "0x1F312",
        shortCode: "(excuse_me)",
        convertedCode: "🌒",
      },
      {
        imgPath: "/assets/emoji/uni_143.png",
        unicode: "0x1F313",
        shortCode: "(blackstabber)",
        convertedCode: "🌓",
      },
      {
        imgPath: "/assets/emoji/uni_144.png",
        unicode: "0x1F314",
        shortCode: "(nosey)",
        convertedCode: "🌔",
      },
      {
        imgPath: "/assets/emoji/uni_145.png",
        unicode: "0x1F315",
        shortCode: "(cough)",
        convertedCode: "🌕",
      },
      {
        imgPath: "/assets/emoji/uni_146.png",
        unicode: "0x1F316",
        shortCode: "(naughty)",
        convertedCode: "🌖",
      },
      {
        imgPath: "/assets/emoji/uni_147.png",
        unicode: "0x1F317",
        shortCode: "(boo)",
        convertedCode: "🌗",
      },
      {
        imgPath: "/assets/emoji/uni_148.png",
        unicode: "0x1F318",
        shortCode: "(oh_no)",
        convertedCode: "🌘",
      },
      {
        imgPath: "/assets/emoji/uni_149.png",
        unicode: "0x1F319",
        shortCode: "(smoker)",
        convertedCode: "🌙",
      },
      {
        imgPath: "/assets/emoji/uni_150.png",
        unicode: "0x1F320",
        shortCode: "(cough)",
        convertedCode: "🌠",
      },
      {
        imgPath: "/assets/emoji/uni_151.png",
        unicode: "0x1F321",
        shortCode: "(naughty)",
        convertedCode: "🌡",
      },
      {
        imgPath: "/assets/emoji/uni_152.png",
        unicode: "0x1F322",
        shortCode: "(boo)",
        convertedCode: "🌢",
      },
      {
        imgPath: "/assets/emoji/uni_153.png",
        unicode: "0x1F323",
        shortCode: "(oh_no)",
        convertedCode: "🌣",
      },
      {
        imgPath: "/assets/emoji/uni_154.png",
        unicode: "0x1F324",
        shortCode: "(smoker)",
        convertedCode: "🌤",
      },
      {
        imgPath: "/assets/emoji/uni_155.png",
        unicode: "0x1F325",
        shortCode: "(kiss_back)",
        convertedCode: "🌥",
      },
      {
        imgPath: "/assets/emoji/uni_156.png",
        unicode: "0x1F326",
        shortCode: "(kiss_me)",
        convertedCode: "🌦",
      },
      {
        imgPath: "/assets/emoji/uni_157.png",
        unicode: "0x1F327",
        shortCode: "(shy)",
        convertedCode: "🌧",
      },
      {
        imgPath: "/assets/emoji/uni_158.png",
        unicode: "0x1F328",
        shortCode: "(sleepy)",
        convertedCode: "🌨",
      },
    ],
  },
  {
    imgPath: "/assets/emoji/emotion_0.png",
    emojis: [
      {
        imgPath: "/assets/emoji/emotion_0.png",
        unicode: "0x1F324",
        shortCode: ":s_smile",
        convertedCode: "🌤",
      },
      {
        imgPath: "/assets/emoji/emotion_1.png",
        unicode: "0x1F325",
        shortCode: ":s_lough",
        convertedCode: "🌥",
      },
      {
        imgPath: "/assets/emoji/emotion_2.png",
        unicode: "0x1F326",
        shortCode: ":s_lol",
        convertedCode: "🌦",
      },
      {
        imgPath: "/assets/emoji/emotion_3.png",
        unicode: "0x1F327",
        shortCode: ":s_sblol",
        convertedCode: "🌧",
      },
      {
        imgPath: "/assets/emoji/emotion_4.png",
        unicode: "0x1F328",
        shortCode: ":s_selol",
        convertedCode: "🌨",
      },
      {
        imgPath: "/assets/emoji/emotion_5.png",
        unicode: "0x1F329",
        shortCode: ":s_lfmao",
        convertedCode: "🌩",
      },
      {
        imgPath: "/assets/emoji/emotion_6.png",
        unicode: "0x1F330",
        shortCode: ":s_happy",
        convertedCode: "🌰",
      },
      {
        imgPath: "/assets/emoji/emotion_7.png",
        unicode: "0x1F331",
        shortCode: ":s_wink",
        convertedCode: "🌱",
      },
      {
        imgPath: "/assets/emoji/emotion_8.png",
        unicode: "0x1F332",
        shortCode: ":s_vhappy",
        convertedCode: "🌲",
      },
      {
        imgPath: "/assets/emoji/emotion_9.png",
        unicode: "0x1F333",
        shortCode: ":s_angel",
        convertedCode: "🌳",
      },
      {
        imgPath: "/assets/emoji/emotion_10.png",
        unicode: "0x1F334",
        shortCode: ":s_love",
        convertedCode: "🌴",
      },
      {
        imgPath: "/assets/emoji/emotion_11.png",
        unicode: "0x1F335",
        shortCode: ":s_inlove",
        convertedCode: "🌵",
      },
      {
        imgPath: "/assets/emoji/emotion_12.png",
        unicode: "0x1F336",
        shortCode: ":s_star",
        convertedCode: "🌶",
      },
      {
        imgPath: "/assets/emoji/emotion_13.png",
        unicode: "0x1F337",
        shortCode: ":s_hm",
        convertedCode: "🌷",
      },
      {
        imgPath: "/assets/emoji/emotion_14.png",
        unicode: "0x1F338",
        shortCode: ":s_yum",
        convertedCode: "🌸",
      },
      {
        imgPath: "/assets/emoji/emotion_15.png",
        unicode: "0x1F339",
        shortCode: ":s_yumlol",
        convertedCode: "🌹",
      },
      {
        imgPath: "/assets/emoji/emotion_16.png",
        unicode: "0x1F340",
        shortCode: ":s_kissw",
        convertedCode: "🍀",
      },
      {
        imgPath: "/assets/emoji/emotion_17.png",
        unicode: "0x1F341",
        shortCode: ":s_kissh",
        convertedCode: "🍁",
      },
      {
        imgPath: "/assets/emoji/emotion_18.png",
        unicode: "0x1F342",
        shortCode: ":s_kiss",
        convertedCode: "🍂",
      },
      {
        imgPath: "/assets/emoji/emotion_19.png",
        unicode: "0x1F343",
        shortCode: ":s_ops",
        convertedCode: "🍃",
      },
      {
        imgPath: "/assets/emoji/emotion_20.png",
        unicode: "0x1F344",
        shortCode: ":s_ylolw",
        convertedCode: "🍄",
      },
      {
        imgPath: "/assets/emoji/emotion_21.png",
        unicode: "0x1F345",
        shortCode: ":s_yloly",
        convertedCode: "🍅",
      },
      {
        imgPath: "/assets/emoji/emotion_22.png",
        unicode: "0x1F346",
        shortCode: ":s_ylolk",
        convertedCode: "🍆",
      },
      {
        imgPath: "/assets/emoji/emotion_23.png",
        unicode: "0x1F347",
        shortCode: ":s_ylole",
        convertedCode: "🍇",
      },
      {
        imgPath: "/assets/emoji/emotion_24.png",
        unicode: "0x1F348",
        shortCode: ":s_ylolm",
        convertedCode: "🍈",
      },
      {
        imgPath: "/assets/emoji/emotion_25.png",
        unicode: "0x1F349",
        shortCode: ":s_eh",
        convertedCode: "🍉",
      },
      {
        imgPath: "/assets/emoji/emotion_26.png",
        unicode: "0x1F350",
        shortCode: ":s_eh",
        convertedCode: "🍐",
      },
      {
        imgPath: "/assets/emoji/emotion_27.png",
        unicode: "0x1F351",
        shortCode: ":s_oh",
        convertedCode: "🍑",
      },
      {
        imgPath: "/assets/emoji/emotion_28.png",
        unicode: "0x1F352",
        shortCode: ":s_hm",
        convertedCode: "🍒",
      },
      {
        imgPath: "/assets/emoji/emotion_29.png",
        unicode: "0x1F353",
        shortCode: ":s_ho",
        convertedCode: "🍓",
      },
      {
        imgPath: "/assets/emoji/emotion_30.png",
        unicode: "0x1F354",
        shortCode: ":s_cold",
        convertedCode: "🍔",
      },
      {
        imgPath: "/assets/emoji/emotion_31.png",
        unicode: "0x1F355",
        shortCode: ":s_sleepy",
        convertedCode: "🍕",
      },
      {
        imgPath: "/assets/emoji/emotion_32.png",
        unicode: "0x1F356",
        shortCode: ":s_what",
        convertedCode: "🍖",
      },
      {
        imgPath: "/assets/emoji/emotion_33.png",
        unicode: "0x1F357",
        shortCode: ":s_sad",
        convertedCode: "🍗",
      },
      {
        imgPath: "/assets/emoji/emotion_34.png",
        unicode: "0x1F358",
        shortCode: ":s_wow",
        convertedCode: "🍘",
      },
      {
        imgPath: "/assets/emoji/emotion_35.png",
        unicode: "0x1F359",
        shortCode: ":s_woww",
        convertedCode: "🍙",
      },
      {
        imgPath: "/assets/emoji/emotion_36.png",
        unicode: "0x1F360",
        shortCode: ":s_wowww",
        convertedCode: "🍠",
      },
      {
        imgPath: "/assets/emoji/emotion_37.png",
        unicode: "0x1F361",
        shortCode: ":s_shit",
        convertedCode: "🍡",
      },
      {
        imgPath: "/assets/emoji/emotion_38.png",
        unicode: "0x1F362",
        shortCode: ":s_sadv",
        convertedCode: "🍢",
      },
      {
        imgPath: "/assets/emoji/emotion_39.png",
        unicode: "0x1F363",
        shortCode: ":s_crying",
        convertedCode: "🍣",
      },
      {
        imgPath: "/assets/emoji/emotion_40.png",
        unicode: "0x1F364",
        shortCode: ":s_stressed",
        convertedCode: "🍤",
      },
      {
        imgPath: "/assets/emoji/emotion_41.png",
        unicode: "0x1F365",
        shortCode: ":s_angry",
        convertedCode: "🍥",
      },
      {
        imgPath: "/assets/emoji/emotion_42.png",
        unicode: "0x1F366",
        shortCode: ":s_tensed",
        convertedCode: "🍦",
      },
      {
        imgPath: "/assets/emoji/emotion_43.png",
        unicode: "0x1F367",
        shortCode: ":s_emoless",
        convertedCode: "🍧",
      },
      {
        imgPath: "/assets/emoji/emotion_44.png",
        unicode: "0x1F368",
        shortCode: ":s_ohho",
        convertedCode: "🍨",
      },
      {
        imgPath: "/assets/emoji/emotion_45.png",
        unicode: "0x1F369",
        shortCode: ":s_crying_2",
        convertedCode: "🍩",
      },
      {
        imgPath: "/assets/emoji/emotion_46.png",
        unicode: "0x1F370",
        shortCode: ":s¬_freezing",
        convertedCode: "🍰",
      },
      {
        imgPath: "/assets/emoji/emotion_47.png",
        unicode: "0x1F371",
        shortCode: ":s_angry",
        convertedCode: "🍱",
      },
      {
        imgPath: "/assets/emoji/emotion_48.png",
        unicode: "0x1F372",
        shortCode: ":s_hot",
        convertedCode: "🍲",
      },
      {
        imgPath: "/assets/emoji/emotion_49.png",
        unicode: "0x1F373",
        shortCode: ":s_sangry",
        convertedCode: "🍳",
      },
      {
        imgPath: "/assets/emoji/emotion_50.png",
        unicode: "0x1F374",
        shortCode: ":s_poo",
        convertedCode: "🍴",
      },
    ],
  },
  {
    imgPath: "/assets/emoji/black_0.png",
    emojis: [
      {
        imgPath: "/assets/emoji/black_0.png",
        unicode: "0x1F375",
        shortCode: "(black_silent)",
        convertedCode: "🍵",
      },
      {
        imgPath: "/assets/emoji/black_1.png",
        unicode: "0x1F376",
        shortCode: "(black_angry)",
        convertedCode: "🍶",
      },
      {
        imgPath: "/assets/emoji/black_2.png",
        unicode: "0x1F377",
        shortCode: "(black_shy)",
        convertedCode: "🍷",
      },
      {
        imgPath: "/assets/emoji/black_3.png",
        unicode: "0x1F378",
        shortCode: "(black_teeth)",
        convertedCode: "🍸",
      },
      {
        imgPath: "/assets/emoji/black_4.png",
        unicode: "0x1F379",
        shortCode: "(black_hide)",
        convertedCode: "🍹",
      },
      {
        imgPath: "/assets/emoji/black_5.png",
        unicode: "0x1F380",
        shortCode: "(black_smile)",
        convertedCode: "🎀",
      },
      {
        imgPath: "/assets/emoji/black_6.png",
        unicode: "0x1F381",
        shortCode: "(black_love)",
        convertedCode: "🎁",
      },
      {
        imgPath: "/assets/emoji/black_7.png",
        unicode: "0x1F382",
        shortCode: "(black_cry)",
        convertedCode: "🎂",
      },
      {
        imgPath: "/assets/emoji/black_8.png",
        unicode: "0x1F383",
        shortCode: "(black_blind)",
        convertedCode: "🎃",
      },
      {
        imgPath: "/assets/emoji/black_9.png",
        unicode: "0x1F384",
        shortCode: "(black_hee)",
        convertedCode: "🎄",
      },
      {
        imgPath: "/assets/emoji/black_10.png",
        unicode: "0x1F385",
        shortCode: "(black_ghost)",
        convertedCode: "🎅",
      },
      {
        imgPath: "/assets/emoji/black_11.png",
        unicode: "0x1F386",
        shortCode: "(black_cutelove)",
        convertedCode: "🎆",
      },
      {
        imgPath: "/assets/emoji/black_12.png",
        unicode: "0x1F387",
        shortCode: "(black_evil)",
        convertedCode: "🎇",
      },
      {
        imgPath: "/assets/emoji/black_13.png",
        unicode: "0x1F388",
        shortCode: "(black_tongue)",
        convertedCode: "🎈",
      },
      {
        imgPath: "/assets/emoji/black_14.png",
        unicode: "0x1F389",
        shortCode: "(black_teethshow)",
        convertedCode: "🎉",
      },
      {
        imgPath: "/assets/emoji/black_15.png",
        unicode: "0x1F390",
        shortCode: "(black_kiss)",
        convertedCode: "🎐",
      },
      {
        imgPath: "/assets/emoji/black_16.png",
        unicode: "0x1F391",
        shortCode: "(black_dontknow)",
        convertedCode: "🎑",
      },
      {
        imgPath: "/assets/emoji/black_17.png",
        unicode: "0x1F392",
        shortCode: "(black_dollar)",
        convertedCode: "🎒",
      },
      {
        imgPath: "/assets/emoji/black_18.png",
        unicode: "0x1F393",
        shortCode: "(black_dontcare)",
        convertedCode: "🎓",
      },
      {
        imgPath: "/assets/emoji/black_19.png",
        unicode: "0x1F394",
        shortCode: "(black_huh)",
        convertedCode: "🎔",
      },
      {
        imgPath: "/assets/emoji/black_20.png",
        unicode: "0x1F395",
        shortCode: "(black_redlove)",
        convertedCode: "🎕",
      },
      {
        imgPath: "/assets/emoji/black_21.png",
        unicode: "0x1F396",
        shortCode: "(black_don)",
        convertedCode: "🎖",
      },
      {
        imgPath: "/assets/emoji/black_22.png",
        unicode: "0x1F397",
        shortCode: "(black_sleeping)",
        convertedCode: "🎗",
      },
      {
        imgPath: "/assets/emoji/black_23.png",
        unicode: "0x1F398",
        shortCode: "(black_innocent)",
        convertedCode: "🎘",
      },
      {
        imgPath: "/assets/emoji/black_24.png",
        unicode: "0x1F399",
        shortCode: "(black_notwell)",
        convertedCode: "🎙",
      },
      {
        imgPath: "/assets/emoji/black_25.png",
        unicode: "0x1F400",
        shortCode: "(black_letsfight)",
        convertedCode: "🐀",
      },
      {
        imgPath: "/assets/emoji/black_26.png",
        unicode: "0x1F401",
        shortCode: "(black_cockroach)",
        convertedCode: "🐁",
      },
      {
        imgPath: "/assets/emoji/black_27.png",
        unicode: "0x1F402",
        shortCode: "(black_blush)",
        convertedCode: "🐂",
      },
      {
        imgPath: "/assets/emoji/black_28.png",
        unicode: "0x1F403",
        shortCode: "(black_welldone)",
        convertedCode: "🐃",
      },
      {
        imgPath: "/assets/emoji/black_29.png",
        unicode: "0x1F404",
        shortCode: "(black_confused)",
        convertedCode: "🐄",
      },
    ],
  },
  {
    imgPath: "/assets/emoji/batman_0.png",
    emojis: [
      {
        imgPath: "/assets/emoji/batman_0.png",
        unicode: "0x1F405",
        shortCode: ":b_normal",
        convertedCode: "🐅",
      },
      {
        imgPath: "/assets/emoji/batman_1.png",
        unicode: "0x1F406",
        shortCode: ":b_evil",
        convertedCode: "🐆",
      },
      {
        imgPath: "/assets/emoji/batman_2.png",
        unicode: "0x1F407",
        shortCode: ":b_cool",
        convertedCode: "🐇",
      },
      {
        imgPath: "/assets/emoji/batman_3.png",
        unicode: "0x1F408",
        shortCode: ":b_angry",
        convertedCode: "🐈",
      },
      {
        imgPath: "/assets/emoji/batman_4.png",
        unicode: "0x1F409",
        shortCode: ":b_furious",
        convertedCode: "🐉",
      },
      {
        imgPath: "/assets/emoji/batman_5.png",
        unicode: "0x1F410",
        shortCode: ":b_sangry",
        convertedCode: "🐐",
      },
      {
        imgPath: "/assets/emoji/batman_6.png",
        unicode: "0x1F411",
        shortCode: ":fb_pig",
        convertedCode: "🐑",
      },
      {
        imgPath: "/assets/emoji/batman_7.png",
        unicode: "0x1F412",
        shortCode: ":tv",
        convertedCode: "🐒",
      },
      {
        imgPath: "/assets/emoji/batman_8.png",
        unicode: "0x1F413",
        shortCode: "(^^^)",
        convertedCode: "🐓",
      },
      {
        imgPath: "/assets/emoji/batman_9.png",
        unicode: "0x1F414",
        shortCode: ":3",
        convertedCode: "🐔",
      },
      {
        imgPath: "/assets/emoji/batman_10.png",
        unicode: "0x1F415",
        shortCode: ":v",
        convertedCode: "🐕",
      },
      {
        imgPath: "/assets/emoji/batman_11.png",
        unicode: "0x1F416",
        shortCode: ":fb_eh",
        convertedCode: "🐖",
      },
      {
        imgPath: "/assets/emoji/batman_12.png",
        unicode: "0x1F417",
        shortCode: ":fb_poo",
        convertedCode: "🐗",
      },
      {
        imgPath: "/assets/emoji/batman_13.png",
        unicode: "0x1F418",
        shortCode: ":fb_tup",
        convertedCode: "🐘",
      },
      {
        imgPath: "/assets/emoji/batman_14.png",
        unicode: "0x1F419",
        shortCode: ":^_^",
        convertedCode: "🐙",
      },
      {
        imgPath: "/assets/emoji/batman_15.png",
        unicode: "0x1F420",
        shortCode: ":fb_sf",
        convertedCode: "🐠",
      },
      {
        imgPath: "/assets/emoji/batman_16.png",
        unicode: "0x1F421",
        shortCode: ":fb_hs",
        convertedCode: "🐡",
      },
      {
        imgPath: "/assets/emoji/batman_17.png",
        unicode: "0x1F422",
        shortCode: ":fb_salute",
        convertedCode: "🐢",
      },
    ],
  },
  {
    imgPath: "/assets/emoji/thumb_0.png",
    emojis: [
      {
        imgPath: "/assets/emoji/thumb_0.png",
        unicode: "0x1F423",
        shortCode: ":f_h5",
        convertedCode: "🐣",
      },
      {
        imgPath: "/assets/emoji/thumb_1.png",
        unicode: "0x1F424",
        shortCode: ":f_h52",
        convertedCode: "🐤",
      },
      {
        imgPath: "/assets/emoji/thumb_2.png",
        unicode: "0x1F425",
        shortCode: "f_fire",
        convertedCode: "🐥",
      },
      {
        imgPath: "/assets/emoji/thumb_3.png",
        unicode: "0x1F426",
        shortCode: ":f_tup",
        convertedCode: "🐦",
      },
      {
        imgPath: "/assets/emoji/thumb_4.png",
        unicode: "0x1F427",
        shortCode: ":f_volcan",
        convertedCode: "🐧",
      },
      {
        imgPath: "/assets/emoji/thumb_5.png",
        unicode: "0x1F428",
        shortCode: ":f_tdown",
        convertedCode: "🐨",
      },
      {
        imgPath: "/assets/emoji/thumb_6.png",
        unicode: "0x1F429",
        shortCode: ":f_out",
        convertedCode: "🐩",
      },
      {
        imgPath: "/assets/emoji/thumb_7.png",
        unicode: "0x1F430",
        shortCode: ":f_pardon",
        convertedCode: "🐰",
      },
      {
        imgPath: "/assets/emoji/thumb_8.png",
        unicode: "0x1F431",
        shortCode: ":f_clap",
        convertedCode: "🐱",
      },
      {
        imgPath: "/assets/emoji/thumb_9.png",
        unicode: "0x1F432",
        shortCode: ":f_v",
        convertedCode: "🐲",
      },
      {
        imgPath: "/assets/emoji/thumb_10.png",
        unicode: "0x1F433",
        shortCode: ":f_two",
        convertedCode: "🐳",
      },
      {
        imgPath: "/assets/emoji/thumb_11.png",
        unicode: "0x1F434",
        shortCode: ":f_ag",
        convertedCode: "🐴",
      },
      {
        imgPath: "/assets/emoji/thumb_12.png",
        unicode: "0x1F435",
        shortCode: ":f_yo",
        convertedCode: "🐵",
      },
      {
        imgPath: "/assets/emoji/thumb_13.png",
        unicode: "0x1F436",
        shortCode: ":f_yo2",
        convertedCode: "🐶",
      },
      {
        imgPath: "/assets/emoji/thumb_14.png",
        unicode: "0x1F437",
        shortCode: ":f_one",
        convertedCode: "🐷",
      },
      {
        imgPath: "/assets/emoji/thumb_15.png",
        unicode: "0x1F438",
        shortCode: ":f_fu",
        convertedCode: "🐸",
      },
      {
        imgPath: "/assets/emoji/thumb_16.png",
        unicode: "0x1F439",
        shortCode: ":f_perfect",
        convertedCode: "🐹",
      },
      {
        imgPath: "/assets/emoji/thumb_17.png",
        unicode: "0x1F440",
        shortCode: ":f_cn",
        convertedCode: "👀",
      },
      {
        imgPath: "/assets/emoji/thumb_18.png",
        unicode: "0x1F441",
        shortCode: ":f_strong",
        convertedCode: "👁",
      },
      {
        imgPath: "/assets/emoji/thumb_20.png",
        unicode: "0x1F443",
        shortCode: ":f_punch_two",
        convertedCode: "👃",
      },
      {
        imgPath: "/assets/emoji/thumb_19.png",
        unicode: "0x1F442",
        shortCode: ":f_punch",
        convertedCode: "👂",
      },
    ],
  },
];

export const emojis = [
  {
    imgPath: "/assets/emoji/uni_0.png",
    unicode: "0x1F100",
    shortCode: ":)",
    regx: /:\)/g,
    convertedCode: "🄀",
  },
  {
    imgPath: "/assets/emoji/uni_1.png",
    unicode: "0x1F101",
    shortCode: ">:(",
    regx: />:\(/g,
    convertedCode: "🄁",
  },
  {
    imgPath: "/assets/emoji/uni_2.png",
    unicode: "0x1F102",
    shortCode: ":|",
    regx: /:\|/g,
    convertedCode: "🄂",
  },
  {
    imgPath: "/assets/emoji/uni_3.png",
    unicode: "0x1F103",
    shortCode: ":D",
    regx: /:D/g,
    convertedCode: "🄃",
  },
  {
    imgPath: "/assets/emoji/uni_4.png",
    unicode: "0x1F104",
    shortCode: ":p",
    regx: /:p/g,
    convertedCode: "🄄",
  },
  {
    imgPath: "/assets/emoji/uni_5.png",
    unicode: "0x1F105",
    shortCode: ">:",
    regx: />:/g,
    convertedCode: "🄅",
  },
  {
    imgPath: "/assets/emoji/uni_6.png",
    unicode: "0x1F106",
    shortCode: ':"(',
    regx: /:"\(/g,
    convertedCode: "🄆",
  },
  {
    imgPath: "/assets/emoji/uni_7.png",
    unicode: "0x1F107",
    shortCode: "(toomuchlove)",
    regx: /\(toomuchlove\)/g,
    convertedCode: "🄇",
  },
  {
    imgPath: "/assets/emoji/uni_8.png",
    unicode: "0x1F108",
    shortCode: ":-)",
    regx: /:-\)/g,
    convertedCode: "🄈",
  },
  {
    imgPath: "/assets/emoji/uni_9.png",
    unicode: "0x1F109",
    shortCode: ":8)",
    regx: /:8\)/g,
    convertedCode: "🄉",
  },
  {
    imgPath: "/assets/emoji/uni_10.png",
    unicode: "0x1F110",
    shortCode: "(gentle)",
    regx: /\(gentle\)/g,
    convertedCode: "🄐",
  },
  {
    imgPath: "/assets/emoji/uni_11.png",
    unicode: "0x1F111",
    shortCode: ":o",
    regx: /:o/g,
    convertedCode: "🄑",
  },
  {
    imgPath: "/assets/emoji/uni_12.png",
    unicode: "0x1F112",
    shortCode: ":~o",
    regx: /:~o/g,
    convertedCode: "🄒",
  },
  {
    imgPath: "/assets/emoji/uni_13.png",
    unicode: "0x1F113",
    shortCode: ";)",
    regx: /;\)/g,
    convertedCode: "🄓",
  },
  {
    imgPath: "/assets/emoji/uni_14.png",
    unicode: "0x1F114",
    shortCode: "^.^",
    regx: /\^\.\^/g,
    convertedCode: "🄔",
  },
  {
    imgPath: "/assets/emoji/uni_15.png",
    unicode: "0x1F115",
    shortCode: ":(",
    regx: /:\(/g,
    convertedCode: "🄕",
  },
  {
    imgPath: "/assets/emoji/uni_16.png",
    unicode: "0x1F116",
    shortCode: "~.–",
    regx: /~\.–/g,
    convertedCode: "🄖",
  },
  {
    imgPath: "/assets/emoji/uni_17.png",
    unicode: "0x1F117",
    shortCode: ":\\",
    regx: /:\\\\/g,
    convertedCode: "🄗",
  },
  {
    imgPath: "/assets/emoji/uni_18.png",
    unicode: "0x1F118",
    shortCode: "(whythat)",
    regx: /\(whythat\)/g,
    convertedCode: "🄘",
  },
  {
    imgPath: "/assets/emoji/uni_19.png",
    unicode: "0x1F119",
    shortCode: ">.<",
    regx: />\.</g,
    convertedCode: "🄙",
  },
  {
    imgPath: "/assets/emoji/uni_20.png",
    unicode: "0x1F120",
    shortCode: ":/",
    regx: /:/g,
    convertedCode: "🄠",
  },
  {
    imgPath: "/assets/emoji/uni_21.png",
    unicode: "0x1F121",
    shortCode: ":'(",
    regx: /:'\(/g,
    convertedCode: "🄡",
  },
  {
    imgPath: "/assets/emoji/uni_22.png",
    unicode: "0x1F122",
    shortCode: "(silent)",
    regx: /\(silent\)/g,
    convertedCode: "🄢",
  },
  {
    imgPath: "/assets/emoji/uni_23.png",
    unicode: "0x1F123",
    shortCode: "/;)",
    regx: /;\)/g,
    convertedCode: "🄣",
  },
  {
    imgPath: "/assets/emoji/uni_24.png",
    unicode: "0x1F124",
    shortCode: "(exhausted)",
    regx: /\(exhausted\)/g,
    convertedCode: "🄤",
  },
  {
    imgPath: "/assets/emoji/uni_25.png",
    unicode: "0x1F125",
    shortCode: "(LOL)",
    regx: /\(LOL\)/g,
    convertedCode: "🄥",
  },
  {
    imgPath: "/assets/emoji/uni_26.png",
    unicode: "0x1F126",
    shortCode: ":'(",
    regx: /:'\(/g,
    convertedCode: "🄦",
  },
  {
    imgPath: "/assets/emoji/uni_27.png",
    unicode: "0x1F127",
    shortCode: "(oh-no)",
    regx: /\(oh-no\)/g,
    convertedCode: "🄧",
  },
  {
    imgPath: "/assets/emoji/uni_28.png",
    unicode: "0x1F128",
    shortCode: "(flirt)",
    regx: /\(flirt\)/g,
    convertedCode: "🄨",
  },
  {
    imgPath: "/assets/emoji/uni_29.png",
    unicode: "0x1F129",
    shortCode: "(doh)",
    regx: /\(doh\)/g,
    convertedCode: "🄩",
  },
  {
    imgPath: "/assets/emoji/uni_30.png",
    unicode: "0x1F130",
    shortCode: "(rofl)",
    regx: /\(rofl\)/g,
    convertedCode: "🄰",
  },
  {
    imgPath: "/assets/emoji/uni_31.png",
    unicode: "0x1F131",
    shortCode: "(notme)",
    regx: /\(notme\)/g,
    convertedCode: "🄱",
  },
  {
    imgPath: "/assets/emoji/uni_32.png",
    unicode: "0x1F132",
    shortCode: "(cuddle)",
    regx: /\(cuddle\)/g,
    convertedCode: "🄲",
  },
  {
    imgPath: "/assets/emoji/uni_33.png",
    unicode: "0x1F133",
    shortCode: "(look)",
    regx: /\(look\)/g,
    convertedCode: "🄳",
  },
  {
    imgPath: "/assets/emoji/uni_34.png",
    unicode: "0x1F134",
    shortCode: "(eh)",
    regx: /\(eh\)/g,
    convertedCode: "🄴",
  },
  {
    imgPath: "/assets/emoji/uni_35.png",
    unicode: "0x1F135",
    shortCode: "(attitude)",
    regx: /\(attitude\)/g,
    convertedCode: "🄵",
  },
  {
    imgPath: "/assets/emoji/uni_36.png",
    unicode: "0x1F136",
    shortCode: "(bum)",
    regx: /\(bum\)/g,
    convertedCode: "🄶",
  },
  {
    imgPath: "/assets/emoji/uni_37.png",
    unicode: "0x1F137",
    shortCode: "(confused)",
    regx: /\(confused\)/g,
    convertedCode: "🄷",
  },
  {
    imgPath: "/assets/emoji/uni_38.png",
    unicode: "0x1F138",
    shortCode: "(droll)",
    regx: /\(droll\)/g,
    convertedCode: "🄸",
  },
  {
    imgPath: "/assets/emoji/uni_39.png",
    unicode: "0x1F139",
    shortCode: "(vomit)",
    regx: /\(vomit\)/g,
    convertedCode: "🄹",
  },
  {
    imgPath: "/assets/emoji/uni_40.png",
    unicode: "0x1F140",
    shortCode: "(poo)",
    regx: /\(poo\)/g,
    convertedCode: "🅀",
  },
  {
    imgPath: "/assets/emoji/uni_41.png",
    unicode: "0x1F141",
    shortCode: "(devil)",
    regx: /\(devil\)/g,
    convertedCode: "🅁",
  },
  {
    imgPath: "/assets/emoji/uni_42.png",
    unicode: "0x1F142",
    shortCode: "(angel)",
    regx: /\(angel\)/g,
    convertedCode: "🅂",
  },
  {
    imgPath: "/assets/emoji/uni_43.png",
    unicode: "0x1F143",
    shortCode: "(vamp)",
    regx: /\(vamp\)/g,
    convertedCode: "🅃",
  },
  {
    imgPath: "/assets/emoji/uni_44.png",
    unicode: "0x1F144",
    shortCode: "(alien)",
    regx: /\(alien\)/g,
    convertedCode: "🅄",
  },
  {
    imgPath: "/assets/emoji/uni_45.png",
    unicode: "0x1F145",
    shortCode: "(scary)",
    regx: /\(scary\)/g,
    convertedCode: "🅅",
  },
  {
    imgPath: "/assets/emoji/uni_46.png",
    unicode: "0x1F146",
    shortCode: "(kiss)",
    regx: /\(kiss\)/g,
    convertedCode: "🅆",
  },
  {
    imgPath: "/assets/emoji/uni_47.png",
    unicode: "0x1F147",
    shortCode: "(hug)",
    regx: /\(hug\)/g,
    convertedCode: "🅇",
  },
  {
    imgPath: "/assets/emoji/uni_48.png",
    unicode: "0x1F148",
    shortCode: "(love)",
    regx: /\(love\)/g,
    convertedCode: "🅈",
  },
  {
    imgPath: "/assets/emoji/uni_49.png",
    unicode: "0x1F149",
    shortCode: "(broken_heart)",
    regx: /\(broken_heart\)/g,
    convertedCode: "🅉",
  },
  {
    imgPath: "/assets/emoji/uni_50.png",
    unicode: "0x1F150",
    shortCode: "(pink_hearts)",
    regx: /\(pink_hearts\)/g,
    convertedCode: "🅐",
  },
  {
    imgPath: "/assets/emoji/uni_51.png",
    unicode: "0x1F151",
    shortCode: "(ring)",
    regx: /\(ring\)/g,
    convertedCode: "🅑",
  },
  {
    imgPath: "/assets/emoji/uni_52.png",
    unicode: "0x1F152",
    shortCode: "(gift)",
    regx: /\(gift\)/g,
    convertedCode: "🅒",
  },
  {
    imgPath: "/assets/emoji/uni_53.png",
    unicode: "0x1F153",
    shortCode: "(teddybear)",
    regx: /\(teddybear\)/g,
    convertedCode: "🅓",
  },
  {
    imgPath: "/assets/emoji/uni_54.png",
    unicode: "0x1F154",
    shortCode: "(rose)",
    regx: /\(rose\)/g,
    convertedCode: "🅔",
  },
  {
    imgPath: "/assets/emoji/uni_55.png",
    unicode: "0x1F155",
    shortCode: "(flowers)",
    regx: /\(flowers\)/g,
    convertedCode: "🅕",
  },
  {
    imgPath: "/assets/emoji/uni_56.png",
    unicode: "0x1F156",
    shortCode: "(heart)",
    regx: /\(heart\)/g,
    convertedCode: "🅖",
  },
  {
    imgPath: "/assets/emoji/uni_57.png",
    unicode: "0x1F157",
    shortCode: "(music)",
    regx: /\(music\)/g,
    convertedCode: "🅗",
  },
  {
    imgPath: "/assets/emoji/uni_58.png",
    unicode: "0x1F158",
    shortCode: "(balloons)",
    regx: /\(balloons\)/g,
    convertedCode: "🅘",
  },
  {
    imgPath: "/assets/emoji/uni_59.png",
    unicode: "0x1F159",
    shortCode: "(cupid)",
    regx: /\(cupid\)/g,
    convertedCode: "🅙",
  },
  {
    imgPath: "/assets/emoji/uni_60.png",
    unicode: "0x1F160",
    shortCode: "(bell)",
    regx: /\(bell\)/g,
    convertedCode: "🅠",
  },
  {
    imgPath: "/assets/emoji/uni_61.png",
    unicode: "0x1F161",
    shortCode: "(call_me)",
    regx: /\(call_me\)/g,
    convertedCode: "🅡",
  },
  {
    imgPath: "/assets/emoji/uni_62.png",
    unicode: "0x1F162",
    shortCode: "(girl)",
    regx: /\(girl\)/g,
    convertedCode: "🅢",
  },
  {
    imgPath: "/assets/emoji/uni_63.png",
    unicode: "0x1F163",
    shortCode: "(boy)",
    regx: /\(boy\)/g,
    convertedCode: "🅣",
  },
  {
    imgPath: "/assets/emoji/uni_64.png",
    unicode: "0x1F164",
    shortCode: "(beauty)",
    regx: /\(beauty\)/g,
    convertedCode: "🅤",
  },
  {
    imgPath: "/assets/emoji/uni_65.png",
    unicode: "0x1F165",
    shortCode: "(dinosaur)",
    regx: /\(dinosaur\)/g,
    convertedCode: "🅥",
  },
  {
    imgPath: "/assets/emoji/uni_66.png",
    unicode: "0x1F166",
    shortCode: "(snake)",
    regx: /\(snake\)/g,
    convertedCode: "🅦",
  },
  {
    imgPath: "/assets/emoji/uni_67.png",
    unicode: "0x1F167",
    shortCode: "(ox)",
    regx: /\(ox\)/g,
    convertedCode: "🅧",
  },
  {
    imgPath: "/assets/emoji/uni_68.png",
    unicode: "0x1F168",
    shortCode: "(chicken)",
    regx: /\(chicken\)/g,
    convertedCode: "🅨",
  },
  {
    imgPath: "/assets/emoji/uni_69.png",
    unicode: "0x1F169",
    shortCode: "(cow)",
    regx: /\(cow\)/g,
    convertedCode: "🅩",
  },
  {
    imgPath: "/assets/emoji/uni_70.png",
    unicode: "0x1F170",
    shortCode: "(dog)",
    regx: /\(dog\)/g,
    convertedCode: "🅰",
  },
  {
    imgPath: "/assets/emoji/uni_71.png",
    unicode: "0x1F171",
    shortCode: "(horse)",
    regx: /\(horse\)/g,
    convertedCode: "🅱",
  },
  {
    imgPath: "/assets/emoji/uni_72.png",
    unicode: "0x1F172",
    shortCode: "(monkey)",
    regx: /\(monkey\)/g,
    convertedCode: "🅲",
  },
  {
    imgPath: "/assets/emoji/uni_73.png",
    unicode: "0x1F173",
    shortCode: "(mouse)",
    regx: /\(mouse\)/g,
    convertedCode: "🅳",
  },
  {
    imgPath: "/assets/emoji/uni_74.png",
    unicode: "0x1F174",
    shortCode: "(rabbit)",
    regx: /\(rabbit\)/g,
    convertedCode: "🅴",
  },
  {
    imgPath: "/assets/emoji/uni_75.png",
    unicode: "0x1F175",
    shortCode: "(tiger)",
    regx: /\(tiger\)/g,
    convertedCode: "🅵",
  },
  {
    imgPath: "/assets/emoji/uni_76.png",
    unicode: "0x1F176",
    shortCode: "(soccer)",
    regx: /\(soccer\)/g,
    convertedCode: "🅶",
  },
  {
    imgPath: "/assets/emoji/uni_77.png",
    unicode: "0x1F177",
    shortCode: "(batsman)",
    regx: /\(batsman\)/g,
    convertedCode: "🅷",
  },
  {
    imgPath: "/assets/emoji/uni_78.png",
    unicode: "0x1F178",
    shortCode: "(winner)",
    regx: /\(winner\)/g,
    convertedCode: "🅸",
  },
  {
    imgPath: "/assets/emoji/uni_79.png",
    unicode: "0x1F179",
    shortCode: "(kick)",
    regx: /\(kick\)/g,
    convertedCode: "🅹",
  },
  {
    imgPath: "/assets/emoji/uni_80.png",
    unicode: "0x1F180",
    shortCode: "(workout)",
    regx: /\(workout\)/g,
    convertedCode: "🆀",
  },
  {
    imgPath: "/assets/emoji/uni_81.png",
    unicode: "0x1F181",
    shortCode: "(forgive)",
    regx: /\(forgive\)/g,
    convertedCode: "🆁",
  },
  {
    imgPath: "/assets/emoji/uni_82.png",
    unicode: "0x1F182",
    shortCode: "(punch_left)",
    regx: /\(punch_left\)/g,
    convertedCode: "🆂",
  },
  {
    imgPath: "/assets/emoji/uni_83.png",
    unicode: "0x1F183",
    shortCode: "(punch_right)",
    regx: /\(punch_right\)/g,
    convertedCode: "🆃",
  },
  {
    imgPath: "/assets/emoji/uni_84.png",
    unicode: "0x1F184",
    shortCode: "(handshake)",
    regx: /\(handshake\)/g,
    convertedCode: "🆄",
  },
  {
    imgPath: "/assets/emoji/uni_85.png",
    unicode: "0x1F185",
    shortCode: "(excellent)",
    regx: /\(excellent\)/g,
    convertedCode: "🆅",
  },
  {
    imgPath: "/assets/emoji/uni_86.png",
    unicode: "0x1F186",
    shortCode: "(thump_up)",
    regx: /\(thump_up\)/g,
    convertedCode: "🆆",
  },
  {
    imgPath: "/assets/emoji/uni_87.png",
    unicode: "0x1F187",
    shortCode: "(punch)",
    regx: /\(punch\)/g,
    convertedCode: "🆇",
  },
  {
    imgPath: "/assets/emoji/uni_88.png",
    unicode: "0x1F188",
    shortCode: "(finger_1)",
    regx: /\(finger_1\)/g,
    convertedCode: "🆈",
  },
  {
    imgPath: "/assets/emoji/uni_89.png",
    unicode: "0x1F189",
    shortCode: "(finger_2)",
    regx: /\(finger_2\)/g,
    convertedCode: "🆉",
  },
  {
    imgPath: "/assets/emoji/uni_90.png",
    unicode: "0x1F190",
    shortCode: "(finger_3)",
    regx: /\(finger_3\)/g,
    convertedCode: "🆐",
  },
  {
    imgPath: "/assets/emoji/uni_91.png",
    unicode: "0x1F191",
    shortCode: "(finger_4)",
    regx: /\(finger_4\)/g,
    convertedCode: "🆑",
  },
  {
    imgPath: "/assets/emoji/uni_92.png",
    unicode: "0x1F192",
    shortCode: "(finger_5)",
    regx: /\(finger_5\)/g,
    convertedCode: "🆒",
  },
  {
    imgPath: "/assets/emoji/uni_93.png",
    unicode: "0x1F193",
    shortCode: "(finger_6)",
    regx: /\(finger_6\)/g,
    convertedCode: "🆓",
  },
  {
    imgPath: "/assets/emoji/uni_94.png",
    unicode: "0x1F194",
    shortCode: "(finger_7)",
    regx: /\(finger_7\)/g,
    convertedCode: "🆔",
  },
  {
    imgPath: "/assets/emoji/uni_95.png",
    unicode: "0x1F195",
    shortCode: "(finger_8)",
    regx: /\(finger_8\)/g,
    convertedCode: "🆕",
  },
  {
    imgPath: "/assets/emoji/uni_96.png",
    unicode: "0x1F196",
    shortCode: "(beer)",
    regx: /\(beer\)/g,
    convertedCode: "🆖",
  },
  {
    imgPath: "/assets/emoji/uni_97.png",
    unicode: "0x1F197",
    shortCode: "(cake)",
    regx: /\(cake\)/g,
    convertedCode: "🆗",
  },
  {
    imgPath: "/assets/emoji/uni_98.png",
    unicode: "0x1F198",
    shortCode: "(drink)",
    regx: /\(drink\)/g,
    convertedCode: "🆘",
  },
  {
    imgPath: "/assets/emoji/uni_99.png",
    unicode: "0x1F199",
    shortCode: "(ice_cream)",
    regx: /\(ice_cream\)/g,
    convertedCode: "🆙",
  },
  {
    imgPath: "/assets/emoji/uni_100.png",
    unicode: "0x1F200",
    shortCode: "(g_earpod)",
    regx: /\(g_earpod\)/g,
    convertedCode: "🈀",
  },
  {
    imgPath: "/assets/emoji/uni_101.png",
    unicode: "0x1F201",
    shortCode: "(g_confused)",
    regx: /\(g_confused\)/g,
    convertedCode: "🈁",
  },
  {
    imgPath: "/assets/emoji/uni_102.png",
    unicode: "0x1F202",
    shortCode: "(g_hi)",
    regx: /\(g_hi\)/g,
    convertedCode: "🈂",
  },
  {
    imgPath: "/assets/emoji/uni_103.png",
    unicode: "0x1F203",
    shortCode: "(g_boo)",
    regx: /\(g_boo\)/g,
    convertedCode: "🈃",
  },
  {
    imgPath: "/assets/emoji/uni_104.png",
    unicode: "0x1F204",
    shortCode: "(g_umm)",
    regx: /\(g_umm\)/g,
    convertedCode: "🈄",
  },
  {
    imgPath: "/assets/emoji/uni_105.png",
    unicode: "0x1F205",
    shortCode: "(g_look)",
    regx: /\(g_look\)/g,
    convertedCode: "🈅",
  },
  {
    imgPath: "/assets/emoji/uni_106.png",
    unicode: "0x1F206",
    shortCode: "(g_ninja)",
    regx: /\(g_ninja\)/g,
    convertedCode: "🈆",
  },
  {
    imgPath: "/assets/emoji/uni_107.png",
    unicode: "0x1F207",
    shortCode: "(star)",
    regx: /\(star\)/g,
    convertedCode: "🈇",
  },
  {
    imgPath: "/assets/emoji/uni_108.png",
    unicode: "0x1F208",
    shortCode: "(sun)",
    regx: /\(sun\)/g,
    convertedCode: "🈈",
  },
  {
    imgPath: "/assets/emoji/uni_109.png",
    unicode: "0x1F279",
    shortCode: "(rainbow)",
    regx: /\(rainbow\)/g,
    convertedCode: "🉹",
  },
  {
    imgPath: "/assets/emoji/uni_110.png",
    unicode: "0x1F280",
    shortCode: "(cloud)",
    regx: /\(cloud\)/g,
    convertedCode: "🊀",
  },
  {
    imgPath: "/assets/emoji/uni_111.png",
    unicode: "0x1F281",
    shortCode: "(light)",
    regx: /\(light\)/g,
    convertedCode: "🊁",
  },
  {
    imgPath: "/assets/emoji/uni_112.png",
    unicode: "0x1F282",
    shortCode: "(alien_laugh)",
    regx: /\(alien_laugh\)/g,
    convertedCode: "🊂",
  },
  {
    imgPath: "/assets/emoji/uni_113.png",
    unicode: "0x1F283",
    shortCode: "alien_smile",
    regx: /alien_smile/g,
    convertedCode: "🊃",
  },
  {
    imgPath: "/assets/emoji/uni_114.png",
    unicode: "0x1F284",
    shortCode: "(alien_confused)",
    regx: /\(alien_confused\)/g,
    convertedCode: "🊄",
  },
  {
    imgPath: "/assets/emoji/uni_115.png",
    unicode: "0x1F285",
    shortCode: "(alien_angry)",
    regx: /\(alien_angry\)/g,
    convertedCode: "🊅",
  },
  {
    imgPath: "/assets/emoji/uni_116.png",
    unicode: "0x1F286",
    shortCode: "(alien_cool)",
    regx: /\(alien_cool\)/g,
    convertedCode: "🊆",
  },
  {
    imgPath: "/assets/emoji/uni_117.png",
    unicode: "0x1F287",
    shortCode: "(alien_cry)",
    regx: /\(alien_cry\)/g,
    convertedCode: "🊇",
  },
  {
    imgPath: "/assets/emoji/uni_118.png",
    unicode: "0x1F288",
    shortCode: "(alien_hot)",
    regx: /\(alien_hot\)/g,
    convertedCode: "🊈",
  },
  {
    imgPath: "/assets/emoji/uni_119.png",
    unicode: "0x1F289",
    shortCode: "(alien_lol)",
    regx: /\(alien_lol\)/g,
    convertedCode: "🊉",
  },
  {
    imgPath: "/assets/emoji/uni_120.png",
    unicode: "0x1F290",
    shortCode: "(heart_laugh)",
    regx: /\(heart_laugh\)/g,
    convertedCode: "🊐",
  },
  {
    imgPath: "/assets/emoji/uni_121.png",
    unicode: "0x1F291",
    shortCode: "(heart_omg)",
    regx: /\(heart_omg\)/g,
    convertedCode: "🊑",
  },
  {
    imgPath: "/assets/emoji/uni_122.png",
    unicode: "0x1F292",
    shortCode: "(heart_burn)",
    regx: /\(heart_burn\)/g,
    convertedCode: "🊒",
  },
  {
    imgPath: "/assets/emoji/uni_123.png",
    unicode: "0x1F293",
    shortCode: "(heart_wink)",
    regx: /\(heart_wink\)/g,
    convertedCode: "🊓",
  },
  {
    imgPath: "/assets/emoji/uni_124.png",
    unicode: "0x1F294",
    shortCode: "(heart-mood-off)",
    regx: /\(heart-mood-off\)/g,
    convertedCode: "🊔",
  },
  {
    imgPath: "/assets/emoji/uni_125.png",
    unicode: "0x1F295",
    shortCode: "(sad_dog)",
    regx: /\(sad_dog\)/g,
    convertedCode: "🊕",
  },
  {
    imgPath: "/assets/emoji/uni_126.png",
    unicode: "0x1F296",
    shortCode: "(angry_dog)",
    regx: /\(angry_dog\)/g,
    convertedCode: "🊖",
  },
  {
    imgPath: "/assets/emoji/uni_127.png",
    unicode: "0x1F297",
    shortCode: "(happy_dog)",
    regx: /\(happy_dog\)/g,
    convertedCode: "🊗",
  },
  {
    imgPath: "/assets/emoji/uni_128.png",
    unicode: "0x1F298",
    shortCode: "ghost_cry)",
    regx: /ghost_cry\)/g,
    convertedCode: "🊘",
  },
  {
    imgPath: "/assets/emoji/uni_129.png",
    unicode: "0x1F299",
    shortCode: "(ghost_laugh)",
    regx: /\(ghost_laugh\)/g,
    convertedCode: "🊙",
  },
  {
    imgPath: "/assets/emoji/uni_130.png",
    unicode: "0x1F300",
    shortCode: "(ghost_dizzy)",
    regx: /\(ghost_dizzy\)/g,
    convertedCode: "🌀",
  },
  {
    imgPath: "/assets/emoji/uni_131.png",
    unicode: "0x1F301",
    shortCode: "(ghost_confused)",
    regx: /\(ghost_confused\)/g,
    convertedCode: "🌁",
  },
  {
    imgPath: "/assets/emoji/uni_132.png",
    unicode: "0x1F302",
    shortCode: "(bear_smile)",
    regx: /\(bear_smile\)/g,
    convertedCode: "🌂",
  },
  {
    imgPath: "/assets/emoji/uni_133.png",
    unicode: "0x1F303",
    shortCode: "(bear_sad)",
    regx: /\(bear_sad\)/g,
    convertedCode: "🌃",
  },
  {
    imgPath: "/assets/emoji/uni_134.png",
    unicode: "0x1F304",
    shortCode: "(bear_shock)",
    regx: /\(bear_shock\)/g,
    convertedCode: "🌄",
  },
  {
    imgPath: "/assets/emoji/uni_135.png",
    unicode: "0x1F305",
    shortCode: "(bear_laugh)",
    regx: /\(bear_laugh\)/g,
    convertedCode: "🌅",
  },
  {
    imgPath: "/assets/emoji/uni_136.png",
    unicode: "0x1F306",
    shortCode: "(bear_cry)",
    regx: /\(bear_cry\)/g,
    convertedCode: "🌆",
  },
  {
    imgPath: "/assets/emoji/uni_137.png",
    unicode: "0x1F307",
    shortCode: "(bear_ok)",
    regx: /\(bear_ok\)/g,
    convertedCode: "🌇",
  },
  {
    imgPath: "/assets/emoji/uni_138.png",
    unicode: "0x1F308",
    shortCode: "(clap)",
    regx: /\(clap\)/g,
    convertedCode: "🌈",
  },
  {
    imgPath: "/assets/emoji/uni_139.png",
    unicode: "0x1F309",
    shortCode: "(ahem)",
    regx: /\(ahem\)/g,
    convertedCode: "🌉",
  },
  {
    imgPath: "/assets/emoji/uni_140.png",
    unicode: "0x1F310",
    shortCode: "((damn)",
    regx: /\(\(damn\)/g,
    convertedCode: "🌐",
  },
  {
    imgPath: "/assets/emoji/uni_141.png",
    unicode: "0x1F311",
    shortCode: "(omg)",
    regx: /\(omg\)/g,
    convertedCode: "🌑",
  },
  {
    imgPath: "/assets/emoji/uni_142.png",
    unicode: "0x1F312",
    shortCode: "(excuse_me)",
    regx: /\(excuse_me\)/g,
    convertedCode: "🌒",
  },
  {
    imgPath: "/assets/emoji/uni_143.png",
    unicode: "0x1F313",
    shortCode: "(blackstabber)",
    regx: /\(blackstabber\)/g,
    convertedCode: "🌓",
  },
  {
    imgPath: "/assets/emoji/uni_144.png",
    unicode: "0x1F314",
    shortCode: "(nosey)",
    regx: /\(nosey\)/g,
    convertedCode: "🌔",
  },
  {
    imgPath: "/assets/emoji/uni_145.png",
    unicode: "0x1F315",
    shortCode: "(cough)",
    regx: /\(cough\)/g,
    convertedCode: "🌕",
  },
  {
    imgPath: "/assets/emoji/uni_146.png",
    unicode: "0x1F316",
    shortCode: "(naughty)",
    regx: /\(naughty\)/g,
    convertedCode: "🌖",
  },
  {
    imgPath: "/assets/emoji/uni_147.png",
    unicode: "0x1F317",
    shortCode: "(boo)",
    regx: /\(boo\)/g,
    convertedCode: "🌗",
  },
  {
    imgPath: "/assets/emoji/uni_148.png",
    unicode: "0x1F318",
    shortCode: "(oh_no)",
    regx: /\(oh_no\)/g,
    convertedCode: "🌘",
  },
  {
    imgPath: "/assets/emoji/uni_149.png",
    unicode: "0x1F319",
    shortCode: "(smoker)",
    regx: /\(smoker\)/g,
    convertedCode: "🌙",
  },
  {
    imgPath: "/assets/emoji/uni_150.png",
    unicode: "0x1F320",
    shortCode: "(cough)",
    regx: /\(cough\)/g,
    convertedCode: "🌠",
  },
  {
    imgPath: "/assets/emoji/uni_151.png",
    unicode: "0x1F321",
    shortCode: "(naughty)",
    regx: /\(naughty\)/g,
    convertedCode: "🌡",
  },
  {
    imgPath: "/assets/emoji/uni_152.png",
    unicode: "0x1F322",
    shortCode: "(boo)",
    regx: /\(boo\)/g,
    convertedCode: "🌢",
  },
  {
    imgPath: "/assets/emoji/uni_153.png",
    unicode: "0x1F323",
    shortCode: "(oh_no)",
    regx: /\(oh_no\)/g,
    convertedCode: "🌣",
  },
  {
    imgPath: "/assets/emoji/uni_154.png",
    unicode: "0x1F324",
    shortCode: "(smoker)",
    regx: /\(smoker\)/g,
    convertedCode: "🌤",
  },
  {
    imgPath: "/assets/emoji/uni_155.png",
    unicode: "0x1F325",
    shortCode: "(kiss_back)",
    regx: /\(kiss_back\)/g,
    convertedCode: "🌥",
  },
  {
    imgPath: "/assets/emoji/uni_156.png",
    unicode: "0x1F326",
    shortCode: "(kiss_me)",
    regx: /\(kiss_me\)/g,
    convertedCode: "🌦",
  },
  {
    imgPath: "/assets/emoji/uni_157.png",
    unicode: "0x1F327",
    shortCode: "(shy)",
    regx: /\(shy\)/g,
    convertedCode: "🌧",
  },
  {
    imgPath: "/assets/emoji/uni_158.png",
    unicode: "0x1F328",
    shortCode: "(sleepy)",
    regx: /\(sleepy\)/g,
    convertedCode: "🌨",
  },
  {
    imgPath: "/assets/emoji/emotion_0.png",
    unicode: "0x1F324",
    shortCode: ":s_smile",
    regx: /:s_smile/g,
    convertedCode: "🌤",
  },
  {
    imgPath: "/assets/emoji/emotion_1.png",
    unicode: "0x1F325",
    shortCode: ":s_lough",
    regx: /:s_lough/g,
    convertedCode: "🌥",
  },
  {
    imgPath: "/assets/emoji/emotion_2.png",
    unicode: "0x1F326",
    shortCode: ":s_lol",
    regx: /:s_lol/g,
    convertedCode: "🌦",
  },
  {
    imgPath: "/assets/emoji/emotion_3.png",
    unicode: "0x1F327",
    shortCode: ":s_sblol",
    regx: /:s_sblol/g,
    convertedCode: "🌧",
  },
  {
    imgPath: "/assets/emoji/emotion_4.png",
    unicode: "0x1F328",
    shortCode: ":s_selol",
    regx: /:s_selol/g,
    convertedCode: "🌨",
  },
  {
    imgPath: "/assets/emoji/emotion_5.png",
    unicode: "0x1F329",
    shortCode: ":s_lfmao",
    regx: /:s_lfmao/g,
    convertedCode: "🌩",
  },
  {
    imgPath: "/assets/emoji/emotion_6.png",
    unicode: "0x1F330",
    shortCode: ":s_happy",
    regx: /:s_happy/g,
    convertedCode: "🌰",
  },
  {
    imgPath: "/assets/emoji/emotion_7.png",
    unicode: "0x1F331",
    shortCode: ":s_wink",
    regx: /:s_wink/g,
    convertedCode: "🌱",
  },
  {
    imgPath: "/assets/emoji/emotion_8.png",
    unicode: "0x1F332",
    shortCode: ":s_vhappy",
    regx: /:s_vhappy/g,
    convertedCode: "🌲",
  },
  {
    imgPath: "/assets/emoji/emotion_9.png",
    unicode: "0x1F333",
    shortCode: ":s_angel",
    regx: /:s_angel/g,
    convertedCode: "🌳",
  },
  {
    imgPath: "/assets/emoji/emotion_10.png",
    unicode: "0x1F334",
    shortCode: ":s_love",
    regx: /:s_love/g,
    convertedCode: "🌴",
  },
  {
    imgPath: "/assets/emoji/emotion_11.png",
    unicode: "0x1F335",
    shortCode: ":s_inlove",
    regx: /:s_inlove/g,
    convertedCode: "🌵",
  },
  {
    imgPath: "/assets/emoji/emotion_12.png",
    unicode: "0x1F336",
    shortCode: ":s_star",
    regx: /:s_star/g,
    convertedCode: "🌶",
  },
  {
    imgPath: "/assets/emoji/emotion_13.png",
    unicode: "0x1F337",
    shortCode: ":s_hm",
    regx: /:s_hm/g,
    convertedCode: "🌷",
  },
  {
    imgPath: "/assets/emoji/emotion_14.png",
    unicode: "0x1F338",
    shortCode: ":s_yum",
    regx: /:s_yum/g,
    convertedCode: "🌸",
  },
  {
    imgPath: "/assets/emoji/emotion_15.png",
    unicode: "0x1F339",
    shortCode: ":s_yumlol",
    regx: /:s_yumlol/g,
    convertedCode: "🌹",
  },
  {
    imgPath: "/assets/emoji/emotion_16.png",
    unicode: "0x1F340",
    shortCode: ":s_kissw",
    regx: /:s_kissw/g,
    convertedCode: "🍀",
  },
  {
    imgPath: "/assets/emoji/emotion_17.png",
    unicode: "0x1F341",
    shortCode: ":s_kissh",
    regx: /:s_kissh/g,
    convertedCode: "🍁",
  },
  {
    imgPath: "/assets/emoji/emotion_18.png",
    unicode: "0x1F342",
    shortCode: ":s_kiss",
    regx: /:s_kiss/g,
    convertedCode: "🍂",
  },
  {
    imgPath: "/assets/emoji/emotion_19.png",
    unicode: "0x1F343",
    shortCode: ":s_ops",
    regx: /:s_ops/g,
    convertedCode: "🍃",
  },
  {
    imgPath: "/assets/emoji/emotion_20.png",
    unicode: "0x1F344",
    shortCode: ":s_ylolw",
    regx: /:s_ylolw/g,
    convertedCode: "🍄",
  },
  {
    imgPath: "/assets/emoji/emotion_21.png",
    unicode: "0x1F345",
    shortCode: ":s_yloly",
    regx: /:s_yloly/g,
    convertedCode: "🍅",
  },
  {
    imgPath: "/assets/emoji/emotion_22.png",
    unicode: "0x1F346",
    shortCode: ":s_ylolk",
    regx: /:s_ylolk/g,
    convertedCode: "🍆",
  },
  {
    imgPath: "/assets/emoji/emotion_23.png",
    unicode: "0x1F347",
    shortCode: ":s_ylole",
    regx: /:s_ylole/g,
    convertedCode: "🍇",
  },
  {
    imgPath: "/assets/emoji/emotion_24.png",
    unicode: "0x1F348",
    shortCode: ":s_ylolm",
    regx: /:s_ylolm/g,
    convertedCode: "🍈",
  },
  {
    imgPath: "/assets/emoji/emotion_25.png",
    unicode: "0x1F349",
    shortCode: ":s_eh",
    regx: /:s_eh/g,
    convertedCode: "🍉",
  },
  {
    imgPath: "/assets/emoji/emotion_26.png",
    unicode: "0x1F350",
    shortCode: ":s_eh",
    regx: /:s_eh/g,
    convertedCode: "🍐",
  },
  {
    imgPath: "/assets/emoji/emotion_27.png",
    unicode: "0x1F351",
    shortCode: ":s_oh",
    regx: /:s_oh/g,
    convertedCode: "🍑",
  },
  {
    imgPath: "/assets/emoji/emotion_28.png",
    unicode: "0x1F352",
    shortCode: ":s_hm",
    regx: /:s_hm/g,
    convertedCode: "🍒",
  },
  {
    imgPath: "/assets/emoji/emotion_29.png",
    unicode: "0x1F353",
    shortCode: ":s_ho",
    regx: /:s_ho/g,
    convertedCode: "🍓",
  },
  {
    imgPath: "/assets/emoji/emotion_30.png",
    unicode: "0x1F354",
    shortCode: ":s_cold",
    regx: /:s_cold/g,
    convertedCode: "🍔",
  },
  {
    imgPath: "/assets/emoji/emotion_31.png",
    unicode: "0x1F355",
    shortCode: ":s_sleepy",
    regx: /:s_sleepy/g,
    convertedCode: "🍕",
  },
  {
    imgPath: "/assets/emoji/emotion_32.png",
    unicode: "0x1F356",
    shortCode: ":s_what",
    regx: /:s_what/g,
    convertedCode: "🍖",
  },
  {
    imgPath: "/assets/emoji/emotion_33.png",
    unicode: "0x1F357",
    shortCode: ":s_sad",
    regx: /:s_sad/g,
    convertedCode: "🍗",
  },
  {
    imgPath: "/assets/emoji/emotion_34.png",
    unicode: "0x1F358",
    shortCode: ":s_wow",
    regx: /:s_wow/g,
    convertedCode: "🍘",
  },
  {
    imgPath: "/assets/emoji/emotion_35.png",
    unicode: "0x1F359",
    shortCode: ":s_woww",
    regx: /:s_woww/g,
    convertedCode: "🍙",
  },
  {
    imgPath: "/assets/emoji/emotion_36.png",
    unicode: "0x1F360",
    shortCode: ":s_wowww",
    regx: /:s_wowww/g,
    convertedCode: "🍠",
  },
  {
    imgPath: "/assets/emoji/emotion_37.png",
    unicode: "0x1F361",
    shortCode: ":s_shit",
    regx: /:s_shit/g,
    convertedCode: "🍡",
  },
  {
    imgPath: "/assets/emoji/emotion_38.png",
    unicode: "0x1F362",
    shortCode: ":s_sadv",
    regx: /:s_sadv/g,
    convertedCode: "🍢",
  },
  {
    imgPath: "/assets/emoji/emotion_39.png",
    unicode: "0x1F363",
    shortCode: ":s_crying",
    regx: /:s_crying/g,
    convertedCode: "🍣",
  },
  {
    imgPath: "/assets/emoji/emotion_40.png",
    unicode: "0x1F364",
    shortCode: ":s_stressed",
    regx: /:s_stressed/g,
    convertedCode: "🍤",
  },
  {
    imgPath: "/assets/emoji/emotion_41.png",
    unicode: "0x1F365",
    shortCode: ":s_angry",
    regx: /:s_angry/g,
    convertedCode: "🍥",
  },
  {
    imgPath: "/assets/emoji/emotion_42.png",
    unicode: "0x1F366",
    shortCode: ":s_tensed",
    regx: /:s_tensed/g,
    convertedCode: "🍦",
  },
  {
    imgPath: "/assets/emoji/emotion_43.png",
    unicode: "0x1F367",
    shortCode: ":s_emoless",
    regx: /:s_emoless/g,
    convertedCode: "🍧",
  },
  {
    imgPath: "/assets/emoji/emotion_44.png",
    unicode: "0x1F368",
    shortCode: ":s_ohho",
    regx: /:s_ohho/g,
    convertedCode: "🍨",
  },
  {
    imgPath: "/assets/emoji/emotion_45.png",
    unicode: "0x1F369",
    shortCode: ":s_crying_2",
    regx: /:s_crying_2/g,
    convertedCode: "🍩",
  },
  {
    imgPath: "/assets/emoji/emotion_46.png",
    unicode: "0x1F370",
    shortCode: ":s¬_freezing",
    regx: /:s¬_freezing/g,
    convertedCode: "🍰",
  },
  {
    imgPath: "/assets/emoji/emotion_47.png",
    unicode: "0x1F371",
    shortCode: ":s_angry",
    regx: /:s_angry/g,
    convertedCode: "🍱",
  },
  {
    imgPath: "/assets/emoji/emotion_48.png",
    unicode: "0x1F372",
    shortCode: ":s_hot",
    regx: /:s_hot/g,
    convertedCode: "🍲",
  },
  {
    imgPath: "/assets/emoji/emotion_49.png",
    unicode: "0x1F373",
    shortCode: ":s_sangry",
    regx: /:s_sangry/g,
    convertedCode: "🍳",
  },
  {
    imgPath: "/assets/emoji/emotion_50.png",
    unicode: "0x1F374",
    shortCode: ":s_poo",
    regx: /:s_poo/g,
    convertedCode: "🍴",
  },
  {
    imgPath: "/assets/emoji/black_0.png",
    unicode: "0x1F375",
    shortCode: "(black_silent)",
    regx: /\(black_silent\)/g,
    convertedCode: "🍵",
  },
  {
    imgPath: "/assets/emoji/black_1.png",
    unicode: "0x1F376",
    shortCode: "(black_angry)",
    regx: /\(black_angry\)/g,
    convertedCode: "🍶",
  },
  {
    imgPath: "/assets/emoji/black_2.png",
    unicode: "0x1F377",
    shortCode: "(black_shy)",
    regx: /\(black_shy\)/g,
    convertedCode: "🍷",
  },
  {
    imgPath: "/assets/emoji/black_3.png",
    unicode: "0x1F378",
    shortCode: "(black_teeth)",
    regx: /\(black_teeth\)/g,
    convertedCode: "🍸",
  },
  {
    imgPath: "/assets/emoji/black_4.png",
    unicode: "0x1F379",
    shortCode: "(black_hide)",
    regx: /\(black_hide\)/g,
    convertedCode: "🍹",
  },
  {
    imgPath: "/assets/emoji/black_5.png",
    unicode: "0x1F380",
    shortCode: "(black_smile)",
    regx: /\(black_smile\)/g,
    convertedCode: "🎀",
  },
  {
    imgPath: "/assets/emoji/black_6.png",
    unicode: "0x1F381",
    shortCode: "(black_love)",
    regx: /\(black_love\)/g,
    convertedCode: "🎁",
  },
  {
    imgPath: "/assets/emoji/black_7.png",
    unicode: "0x1F382",
    shortCode: "(black_cry)",
    regx: /\(black_cry\)/g,
    convertedCode: "🎂",
  },
  {
    imgPath: "/assets/emoji/black_8.png",
    unicode: "0x1F383",
    shortCode: "(black_blind)",
    regx: /\(black_blind\)/g,
    convertedCode: "🎃",
  },
  {
    imgPath: "/assets/emoji/black_9.png",
    unicode: "0x1F384",
    shortCode: "(black_hee)",
    regx: /\(black_hee\)/g,
    convertedCode: "🎄",
  },
  {
    imgPath: "/assets/emoji/black_10.png",
    unicode: "0x1F385",
    shortCode: "(black_ghost)",
    regx: /\(black_ghost\)/g,
    convertedCode: "🎅",
  },
  {
    imgPath: "/assets/emoji/black_11.png",
    unicode: "0x1F386",
    shortCode: "(black_cutelove)",
    regx: /\(black_cutelove\)/g,
    convertedCode: "🎆",
  },
  {
    imgPath: "/assets/emoji/black_12.png",
    unicode: "0x1F387",
    shortCode: "(black_evil)",
    regx: /\(black_evil\)/g,
    convertedCode: "🎇",
  },
  {
    imgPath: "/assets/emoji/black_13.png",
    unicode: "0x1F388",
    shortCode: "(black_tongue)",
    regx: /\(black_tongue\)/g,
    convertedCode: "🎈",
  },
  {
    imgPath: "/assets/emoji/black_14.png",
    unicode: "0x1F389",
    shortCode: "(black_teethshow)",
    regx: /\(black_teethshow\)/g,
    convertedCode: "🎉",
  },
  {
    imgPath: "/assets/emoji/black_15.png",
    unicode: "0x1F390",
    shortCode: "(black_kiss)",
    regx: /\(black_kiss\)/g,
    convertedCode: "🎐",
  },
  {
    imgPath: "/assets/emoji/black_16.png",
    unicode: "0x1F391",
    shortCode: "(black_dontknow)",
    regx: /\(black_dontknow\)/g,
    convertedCode: "🎑",
  },
  {
    imgPath: "/assets/emoji/black_17.png",
    unicode: "0x1F392",
    shortCode: "(black_dollar)",
    regx: /\(black_dollar\)/g,
    convertedCode: "🎒",
  },
  {
    imgPath: "/assets/emoji/black_18.png",
    unicode: "0x1F393",
    shortCode: "(black_dontcare)",
    regx: /\(black_dontcare\)/g,
    convertedCode: "🎓",
  },
  {
    imgPath: "/assets/emoji/black_19.png",
    unicode: "0x1F394",
    shortCode: "(black_huh)",
    regx: /\(black_huh\)/g,
    convertedCode: "🎔",
  },
  {
    imgPath: "/assets/emoji/black_20.png",
    unicode: "0x1F395",
    shortCode: "(black_redlove)",
    regx: /\(black_redlove\)/g,
    convertedCode: "🎕",
  },
  {
    imgPath: "/assets/emoji/black_21.png",
    unicode: "0x1F396",
    shortCode: "(black_don)",
    regx: /\(black_don\)/g,
    convertedCode: "🎖",
  },
  {
    imgPath: "/assets/emoji/black_22.png",
    unicode: "0x1F397",
    shortCode: "(black_sleeping)",
    regx: /\(black_sleeping\)/g,
    convertedCode: "🎗",
  },
  {
    imgPath: "/assets/emoji/black_23.png",
    unicode: "0x1F398",
    shortCode: "(black_innocent)",
    regx: /\(black_innocent\)/g,
    convertedCode: "🎘",
  },
  {
    imgPath: "/assets/emoji/black_24.png",
    unicode: "0x1F399",
    shortCode: "(black_notwell)",
    regx: /\(black_notwell\)/g,
    convertedCode: "🎙",
  },
  {
    imgPath: "/assets/emoji/black_25.png",
    unicode: "0x1F400",
    shortCode: "(black_letsfight)",
    regx: /\(black_letsfight\)/g,
    convertedCode: "🐀",
  },
  {
    imgPath: "/assets/emoji/black_26.png",
    unicode: "0x1F401",
    shortCode: "(black_cockroach)",
    regx: /\(black_cockroach\)/g,
    convertedCode: "🐁",
  },
  {
    imgPath: "/assets/emoji/black_27.png",
    unicode: "0x1F402",
    shortCode: "(black_blush)",
    regx: /\(black_blush\)/g,
    convertedCode: "🐂",
  },
  {
    imgPath: "/assets/emoji/black_28.png",
    unicode: "0x1F403",
    shortCode: "(black_welldone)",
    regx: /\(black_welldone\)/g,
    convertedCode: "🐃",
  },
  {
    imgPath: "/assets/emoji/black_29.png",
    unicode: "0x1F404",
    shortCode: "(black_confused)",
    regx: /\(black_confused\)/g,
    convertedCode: "🐄",
  },
  {
    imgPath: "/assets/emoji/batman_0.png",
    unicode: "0x1F405",
    shortCode: ":b_normal",
    regx: /:b_normal/g,
    convertedCode: "🐅",
  },
  {
    imgPath: "/assets/emoji/batman_1.png",
    unicode: "0x1F406",
    shortCode: ":b_evil",
    regx: /:b_evil/g,
    convertedCode: "🐆",
  },
  {
    imgPath: "/assets/emoji/batman_2.png",
    unicode: "0x1F407",
    shortCode: ":b_cool",
    regx: /:b_cool/g,
    convertedCode: "🐇",
  },
  {
    imgPath: "/assets/emoji/batman_3.png",
    unicode: "0x1F408",
    shortCode: ":b_angry",
    regx: /:b_angry/g,
    convertedCode: "🐈",
  },
  {
    imgPath: "/assets/emoji/batman_4.png",
    unicode: "0x1F409",
    shortCode: ":b_furious",
    regx: /:b_furious/g,
    convertedCode: "🐉",
  },
  {
    imgPath: "/assets/emoji/batman_5.png",
    unicode: "0x1F410",
    shortCode: ":b_sangry",
    regx: /:b_sangry/g,
    convertedCode: "🐐",
  },
  {
    imgPath: "/assets/emoji/batman_6.png",
    unicode: "0x1F411",
    shortCode: ":fb_pig",
    regx: /:fb_pig/g,
    convertedCode: "🐑",
  },
  {
    imgPath: "/assets/emoji/batman_7.png",
    unicode: "0x1F412",
    shortCode: ":tv",
    regx: /:tv/g,
    convertedCode: "🐒",
  },
  {
    imgPath: "/assets/emoji/batman_8.png",
    unicode: "0x1F413",
    shortCode: "(^^^)",
    regx: /\(\^\^\^\)/g,
    convertedCode: "🐓",
  },
  {
    imgPath: "/assets/emoji/batman_9.png",
    unicode: "0x1F414",
    shortCode: ":3",
    regx: /:3/g,
    convertedCode: "🐔",
  },
  {
    imgPath: "/assets/emoji/batman_10.png",
    unicode: "0x1F415",
    shortCode: ":v",
    regx: /:v/g,
    convertedCode: "🐕",
  },
  {
    imgPath: "/assets/emoji/batman_11.png",
    unicode: "0x1F416",
    shortCode: ":fb_eh",
    regx: /:fb_eh/g,
    convertedCode: "🐖",
  },
  {
    imgPath: "/assets/emoji/batman_12.png",
    unicode: "0x1F417",
    shortCode: ":fb_poo",
    regx: /:fb_poo/g,
    convertedCode: "🐗",
  },
  {
    imgPath: "/assets/emoji/batman_13.png",
    unicode: "0x1F418",
    shortCode: ":fb_tup",
    regx: /:fb_tup/g,
    convertedCode: "🐘",
  },
  {
    imgPath: "/assets/emoji/batman_14.png",
    unicode: "0x1F419",
    shortCode: ":^_^",
    regx: /:\^_\^/g,
    convertedCode: "🐙",
  },
  {
    imgPath: "/assets/emoji/batman_15.png",
    unicode: "0x1F420",
    shortCode: ":fb_sf",
    regx: /:fb_sf/g,
    convertedCode: "🐠",
  },
  {
    imgPath: "/assets/emoji/batman_16.png",
    unicode: "0x1F421",
    shortCode: ":fb_hs",
    regx: /:fb_hs/g,
    convertedCode: "🐡",
  },
  {
    imgPath: "/assets/emoji/batman_17.png",
    unicode: "0x1F422",
    shortCode: ":fb_salute",
    regx: /:fb_salute/g,
    convertedCode: "🐢",
  },
  {
    imgPath: "/assets/emoji/thumb_1.png",
    unicode: "0x1F424",
    shortCode: ":f_h52",
    regx: /:f_h52/g,
    convertedCode: "🐤",
  },
  {
    imgPath: "/assets/emoji/thumb_0.png",
    unicode: "0x1F423",
    shortCode: ":f_h5",
    regx: /:f_h5/g,
    convertedCode: "🐣",
  },

  {
    imgPath: "/assets/emoji/thumb_2.png",
    unicode: "0x1F425",
    shortCode: "f_fire",
    regx: /f_fire/g,
    convertedCode: "🐥",
  },
  {
    imgPath: "/assets/emoji/thumb_3.png",
    unicode: "0x1F426",
    shortCode: ":f_tup",
    regx: /:f_tup/g,
    convertedCode: "🐦",
  },
  {
    imgPath: "/assets/emoji/thumb_4.png",
    unicode: "0x1F427",
    shortCode: ":f_volcan",
    regx: /:f_volcan /g,
    convertedCode: "🐧",
  },
  {
    imgPath: "/assets/emoji/thumb_5.png",
    unicode: "0x1F428",
    shortCode: ":f_tdown",
    regx: /:f_tdown /g,
    convertedCode: "🐨",
  },
  {
    imgPath: "/assets/emoji/thumb_6.png",
    unicode: "0x1F429",
    shortCode: ":f_out",
    regx: /:f_out /g,
    convertedCode: "🐩",
  },
  {
    imgPath: "/assets/emoji/thumb_7.png",
    unicode: "0x1F430",
    shortCode: ":f_pardon",
    regx: /:f_pardon /g,
    convertedCode: "🐰",
  },
  {
    imgPath: "/assets/emoji/thumb_8.png",
    unicode: "0x1F431",
    shortCode: ":f_clap",
    regx: /:f_clap /g,
    convertedCode: "🐱",
  },
  {
    imgPath: "/assets/emoji/thumb_9.png",
    unicode: "0x1F432",
    shortCode: ":f_v",
    regx: /:f_v /g,
    convertedCode: "🐲",
  },
  {
    imgPath: "/assets/emoji/thumb_10.png",
    unicode: "0x1F433",
    shortCode: ":f_two",
    regx: /:f_two /g,
    convertedCode: "🐳",
  },
  {
    imgPath: "/assets/emoji/thumb_11.png",
    unicode: "0x1F434",
    shortCode: ":f_ag",
    regx: /:f_ag /g,
    convertedCode: "🐴",
  },
  {
    imgPath: "/assets/emoji/thumb_12.png",
    unicode: "0x1F435",
    shortCode: ":f_yo",
    regx: /:f_yo /g,
    convertedCode: "🐵",
  },
  {
    imgPath: "/assets/emoji/thumb_13.png",
    unicode: "0x1F436",
    shortCode: ":f_yo2",
    regx: /:f_yo2 /g,
    convertedCode: "🐶",
  },
  {
    imgPath: "/assets/emoji/thumb_14.png",
    unicode: "0x1F437",
    shortCode: ":f_one",
    regx: /:f_one /g,
    convertedCode: "🐷",
  },
  {
    imgPath: "/assets/emoji/thumb_15.png",
    unicode: "0x1F438",
    shortCode: ":f_fu",
    regx: /:f_fu /g,
    convertedCode: "🐸",
  },
  {
    imgPath: "/assets/emoji/thumb_16.png",
    unicode: "0x1F439",
    shortCode: ":f_perfect",
    regx: /:f_perfect /g,
    convertedCode: "🐹",
  },
  {
    imgPath: "/assets/emoji/thumb_17.png",
    unicode: "0x1F440",
    shortCode: ":f_cn",
    regx: /:f_cn /g,
    convertedCode: "👀",
  },
  {
    imgPath: "/assets/emoji/thumb_18.png",
    unicode: "0x1F441",
    shortCode: ":f_strong",
    regx: /:f_strong /g,
    convertedCode: "👁",
  },
  {
    imgPath: "/assets/emoji/thumb_19.png",
    unicode: "0x1F442",
    shortCode: ":f_punch",
    regx: /:f_punch /g,
    convertedCode: "👂",
  },
  {
    imgPath: "/assets/emoji/thumb_20.png",
    unicode: "0x1F443",
    shortCode: ":f_punch_two",
    regx: /:f_punch_two /g,
    convertedCode: "👃",
  },
];
