import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/services/loader.service";
import { UserService } from "src/services/user.service";

@Component({
  selector: "app-gifts",
  templateUrl: "./gifts.component.html",
  styleUrls: ["./gifts.component.scss"],
})
export class GiftsComponent implements OnInit {
  receivedGiftsPage: number = 0;
  sentGiftsPage: number = 0;

  segmentValue: string = "received";

  receivedGifts = [];
  sentGifts = [];

  loadReceivedGifts = false;
  loadSentGifts = false;

  constructor(
    private userService: UserService,
    public spinnerDialog: LoaderService
  ) {}

  ngOnInit() {
    this.onGetGifts(this.receivedGiftsPage, "received");
  }

  extractImgUrl(type, data): void {
    for (const item of data) {
      const text = type === "received" ? item.text : item.debit_text;
      const imgTxt = text.substring(
        text.lastIndexOf("<img"),
        text.lastIndexOf(">") + 1
      );
      item.text = text.replace(imgTxt, "").trim();
      const imgUrl = imgTxt.substring(
        text.lastIndexOf("https://"),
        text.indexOf("' ")
      );
      item.imgUrl = imgUrl;
      item.altImgUrl = `/assets/images/${item.notification_type}.svg`;
    }

    return data;
  }

  onGetGifts(page, type): void {
    this.spinnerDialog.show("", `"Loading ${type} gifts..."`);
    this.userService.getGifts(page, type).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        let { gifts } = resp;
        gifts = this.extractImgUrl(type, gifts);
        if (type === "received") {
          if (gifts.length > 0) {
            this.receivedGifts.push(...gifts);
            this.loadReceivedGifts = true;
          } else {
            this.loadReceivedGifts = false;
          }
        } else {
          if (gifts.length > 0) {
            this.sentGifts.push(...gifts);
            this.loadSentGifts = true;
          } else {
            this.loadSentGifts = false;
          }
        }
      },
      (err) => {
        this.spinnerDialog.hide();
      }
    );
  }

  segmentChanged(e): void {
    const { value } = e.target;
    this.segmentValue = value;
    if (value === "sent" && this.sentGifts.length === 0) {
      this.onGetGifts(this.sentGiftsPage, value);
    }
  }

  public loadMoreData(event) {
    if (this.segmentValue === "received") {
      if (this.loadReceivedGifts) {
        this.receivedGiftsPage += 1;
        this.onGetGifts(this.receivedGiftsPage, this.segmentValue);
        setTimeout(() => {
          event.target.complete();
        }, 1000);
        this.loadReceivedGifts = false;
      } else {
        event.target.complete();
      }
    } else if (this.segmentValue === "sent") {
      if (this.loadSentGifts) {
        this.sentGiftsPage += 1;
        this.onGetGifts(this.sentGiftsPage, this.segmentValue);
        setTimeout(() => {
          event.target.complete();
        }, 1000);
        this.loadSentGifts = false;
      } else {
        event.target.complete();
      }
    }
  }
}
