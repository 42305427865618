import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { HelperService } from "../../services/helper.service";
import { EventService } from "../../services/event.service";
import { AlertController } from "@ionic/angular";
import { LoaderService } from "../../services/loader.service";
import { Device } from "@ionic-native/device/ngx";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  form: any;
  userDetails: any;
  public toast;
  errorLoginMessage: any;
  disableLogin = false;
  errorMessage: any;
  buttonText: any = "Login";
  phone_model = "iPhone";
  userProfile: any = null;
  toggle_password = false;

  constructor(
    private router: Router,
    public authProvider: AuthService,
    private helperService: HelperService,
    public eventService: EventService,
    public alertCtrl: AlertController,
    public spinnerDialog: LoaderService,
    private device: Device
  ) {
    this.form = { username: "", password: "" };
  }

  ngOnInit() {
    const data = window.localStorage.getItem("credential");
    const credential = data ? JSON.parse(data) : {};
    const auth = localStorage.getItem("auth");
    if (auth) {
      this.router.navigateByUrl("/home");
    }
    this.form.password = credential.password;
    this.form.username = credential.username;
    if (credential.username) {
      this.form.save_password = true;
    }
  }

  // Login function
  signin() {
    if (this.validateRegister(this.form)) {
      this.disableLogin = true;
      this.buttonText = "Login...";
      this.userDetails = {
        login: this.form.username,
        password: this.form.password,
        online_status: !this.form.online_status,
        device_id: this.device.uuid,
      };
      this.spinnerDialog.show("iNwe", "Login...", false);
      this.authProvider.login(this.userDetails).subscribe(
        (resp) => {
          this.spinnerDialog.hide();
          this.disableLogin = false;
          if (resp.success) {
            if (resp.user.account_confirmed) {
              window.localStorage.setItem("auth", JSON.stringify(resp.auth));
              window.localStorage.removeItem("chat_rooms");
              // this.eventService.emitAuthEvent(resp.user);
              // this.showAnnouncement(resp.setting);
              if (this.form.save_password) {
                let credential = {
                  username: this.userDetails.login,
                  password: this.userDetails.password,
                };
                window.localStorage.setItem(
                  "credential",
                  JSON.stringify(credential)
                );
              } else {
                window.localStorage.removeItem("credential");
              }
              window.location.href = "/home";
            } else {
              this.helperService.showToast("Your account is not active!");
              this.router.navigateByUrl("/account-activation");
            }
          } else {
            this.errorLoginMessage = resp.errors;
          }
        },
        (err) => {
          this.spinnerDialog.hide();
          this.handleLoginError({ message: "Server encountered an error!" });
        }
      );
    } else {
      this.helperService.showToast(this.errorMessage);
    }
  }

  // Handling login error
  handleLoginError(err) {
    this.errorLoginMessage = err.message;
    this.disableLogin = false;
    this.buttonText = "Login";
  }

  // Validation of the user details input
  validateRegister(form) {
    if (this.form.username == undefined || this.form.username == "") {
      this.errorMessage = "Enter username or email";
      return false;
    }
    if (this.form.password == undefined || this.form.password == "") {
      this.errorMessage = "Enter password";
      return false;
    }
    return true;
  }

  // proceed to register page
  goRegister() {
    this.router.navigateByUrl("signup");
  }

  // proceed to forgot password page
  goForgotPassword() {
    this.router.navigateByUrl("forgot-password");
  }
}
