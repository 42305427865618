import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { HelperService } from "../../services/helper.service";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-account-activation",
  templateUrl: "./account-activation.component.html",
  styleUrls: ["./account-activation.component.scss"],
})
export class AccountActivationComponent implements OnInit {
  code = "";
  email: any;

  constructor(
    public authService: AuthService,
    public helperService: HelperService,
    private spinner: LoaderService,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    const auth = localStorage.getItem("auth");
    if (auth) {
      this.router.navigateByUrl("/home");
    }
    this.route.queryParams.subscribe((params) => {
      this.email = params.email || "";
    });
  }

  public accountActivation() {
    if (this.code != "") {
      this.spinner.show("", "Account activation...");
      this.authService.activation(this.code).subscribe(
        (resp) => {
          this.spinner.hide();
          if (resp.success) {
            this.helperService.showToast(
              "Account activation success! Please login",
              "success"
            );
            this.router.navigateByUrl("/login");
          } else {
            this.helperService.showToast(resp.message);
          }
        },
        (err) => {
          this.spinner.hide();
          this.helperService.showToast(
            "Unable to activated your account this moment!"
          );
        }
      );
    } else {
      this.helperService.showToast("Please enter your activation code!");
    }
  }
}
