import { Component, NgZone } from "@angular/core";
import { Platform, AlertController, ModalController } from "@ionic/angular";
import { BackgroundMode } from "@ionic-native/background-mode/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { Network } from "@ionic-native/network/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router, RouterEvent, NavigationEnd } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { HelperService } from "../services/helper.service";
import { EventService } from "../services/event.service";
import { Base } from "../services/base";
import { ChatService } from "../services/chat.service";
import { ChatRoomService } from "../services/chat-room.service";
import { UserService } from "../services/user.service";
import { RxStompService } from "@stomp/ng2-stompjs";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public displayName;
  public photoURL;
  public notifyme;
  public userId;
  public pages;
  public user_channel: any;
  public current_version = 402;
  onlineStatus: boolean;
  user: any;
  notifications = [];
  exit_alert: any;
  authData: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public ngZone: NgZone,
    public helperService: HelperService,
    public authProvider: AuthService,
    public eventService: EventService,
    //public actionCable: ActionCableService,
    public chatService: ChatService,
    public chatRoomService: ChatRoomService,
    public userService: UserService,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    private backgroundMode: BackgroundMode,
    private network: Network,
    private rxStompService: RxStompService
  ) {
    this.initializeApp();
    this.pages = [
      {
        title: "Home",
        url: "/home",
        icon: "home",
      },
      {
        title: "My Profile",
        url: "/profile",
        icon: "person-circle-outline",
      },
      {
        title: "My Account",
        url: "/account",
        icon: "wallet-outline",
      },
      {
        title: "Notifications",
        url: "/notifications",
        icon: "information-circle-outline",
      },
      {
        title: "Friends",
        url: "/friends",
        icon: "people-circle-outline",
      },
      {
        title: "Explore",
        url: "/explore",
        icon: "compass-outline",
      },
      {
        title: "Story",
        url: "/feeds",
        icon: "newspaper-outline",
      },
      {
        title: "Settings",
        url: "/settings",
        icon: "settings-outline",
      },
      {
        title: "Logout",
        url: "",
        icon: "power-outline",
        handler: () => {},
      },
    ];
  }

  // initialise splashscreen, statusbar, userdetails, and status
  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString("#163752");
      this.splashScreen.hide();
      this.backgroundMode.setDefaults({ silent: true });
      this.backgroundMode.enable();
      this.backgroundMode.on("activate").subscribe(() => {
        this.backgroundMode.disableWebViewOptimizations();
      });
      this.checkUserState();
      this.checkNetwork();
      this.checkAuth();
    });
  }

  checkNetwork() {
    this.network.onDisconnect().subscribe(() => {
      this.noNetworkAlert();
    });
  }

  checkForUpdate() {
    this.userService.appUpdate().subscribe((resp) => {
      this.showAnnouncement(resp.setting);
    });
  }

  async showAnnouncement(setting) {
    if (!setting) {
      return;
    }
    let options = [
      {
        text: "Close",
        handler: (data) => {
          alert.dismiss();
        },
      },
      {
        text: "More",
        handler: (data) => {
          this.router.navigateByUrl("/announcements");
        },
      },
    ];

    if (setting.force_update > this.current_version) {
      options = [
        {
          text: "Continue Update",
          handler: (data) => {
            window.open(setting.update_link, "_system");
            navigator["app"].exitApp();
          },
        },
      ];
    }
    const alert = await this.alertCtrl.create({
      header: "Announcement",
      message: setting.notification,
      mode: "ios",
      backdropDismiss: true,
      buttons: options,
    });
    await alert.present();
  }

  async noNetworkAlert() {
    const netAlert = await this.alertCtrl.create({
      header: "Network Disconnected!",
      message:
        "<img src='/assets/images/disconnect.jpg'> <br> Check internet connection",
      backdropDismiss: false,
      mode: "ios",
      buttons: [
        {
          text: "Exit",
          handler: () => {
            navigator["app"].exitApp();
          },
        },
        {
          text: "Refresh",
          handler: () => {
            window.location.href = "/";
          },
        },
      ],
    });
    await netAlert.present();
  }

  // get notification count
  loadNotification() {
    this.userService.notifications().subscribe(
      (resp) => {
        this.notifications = resp.notifications;
      },
      (err) => {
        this.helperService.showToast("Unable to load notifications");
      }
    );
  }

  // handle the click event of menu items
  handleClick(title: string): void {
    if (title === "Logout") {
      this.logOut();
    }
  }

  checkAuth() {
    let auth = Base.getAuthToken();
    if (auth && auth != "") {
      this.setUserData();
    } else {
      this.user = {};
    }
  }

  // navigate to pages through router
  ngOnInit() {
    this.authData = window.localStorage.getItem("auth");
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.pages.map((p) => {
          return (p["active"] = event.url === p.url);
        });
      }
    });
    this.eventService.notificationDispatcher.subscribe((data) => {
      if (data.type === "read") {
        this.notifications = [];
      }
    });
    this.eventService.dispatcher.subscribe((user) => {
      // Auth event dispatcher
      this.user = user;
      if (this.user) {
        this.chatService.checkCreateQueue(`public_${this.user.id}_channel`).subscribe(res => {
          this.subscribeToChannel();
        });
        this.loadRecentRooms();
        this.loadNotification();
        this.userWatcher();
      }
    });
  }

  public loadRecentRooms() {
    this.chatRoomService.categoryRooms("all").subscribe(
      (resp) => {
        if (resp.all) {
          let recent_rooms = resp.all || [];
          recent_rooms = recent_rooms.map((room) => {
            return { id: room.id, name: room.name };
          });
          window.localStorage.setItem(
            "chat_rooms",
            JSON.stringify(recent_rooms)
          );
          this.subscribeToRooms(recent_rooms);
        }
      },
      (err) => {}
    );
  }

  public subscribeToChannel() {
    if (this.user_channel) {
      this.user_channel.unsubscribe();
    }
    this.user_channel = this.rxStompService
      .watch(`/amq/queue/public_${this.user.id}_channel`)
      .subscribe(
        (resp) => {
          let resp_body = JSON.parse(resp.body);
          const response_message = resp_body.message;
          if (response_message.type === "chat") {
            this.handleChat(response_message.chat_room, response_message.user);
          } else if (response_message.type === "action") {
            if (response_message.action === "logout") {
              this.helperService.showToast(response_message.message);
              this.logOut();
            }
          } else if (response_message.type === "notification") {
            this.notifications.push(response_message.notification);
            this.eventService.emitNotificationEvent({
              type: "new",
              notification: response_message.notification,
            });
          }
        },
        (err) => {
          console.log("err: ", err);
        }
      );
  }

  public subscribeToRooms(chat_rooms) {
    for (let i = 0; i < chat_rooms.length; i++) {
      //this.chatRoomService.roomSubscribe(chat_rooms[i]);
    }
  }

  public userWatcher() {
    this.eventService.updateUser.subscribe((user) => {
      this.user = user;
    });
  }

  public handleChat(chat_room, user) {
    let chat_rooms = this.helperService.rooms();
    let room = chat_rooms.find((room) => room.id == chat_room.id);
    if (room === "" || room == null || typeof room === "undefined") {
      this.chatService.chatPopup(user.username, chat_room.id);
    }
  }

  // check user status, if user login, status online and navigate home
  // when user logout the status offline and navigate to welcome page
  checkUserState() {
    // this.afAuth.authState.subscribe(user => {
    //   if (user && user.uid) {
    //     console.log('user is logged in');
    //     this.authProvider.offlineStatus()
    //     this.authProvider.onlineStatus()
    //     this.router.navigateByUrl('home');
    this.authProvider.isLoggedIn = true;
    this.photoURL = "/assets/images/profile.png";
    //   } else {
    //     console.log('user not logged in');
    //     this.router.navigateByUrl('welcome');
    //   }
    // });
  }

  // get user details
  setUserData() {
    this.authProvider.getCurrentUser().subscribe(
      (resp) => {
        this.checkForUpdate();
        this.eventService.emitAuthEvent(resp.profile);

        this.onlineStatus = resp.profile.online_status;
      },
      (err) => {
        this.user = {};
        if (err.status == 401) {
          if (this.router.url !== "/login") {
            window.location.href = "/login";
          }
        }
      }
    );
  }

  // user logout and navigate to welcome page
  async logOut() {
    this.authProvider.logout().subscribe(
      (resp) => {
        window.localStorage.removeItem("auth");
        window.location.href = "/login";
      },
      (err) => {
        window.localStorage.removeItem("auth");
        window.location.href = "/login";
      }
    );
  }
}

