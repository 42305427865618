import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {}

  // Proceed to Login page.
  goLogin() {
    this.router.navigateByUrl('login');
  }

  // Proceed to Register page.
  goRegister() {
    this.router.navigateByUrl('signup');
  }
}
