import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { HelperService } from "../../services/helper.service";
import { ActivatedRoute } from "@angular/router";
import { LoaderService } from "../../services/loader.service";
import { EventService } from "../../services/event.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-transfer-coin",
  templateUrl: "./transfer-coin.component.html",
  styleUrls: ["./transfer-coin.component.scss"],
})
export class TransferCoinComponent implements OnInit {
  transfer = {
    username: "",
    amount: "",
    type: "transfer",
    pin: "",
    otp: "",
    tag: false,
  };
  balance = 0;
  submitted = false;
  avl_otp = "";
  user: any;

  constructor(
    public userService: UserService,
    public helperService: HelperService,
    public route: ActivatedRoute,
    private spinner: LoaderService,
    private eventService: EventService,
    private authProvider: AuthService
  ) {}

  ngOnInit() {
    this.loadBalance();
    this.loadUSer();
    this.route.queryParams.subscribe((params) => {
      if (params["username"]) {
        this.transfer.username = params["username"];
      }
    });
  }

  public loadBalance() {
    this.spinner.show("", "Loading current balance...");
    this.userService.balance().subscribe(
      (resp) => {
        this.spinner.hide();
        this.balance = resp.balance;
      },
      (err) => {
        this.spinner.hide();
        this.helperService.showAlert("Unable to load current balance");
      }
    );
  }

  loadUSer() {
    this.authProvider.getCurrentUser().subscribe((resp) => {
      this.user = resp.profile;
    });
  }

  public sendCredit() {
    this.spinner.show("", "Sending credit...");
    let data = {
      receiver: this.transfer.username,
      amount: this.transfer.amount,
      pin: this.transfer.pin,
      otp: this.transfer.otp,
      tag: this.transfer.tag,
    };
    this.userService.sendCredit(data).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          if (resp.send_otp) {
            this.helperService.showAlert(
              "Transfer credit successfully",
              "success"
            );
            this.balance = resp.balance;
            this.eventService.update_balance.next(this.balance);
            this.transfer = {
              username: "",
              amount: "",
              type: "transfer",
              pin: "",
              otp: "",
              tag: false,
            };
            this.avl_otp = "";
            this.submitted = false;
          } else {
            this.avl_otp = resp.otp;
          }
        } else {
          this.spinner.hide();
          this.helperService.showAlert(resp.errors);
        }
      },
      (err) => {
        this.helperService.showAlert("Unable to send credit this moment");
      }
    );
  }

  public transferCoin(valid) {
    this.submitted = true;
    if (valid) {
      if (+this.transfer.amount > this.balance) {
        this.helperService.showAlert("Insufficient balance");
      } else {
        this.sendCredit();
      }
    }
  }
}
