import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { HelperService } from "../../services/helper.service";
import { EventService } from "../../services/event.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  public userDetails: any;
  form: any;
  public errorMessage: any;
  public buttonText: any = "Register Account";
  public toast;
  errorRegisterMessage: any;
  errorSigninMessage: any;
  disableRegister = false;
  phone_model = "iPhone";
  userProfile: any = null;
  valid_username = true;
  secretKey = "$C&F)J@M1cQ8fTj39WnZru6x!A%1D*G0-Ka9P81d";
  countries = [];

  constructor(
    private router: Router,
    public authProvider: AuthService,
    private helperService: HelperService,
    private eventService: EventService
  ) {
    this.form = { country: "", gender: "male" };
    this.countries = this.helperService.getCountries();
  }

  ngOnInit() {
    const auth = localStorage.getItem("auth");
    if (auth) {
      this.router.navigateByUrl("/home");
    }
  }

  register() {
    if (this.validateRegister(this.form)) {
      // this.spinnerDialog.show('iNwe', this.translate.instant('ALERT.creatingprofile'), false);
      this.disableRegister = true;
      this.buttonText = "Registering...";
      this.userDetails = {
        username: this.form.username,
        email: this.form.email,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
        gender: this.form.gender,
        country: this.form.country.name,
        rcp_token: this.encrypt(),
      };
      this.authProvider.register(this.userDetails).subscribe(
        (resp) => {
          this.disableRegister = false;
          this.buttonText = "Register Account";
          // this.spinnerDialog.hide();
          if (resp.success) {
            if (resp.user.account_confirmed) {
              this.eventService.emitAuthEvent(resp.user);
              window.localStorage.setItem("auth", JSON.stringify(resp.auth));
              this.router.navigateByUrl("/");
            } else {
              this.helperService.showToast("Welcome To iNwe!", "success");
              this.router.navigate(["/account-activation"], {
                queryParams: { email: resp.user.email },
              });
            }
          } else {
            this.errorRegisterMessage = resp.errors;
          }
        },
        (err) => {
          this.handleRegisterError({ message: "Server encountered an error!" });
        }
      );
    } else {
      this.helperService.showToast(this.errorMessage);
    }
  }

  public checkUsername() {
    this.authProvider.checkUsername(this.form.username).subscribe(
      (resp) => {
        this.valid_username = !resp.exist;
      },
      (err) => {}
    );
  }

  // Handling registration error
  handleRegisterError(err) {
    this.errorRegisterMessage = err.message;
    this.disableRegister = false;
    this.buttonText = "Register Account";
  }

  // Validation of the user details input
  validateRegister(form) {
    if (this.form.username == undefined || this.form.username == "") {
      this.errorMessage = "Enter username";
      return false;
    } else if (this.form.username.match(/^([A-Za-z]+)[a-zA-Z0-9_.-]*$/) <= 0) {
      this.errorMessage =
        "Username must start with letter, be at least 6 characters long and contain only letter, digit, hyphen, underline and period.";
      return false;
    }
    if (this.form.email == undefined || this.form.email == "") {
      this.errorMessage = "Enter email";
      return false;
    }
    if (this.form.password == undefined || this.form.password == "") {
      this.errorMessage = "Enter password";
      return false;
    }
    if (this.form.password != this.form.password_confirmation) {
      this.errorMessage = "Password and confirm password doesn't match";
      return false;
    }
    if (this.form.country == undefined || this.form.country == "") {
      this.errorMessage = "Select your country";
      return false;
    }
    if (this.form.toc == undefined || this.form.toc == "") {
      this.errorMessage = "Agree our privacy and policy";
      return false;
    }
    return true;
  }

  encrypt() {
    let utc_now = window.btoa(new Date().getTime().toString());
    let new_token = utc_now + "=" + this.secretKey;
    return "$22=" + window.btoa(new_token);
  }

  // Proceed to Login page.
  goLogin() {
    this.router.navigateByUrl("/login");
  }
}
