import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { HelperService } from "../../services/helper.service";
import { EventService } from "../../services/event.service";
import { LoaderService } from "../../services/loader.service";

declare var window: any;

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.page.html",
  styleUrls: ["./edit-profile.page.scss"],
})
export class EditProfilePage implements OnInit {
  public edit_profile: any;
  public user: any;
  profile_picture: any;
  phone_model = "iPhone";
  coverImage = "assets/images/cover.png";
  relations = [
    "Single",
    "Married",
    "In a relationship",
    "Complicated",
    "Widowed",
    "Divorced",
    "Not available",
  ];

  constructor(
    public formBuilder: FormBuilder,
    public helperService: HelperService,
    public authProvider: AuthService,
    private spinnerDialog: LoaderService,
    private router: Router,
    private eventService: EventService
  ) {}

  // get user profile details
  ngOnInit() {
    this.getMyProfile();
  }

  // update profile function
  updateProfile(is_valid) {
    if (is_valid) {
      this.spinnerDialog.show("", "Updating profile...", false);
      this.authProvider.updateProfile(this.edit_profile).subscribe(
        (resp) => {
          this.spinnerDialog.hide();
          if (resp.success) {
            this.helperService.showToast(
              "Profile updated successfully!",
              "success"
            );
            this.eventService.emitUpdateUser(resp.user);
            this.router.navigateByUrl("profile");
          } else {
            this.helperService.showToast(resp.errors);
          }
        },
        (err) => {
          this.helperService.showToast("Unable to update profile!");
        }
      );
    } else {
      this.helperService.showToast("All fields are required!");
    }
  }

  getProfilePic() {
    if (this.user.profile_picture && this.user.profile_picture.url != null) {
      this.profile_picture = { url: this.user.profile_picture.small.url };
    } else {
      this.profile_picture = { url: "/assets/images/profile.png" };
    }
  }

  // Get current user details
  getMyProfile() {
    this.spinnerDialog.show("", "Loading profile...");
    this.authProvider.getCurrentUser().subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.user = resp.profile;
          this.edit_profile = {
            name: this.user.name,
            about: this.user.about,
            dob: this.user.dob,
            phone: this.user.phone,
            relationship_status: this.user.relationship_status,
          };
          this.getProfilePic();
        } else {
          this.helperService.showToast("Unable to get user information");
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showToast("Unable to get user information");
      }
    );
  }
}
