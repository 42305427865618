import { Component, OnInit, ViewChild } from "@angular/core";
import { ChatRoomService } from "../../../services/chat-room.service";
import { HelperService } from "../../../services/helper.service";
import { IonInput, ModalController } from "@ionic/angular";

@Component({
  selector: "app-create-room",
  templateUrl: "./create-room.component.html",
  styleUrls: ["./create-room.component.scss"],
})
export class CreateRoomComponent implements OnInit {
  @ViewChild("input") input: IonInput;
  chat_room = {
    name: "",
    description: "",
  };

  constructor(
    public roomService: ChatRoomService,
    public helperService: HelperService,
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.input.setFocus();
    }, 500);
  }

  public createRoom() {
    this.roomService.create(this.chat_room).subscribe(
      (resp) => {
        if (resp.success) {
          this.modalCtrl.dismiss({ chat_room: resp.chat_room });
        } else {
          this.helperService.showAlert(resp.errors);
        }
      },
      (err) => {
        this.helperService.showAlert(
          "Unable to create chat room, Please try after some times!"
        );
      }
    );
  }

  public closeModal() {
    this.modalCtrl.dismiss({});
  }
}
