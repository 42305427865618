import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { UserService } from "../../../services/user.service";
import { HelperService } from "../../../services/helper.service";
import { LoaderService } from "../../../services/loader.service";

@Component({
  selector: "app-request",
  templateUrl: "./request.component.html",
  styleUrls: ["./request.component.scss"],
})
export class FriendRequestComponent implements OnInit {
  friend_requests = [];

  constructor(
    public usersProvider: UserService,
    public helperService: HelperService,
    public spinnerDialog: LoaderService,
    public alertCtrl: AlertController,
    public router: Router
  ) {}

  ngOnInit() {
    this.loadFriendRequest();
  }

  public loadFriendRequest() {
    this.usersProvider.friendRequest().subscribe(
      (resp) => {
        this.friend_requests = resp.requested_friends;
      },
      (err) => {}
    );
  }

  public gotoProfile(user_id) {
    this.router.navigateByUrl("/profile/" + user_id);
  }

  public profilePicture(profile_photo) {
    if (profile_photo && profile_photo.url) {
      return profile_photo.small.url;
    } else {
      return "assets/images/profile.png";
    }
  }

  public viewImage(src: string, title: string, description: string) {
    this.helperService.imageViewer(src, title, "User profile photo");
  }

  public removeFromList(user) {
    let index = this.friend_requests.indexOf(user);
    if (index !== -1) {
      this.friend_requests.splice(index, 1);
    }
  }
}
