import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {HelperService} from '../../services/helper.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-resend-activation',
  templateUrl: './resend-activation.component.html',
  styleUrls: ['./resend-activation.component.scss'],
})
export class ResendActivationComponent implements OnInit {
  email = '';

  constructor(public authService: AuthService, public helperService: HelperService, private spinner: LoaderService,
              public router: Router) { }

  ngOnInit() {}

  public resendActivation() {
    if(this.email != '') {
      this.spinner.show('', 'Resend activation...');
      this.authService.resend_activation(this.email).subscribe(resp => {
        this.spinner.hide();
        if(resp.success) {
          this.helperService.showToast('Sent account activation code!', 'success');
          this.router.navigateByUrl('/account-activation');
        }
        else {
          this.helperService.showToast(resp.message);
        }
      }, err=> {
        this.spinner.hide();
        this.helperService.showToast('Unable to send activation code this moment!');
      })
    }
    else {
      this.helperService.showToast('Please enter your email!');
    }
  }

}
