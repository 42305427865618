import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { LoaderService } from "src/services/loader.service";
import { HelperService } from "src/services/helper.service";
import { UserService } from "src/services/user.service";

@Component({
  selector: "app-people-list",
  templateUrl: "./people-list.component.html",
  styleUrls: ["./people-list.component.scss"],
})
export class PeopleListComponent implements OnInit {
  @Input() users: Array<any>;
  @Input() title: string;
  @Input() type: string;
  pager = 0;
  load_more_data = true;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private spinner: LoaderService,
    private helperService: HelperService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  public closeModal() {
    this.modalCtrl.dismiss();
    this.pager = 0;
  }

  public loadProfile(id) {
    this.closeModal();
    this.router.navigateByUrl("/profile/" + id);
  }

  public loadMoreData(event) {
    if (this.load_more_data) {
      this.pager += 1;
      this.loadPeople();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
      this.load_more_data = false;
    } else {
      event.target.complete();
    }
  }

  public loadPeople() {
    this.spinner.show("", `Loading ${this.title}...`);
    this.userService.people(this.type, this.pager).subscribe(
      (resp) => {
        this.spinner.hide();
        const { people } = resp;
        if (people.length > 0) {
          this.users.push(...people);
          this.load_more_data = true;
        } else {
          this.load_more_data = false;
        }
      },
      (err) => {
        this.spinner.hide();
        this.helperService.showToast("Unable to load people this moment!");
      }
    );
  }
}
