import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoaderService } from "src/services/loader.service";
import { UserService } from "src/services/user.service";
import {HelperService} from "../../services/helper.service";

@Component({
    selector: "app-legend-campaign",
    templateUrl: "./legend-campaign.component.html",
    styleUrls: ["./legend-campaign.component.scss"],
})
export class LegendCampaignComponent implements OnInit {
    legendCampaign = [];
    constructor(
        private spinner: LoaderService,
        private helperService: HelperService,
        private userService: UserService,
        public router: Router
    ) {}

    ngOnInit() {
        this.getUsers();
    }

    getUsers(): void {
        this.userService.people('legend_campaign', 0).subscribe(
            resp => {
                this.spinner.hide();
                this.legendCampaign = resp.people;
                console.log(this.legendCampaign);
                
            }, err => {
                this.spinner.hide();
                this.helperService.showToast('Unable to load campaign data this moment!');
            }
        );
    }

    public profilePicture(profile_photo) {
        if (profile_photo && profile_photo.url) {
            return profile_photo.small.url;
        } else {
            return "assets/images/profile.png";
        }
    }

    public gotoProfile(user_id) {
        this.router.navigateByUrl("/profile/" + user_id);
    }

}
