import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ChatRoomService} from '../../services/chat-room.service';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.scss'],
})
export class CommandComponent implements OnInit {
  commands = [];
  constructor(public ctrlModal: ModalController, public chatRoomService: ChatRoomService, private spinner: LoaderService) { }

  ngOnInit() {
    this.loadCommands();
  }

  public loadCommands() {
    this.spinner.show('', 'Loading commands...');
    this.chatRoomService.commands().subscribe(resp => {
      this.spinner.hide();
      for(let i = 0; i < resp.commands.length; i += 2) {
        this.commands.push([resp.commands[i].short_code, (resp.commands[(i + 1)] ? resp.commands[(i + 1)].short_code : '')]);
      }
    }, err => {
      this.spinner.hide();
    });
  }
}
