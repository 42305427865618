import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {HelperService} from '../../services/helper.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  privacy_policy = '';

  constructor(public userService: UserService, public helperService: HelperService) { }

  ngOnInit() {
    this.loadPolicy();
  }

  public loadPolicy() {
    this.userService.appSettings().subscribe(resp => {
      this.privacy_policy = resp.privacy_policy;
    }, err => {
      this.helperService.showToast('Unable to load policy text!');
    });
  }

}
