import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AuthService} from '../../../services/auth.service';
import {HelperService} from '../../../services/helper.service';

@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  styleUrls: ['./security-question.component.scss'],
})
export class SecurityQuestionComponent implements OnInit {
  user: any;
  security_form = {
    old_answer: '',
    security_question: '',
    security_answer: ''
  };

  constructor(public modalCtrl: ModalController, public authService: AuthService, public helperService: HelperService) { }

  ngOnInit() {
    this.loadUser();
  }

  loadUser() {
    this.authService.getCurrentUser().subscribe(resp => {
      this.user = resp.profile;
    }, err => {
      this.helperService.showToast('Unable to load user this moment!');
    });
  }

  public changeSecurity() {
    this.authService.changeSecurityQuestion(this.security_form).subscribe(resp => {
      if(resp.success) {
        this.helperService.showToast('Security question changes successfully!', 'success');
        this.closeModal();
      }
      else {
        this.helperService.showAlert(resp.message);
      }
    }, err => {
      this.helperService.showToast('Unable to change security question this moment!');
    });
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
