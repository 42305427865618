import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Base } from "./base";

@Injectable({
  providedIn: "root",
})
export class UserService {
  api_url = Base.api_url;
  isLoggedIn: boolean;

  constructor(public http: HttpClient) {}

  public allUsers(term = ""): Observable<any> {
    return this.http.get(
      this.api_url + "/users/search?q=" + term,
      this.requestHeader()
    );
  }

  public updateStatusMessage(message) {
    return this.http.post(
      this.api_url + "/users/update_status_message",
      { message: message },
      this.requestHeader()
    );
  }

  public userProfile(user_id): Observable<any> {
    return this.http.get(
      this.api_url + "/users/user_profile?user_id=" + user_id,
      this.requestHeader()
    );
  }

  public sendRequest(user_id): Observable<any> {
    return this.http.post(
      this.api_url + "/friendship/add_friend",
      { user_id: user_id },
      this.requestHeader()
    );
  }

  public cancelFriendRequest(user_id): Observable<any> {
    return this.http.post(
      this.api_url + "/friendship/remove_friend",
      { user_id: user_id },
      this.requestHeader()
    );
  }

  public acceptRequest(user_id): Observable<any> {
    return this.http.post(
      this.api_url + "/friendship/accept_friend",
      { user_id: user_id },
      this.requestHeader()
    );
  }

  public rejectRequest(user_id): Observable<any> {
    return this.http.post(
      this.api_url + "/friendship/decline_friend",
      { user_id: user_id },
      this.requestHeader()
    );
  }

  public blockFriendship(user_id): Observable<any> {
    return this.http.post(
      this.api_url + "/friendship/block_user",
      { user_id: user_id },
      this.requestHeader()
    );
  }

  public removeFriendship(user_id): Observable<any> {
    return this.http.post(
      this.api_url + "/friendship/remove_friend",
      { user_id: user_id },
      this.requestHeader()
    );
  }

  public friendList(): Observable<any> {
    return this.http.get(
      this.api_url + "/friendship/friend_list",
      this.requestHeader()
    );
  }

  public friendRequest(): Observable<any> {
    return this.http.get(
      this.api_url + "/friendship/requested_friends",
      this.requestHeader()
    );
  }

  public announcements(page: number): Observable<any> {
    return this.http.get(
      `${this.api_url}/announcements?page=${page}`,
      this.requestHeader()
    );
  }

  public announcement(id): Observable<any> {
    return this.http.get(
      `${this.api_url}/announcements/${id}`,
      this.requestHeader()
    );
  }

  public feedback(data): Observable<any> {
    return this.http.post(
      this.api_url + "/users/feedback",
      {
        feedback: data.content,
        base64_file: data.base64_file,
      },
      this.requestHeader()
    );
  }

  public appSettings(): Observable<any> {
    return this.http.get(this.api_url + "/users/app_settings");
  }

  public balance(): Observable<any> {
    return this.http.get(this.api_url + "/users/balance", this.requestHeader());
  }

  public statement(page): Observable<any> {
    return this.http.get(
      this.api_url + "/transactions?page=" + page,
      this.requestHeader()
    );
  }

  public sendCredit(data): Observable<any> {
    return this.http.post(
      this.api_url + "/transfers",
      data,
      this.requestHeader()
    );
  }

  public notifications(): Observable<any> {
    return this.http.get(
      this.api_url + "/users/notifications",
      this.requestHeader()
    );
  }

  public mentions(): Observable<any> {
    return this.http.get(
      this.api_url + "/users/mentions",
      this.requestHeader()
    );
  }

  public readNotification(): Observable<any> {
    return this.http.get(
      this.api_url + "/users/read_all_notifications",
      this.requestHeader()
    );
  }

  public deleteNotification(id): Observable<any> {
    return this.http.delete(
      this.api_url + "/users/delete_notification/?id=" + id,
      this.requestHeader()
    );
  }

  public recentActivity(id): Observable<any> {
    return this.http.get(
      this.api_url + "/users/recent_activity?user_id=" + id,
      this.requestHeader()
    );
  }

  public leaderBoard(): Observable<any> {
    return this.http.get(
      this.api_url + "/users/leader_board",
      this.requestHeader()
    );
  }

  public levelProgress(): Observable<any> {
    return this.http.get(this.api_url + "/users/level", this.requestHeader());
  }

  public people(type: string, page: number): Observable<any> {
    return this.http.get(
      `${this.api_url}/users/people?type=${type}&page=${page}`,
      this.requestHeader()
    );
  }

  public appUpdate(): Observable<any> {
    return this.http.get(
      this.api_url + "/users/app_announcement",
      this.requestHeader()
    );
  }

  public getAlerts(page: number): Observable<any> {
    return this.http.get(
      `${this.api_url}/users/alerts?page=${page}`,
      this.requestHeader()
    );
  }

  public getFootprints(): Observable<any> {
    return this.http.get(
      `${this.api_url}/users/footprints`,
      this.requestHeader()
    );
  }

  public getGifts(page: number, type: string): Observable<any> {
    return this.http.get(
      `${this.api_url}/transactions/gift_details?page=${page}&type=${type}`,
      this.requestHeader()
    );
  }

  public getOnlineStatus(): Observable<any> {
    return this.http.get(
      `${this.api_url}/users/appearance`,
      this.requestHeader()
    );
  }

  public updateOnlineStatus(appearance: string): Observable<any> {
    return this.http.post(
      `${this.api_url}/users/appearance`,
      { appearance },
      this.requestHeader()
    );
  }

  private requestHeader() {
    return { headers: { Authorization: `Bearer ${Base.getAuthToken()}` } };
  }
}
