import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {ChatRoomService} from '../../../services/chat-room.service';
import {App} from '../../models/app';
import {Router} from '@angular/router';
import {LoaderService} from '../../../services/loader.service';
import {HelperService} from '../../../services/helper.service';

@Component({
    selector: 'app-participants',
    templateUrl: './participants.component.html',
    styleUrls: ['./participants.component.scss'],
})
export class ParticipantsComponent implements OnInit {
    @Input() name: string;
    @Input() room_id: string;
    @Input() kick: boolean;
    users = [];

    constructor(public modalCtrl: ModalController, public spinnerDialog: LoaderService, public alertCtrl: AlertController,
                public chatRoom: ChatRoomService, public router: Router, public helperService: HelperService) {
    }

    ngOnInit() {
        this.loadParticipants();
    }

    public loadParticipants() {
        this.spinnerDialog.show('', 'Loading participants...');
        this.chatRoom.participants(this.room_id).subscribe(resp => {
            this.spinnerDialog.hide();
            this.users = (resp.users || []).sort((a, b) => {
                return a.user.username > b.user.username ? 1 : -1;
            });
        }, err => {
            this.spinnerDialog.hide();
        });
    }

    async kickUser(user) {
        this.chatRoom.kickUser(user.id, this.room_id).subscribe(resp => {
            if (!resp.success) {
                this.helperService.showAlert(resp.message);
            }
        }, err => {
            this.helperService.showAlert('Unable to kick this moment');
        });
    }

    public gotoProfile(user_id) {
        this.closeModal();
        this.router.navigateByUrl('/profile/' + user_id);
    }

    public getRole(id) {
        return App.role(id);
    }

    public closeModal() {
        this.modalCtrl.dismiss();
    }
}
