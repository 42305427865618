import {Component, OnInit} from '@angular/core';
import {BlockService} from '../../../services/block.service';
import {AlertController, ModalController} from '@ionic/angular';
import {HelperService} from '../../../services/helper.service';

@Component({
    selector: 'app-block-list',
    templateUrl: './block-list.component.html',
    styleUrls: ['./block-list.component.scss'],
})
export class BlockListComponent implements OnInit {
    block_users = [];

    constructor(private blockService: BlockService, public modalCtrl: ModalController,
                public alertCtrl: AlertController, public helperService: HelperService) {
    }

    ngOnInit() {
        this.loadBlockList();
    }

    public loadBlockList() {
        this.blockService.list().subscribe(resp => {
            this.block_users = resp.users;
        }, err => {
            this.helperService.showToast('Unable to load block list this moment!');
        });
    }

    async unblock(user, index) {
        const alert = await this.alertCtrl
            .create({
                header: 'Unblock User',
                message: 'Are you sure want to unblock <b>' + user.username + '?</b>',
                mode: 'ios',
                buttons: [
                    {
                        text: 'Cancel',
                        handler: data => {
                        }
                    },
                    {
                        text: 'Unblock',
                        handler: data => {
                            this.confirmUnblock(user, index);
                        }
                    }
                ]
            });
        await alert.present();
    }

    public confirmUnblock(user, index) {
        this.blockService.unBlock(user.id).subscribe(resp => {
            this.block_users.splice(index, 1);
            this.helperService.showToast('User successfully unblock', 'success');
        }, err => {
            this.helperService.showToast('Unable to unblock user this moment!');
        });
    }

    public closeModal() {
        this.modalCtrl.dismiss();
    }

}
