import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class AnnouncementDetailsComponent implements OnInit {
  announcement: any;

  constructor(public userService: UserService, private route: ActivatedRoute, public spinnerDialog: LoaderService) {
    let announcement_id = this.route.snapshot.paramMap.get('id');
    this.loadAnnouncement(announcement_id);
  }

  ngOnInit() {}

  public loadAnnouncement(id) {
    this.spinnerDialog.show('', 'Loading...');
    this.userService.announcement(id).subscribe(resp => {
      this.spinnerDialog.hide();
      this.announcement = resp.announcement;
    }, err => {
      this.spinnerDialog.hide();
    });
  }
}
