import { Pipe, PipeTransform } from "@angular/core";
import { emojis } from "../assets/js/emojis.js";
@Pipe({
  name: "emojify",
  pure: false,
})
export class EmojiPipe implements PipeTransform {
  constructor() {}
  transform(value: string) {
    let message = value;
    for (const emoji of emojis) {
      message = message
        .split(emoji.convertedCode)
        .join("<img class='emoji-message-icon' src='" + emoji.imgPath + "'/> ");
    }
    return message;
  }
}
