import { Component, OnInit } from "@angular/core";
import { EmailService } from "../../services/email.service";
import { HelperService } from "../../services/helper.service";
import { LoaderService } from "../../services/loader.service";
import { ModalController } from "@ionic/angular";
import { EmailDetailsComponent } from "./details/details.component";

@Component({
  selector: "app-emails",
  templateUrl: "./emails.component.html",
  styleUrls: ["./emails.component.scss"],
})
export class EmailsComponent implements OnInit {
  emails = [];
  receivedEmailsPage: number = 0;
  sentEmailsPage: number = 0;

  segmentValue: string = "received";

  receivedEmails = [];
  sentEmails = [];

  loadReceivedEmails = false;
  loadSentEmails = false;

  constructor(
    public emailService: EmailService,
    public helperService: HelperService,
    public spinnerDialog: LoaderService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.loadEmails(this.receivedEmailsPage, "received");
  }

  segmentChanged(e): void {
    const { value } = e.target;
    this.segmentValue = value;
    if (value === "sent" && this.sentEmails.length === 0) {
      this.loadEmails(this.sentEmailsPage, value);
    }
  }

  public loadEmails(page: number, type: string) {
    const emailType = type === "received" ? "inbox" : "outbox";
    this.spinnerDialog.show("", `Loading ${type} emails...`);
    this.emailService.emails(emailType, page).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        this.emails = resp.emails;
        const { emails } = resp;
        if (type === "received") {
          if (emails.length > 0) {
            this.receivedEmails.push(...emails);
            this.loadReceivedEmails = true;
          } else {
            this.loadReceivedEmails = false;
          }
        } else {
          if (emails.length > 0) {
            this.sentEmails.push(...emails);
            this.loadSentEmails = true;
          } else {
            this.loadSentEmails = false;
          }
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showToast("Unable to load emails!");
      }
    );
  }

  async emailDetails(id) {
    const modal = await this.modalCtrl.create({
      component: EmailDetailsComponent,
      componentProps: {
        email_id: id,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data.email) {
      this.emails = this.emails.filter((email) => email.id !== data.email);
    }
  }

  public loadMoreData(event) {
    if (this.segmentValue === "received") {
      if (this.loadReceivedEmails) {
        this.receivedEmailsPage += 1;
        this.loadEmails(this.receivedEmailsPage, this.segmentValue);
        setTimeout(() => {
          event.target.complete();
        }, 1000);
        this.loadReceivedEmails = false;
      } else {
        event.target.complete();
      }
    } else if (this.segmentValue === "sent") {
      if (this.loadSentEmails) {
        this.sentEmailsPage += 1;
        this.loadEmails(this.sentEmailsPage, this.segmentValue);
        setTimeout(() => {
          event.target.complete();
        }, 1000);
        this.loadSentEmails = false;
      } else {
        event.target.complete();
      }
    }
  }
}
