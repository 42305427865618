export const AllMessageReaction = [
    {
        type: 'like',
        src: '../../../assets/message_reaction/SVG/like.svg',
        alt: 'like',

    },
    {
        type: 'down',
        src: '../../../assets/message_reaction/SVG/down.svg',
        alt: 'down',
        
    },
    {
        type: 'love',
        src: '../../../assets/message_reaction/SVG/heart.svg',
        alt: 'love',
        
    },
    {
        type: 'laugh',
        src: '../../../assets/message_reaction/SVG/laugh.svg',
        alt: 'laugh',
        
    },
    {
        type: 'surprise',
        src: '../../../assets/message_reaction/SVG/surprise.svg',
        alt: 'surprise',
        
    },
    {
        type: 'crying',
        src: '../../../assets/message_reaction/SVG/crying.svg',
        alt: 'crying',
        
    },
    {
        type: 'angry',
        src: '../../../assets/message_reaction/SVG/angry.svg',
        alt: 'angry',
        
    }
]