import {Component, Input, OnInit} from '@angular/core';
import {ChatRoomService} from '../../../services/chat-room.service';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';
import {HelperService} from '../../../services/helper.service';

@Component({
    selector: 'app-room-info',
    templateUrl: './room-info.component.html',
    styleUrls: ['./room-info.component.scss'],
})
export class RoomInfoComponent implements OnInit {
    @Input() room_id: string;
    room_info: any;
    room = {description: '', public_kick: true, level_lock: 0, name: '', capacity: 25};

    constructor(public roomService: ChatRoomService, public modalCtrl: ModalController,
                private router: Router, public helperService: HelperService) {
    }

    ngOnInit() {
        this.loadRoom();
    }

    public loadRoom() {
        this.roomService.roomInfo(this.room_id).subscribe(resp => {
            this.room_info = resp.room_info;
            this.room = resp.room_info.rome;
        }, err => {
        });
    }

    public updateRoom() {
        let data = {
            description: this.room.description,
            level_lock: this.room.level_lock,
            public_kick: this.room.public_kick,
        };
        this.roomService.updateInfo(data, this.room_id).subscribe(resp => {
            if (resp.success) {
                this.helperService.showToast('Room information has been updated!', 'success');
            } else {
                this.helperService.showAlert(resp.errors);
            }
        }, err => {
            this.helperService.showToast('Unable to update room info this moment!');
        });
    }

    public gotoProfile(id) {
        this.close();
        this.router.navigateByUrl('/profile/' + id);
    }

    public close() {
        this.modalCtrl.dismiss();
    }
}
