import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { HelperService } from "../../services/helper.service";
import { AuthService } from "../../services/auth.service";
import { LoaderService } from "../../services/loader.service";
import { EventService } from "../../services/event.service";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  statements = [];
  balance: any;
  today_spent = 0;
  user_id = 0;
  pager = 0;
  load_more_data = false;

  constructor(
    public userService: UserService,
    public spinner: LoaderService,
    public helperService: HelperService,
    public authService: AuthService,
    public eventService: EventService
  ) {
    this.user_id = this.authService.userID();
    this.balance = 0.0;
  }

  ngOnInit() {
    this.loadBalance();
    this.loadAccountData();
    this.eventService.update_balance.subscribe((updated_balance) => {
      this.balance = updated_balance;
    });
  }

  extractImgUrl(data): void {
    for (const item of data) {
      const creditTxt = item.credit_text;
      const creditImgTxt = creditTxt.substring(
        creditTxt.lastIndexOf("<img"),
        creditTxt.lastIndexOf(">") + 1
      );
      item.credit_text = creditTxt.replace(creditImgTxt, "").trim();
      const creditImgUrl = creditImgTxt.substring(
        creditTxt.lastIndexOf("https://"),
        creditTxt.indexOf("' ")
      );
      item.creditImgUrl = creditImgUrl;

      const debitTxt = item.debit_text;
      const debitImgTxt = debitTxt.substring(
        debitTxt.lastIndexOf("<img"),
        debitTxt.lastIndexOf(">") + 1
      );
      item.debit_text = debitTxt.replace(debitImgTxt, "").trim();
      const debitImgUrl = debitImgTxt.substring(
        debitTxt.lastIndexOf("https://"),
        debitTxt.indexOf("' ")
      );
      item.debitImgUrl = debitImgUrl;
    }

    return data;
  }

  public loadAccountData() {
    this.spinner.show("", "Loading account statement");
    this.userService.statement(this.pager).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.transactions.length > 0) {
          this.statements = this.statements.concat(
            this.extractImgUrl(resp.transactions)
          );
          this.load_more_data = true;
        } else {
          this.load_more_data = false;
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  public loadMoreData(event) {
    if (this.load_more_data) {
      this.pager += 1;
      this.loadAccountData();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
      this.load_more_data = false;
    } else {
      event.target.complete();
    }
  }

  public loadBalance() {
    this.userService.balance().subscribe(
      (resp) => {
        this.balance = resp.balance;
        this.today_spent = resp.today_spent;
      },
      (err) => {
        this.helperService.showToast("Unable to load balance");
      }
    );
  }
}
