import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Base } from "./base";
import { EventService } from "./event.service";

@Injectable({
  providedIn: "root",
})
export class ChatRoomService {
  api_url = Base.api_url;

  constructor(public http: HttpClient, private eventService: EventService) {}

  public create(data): Observable<any> {
    return this.http.post(
      this.api_url + "/chat_rooms",
      { chat_room: data },
      this.requestHeader()
    );
  }

  public updateInfo(data, id): Observable<any> {
    return this.http.put(
      this.api_url + "/chat_rooms/" + id,
      { chat_room: data },
      this.requestHeader()
    );
  }

  public rooms(): Observable<any> {
    return this.http.get(this.api_url + "/chat_rooms", this.requestHeader());
  }

  public search(term): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/search?q=" + term,
      this.requestHeader()
    );
  }

  public room(id): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/" + id,
      this.requestHeader()
    );
  }

  public roomInfo(id): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/" + id + "/info",
      this.requestHeader()
    );
  }

  public messages(id): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/" + id + "/messages",
      this.requestHeader()
    );
  }

  public participants(id): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/" + id + "/participants",
      this.requestHeader()
    );
  }

  public commands(): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/commands",
      this.requestHeader()
    );
  }

  public personal_chat(username): Observable<any> {
    return this.http.post(
      this.api_url + "/chat_rooms/personal_chat",
      { username: username },
      this.requestHeader()
    );
  }

  public joinChatRoom(room_id): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/" + room_id + "/join",
      this.requestHeader()
    );
  }

  public categoryRooms(category): Observable<any> {
    return this.http.get(
      this.api_url + "/chat_rooms/category_rooms?category=" + category,
      this.requestHeader()
    );
  }

  public roomSubscribe(chat_room) {
    // let channel: Channel = this.actionCable
    //   .cable(Base.base_url + "cable")
    //   .channel("ChatRoomChannel", { room_id: chat_room.id });
    // channel.received().subscribe(
    //   (resp) => {
    //     this.eventService.emitChatNotification(resp.room_id);
    //   },
    //   (err) => {}
    // );
  }

  public kickUser(user_id, room_id): Observable<any> {
    return this.http.post(
      this.api_url + "/chat_rooms/" + room_id + "/kicks",
      { user_id: user_id },
      this.requestHeader()
    );
  }

  private requestHeader() {
    return { headers: { Authorization: `Bearer ${Base.getAuthToken()}` } };
  }
}
