import {Component, OnInit} from "@angular/core";
import { Router } from "@angular/router";
import {EventService} from "../../../services/event.service";
import {UserService} from "../../../services/user.service";
import {HelperService} from "../../../services/helper.service";
import {LoaderService} from "../../../services/loader.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss']
})

export class NotificationListComponent implements OnInit{
    notifications = [];
    readNotifications = [];
    constructor(
        public event: EventService,
        public router: Router,
        public userService: UserService,
        private helperService: HelperService,
        public spinner: LoaderService,
        public notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.loadNotification();
    }

    loadNotification() {
        this.spinner.show("", "Loading Notifications");
        this.userService.notifications().subscribe(
            (resp) => {
                this.spinner.hide();
                const { notifications, read_notifications } = resp;
                this.notifications.push(
                    ...this.notificationService.extractImgUrl(notifications)
                );
                this.readNotifications.push(
                    ...this.notificationService.extractImgUrl(read_notifications)
                );
                if (this.notifications.length > 0) {
                    this.readAllNotifications();
                }
            },
            (err) => {
                this.spinner.hide();
            }
        );
    }
    // if the notification type is Request navigate to request page
    // if the notification type is Friend navigate to user profile page
    // then make the unread notification to read
    readAllNotifications() {
        this.userService.readNotification().subscribe(
            (resp) => {
                if (resp.success) {
                    this.event.emitNotificationEvent({ type: "read" });
                }
            },
            (err) => {
                this.helperService.showToast("Unable to read notification");
            }
        );
    }

    public readNotification(item) {
        item.is_read = true;
    }

    deleteNotification(item) {
        this.userService.deleteNotification(item.id).subscribe(
            (resp) => {
                if (resp.success) {
                    this.readNotifications = this.readNotifications.filter(
                        (notif) => notif.id != item.id
                    );
                }
            },
            (err) => {
                this.helperService.showToast("Unable to delete notification");
            }
        );
    }
}