import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-announcements",
  templateUrl: "./announcements.component.html",
  styleUrls: ["./announcements.component.scss"],
})
export class AnnouncementsComponent implements OnInit {
  announcements = [];
  pager = 0;
  load_more_data = false;

  constructor(
    public userService: UserService,
    private spinner: LoaderService
  ) {}

  ngOnInit() {
    this.loadAnnouncement();
  }

  public loadMoreData(event) {
    if (this.load_more_data) {
      this.pager += 1;
      this.loadAnnouncement();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
      this.load_more_data = false;
    } else {
      event.target.complete();
    }
  }

  public loadAnnouncement() {
    this.spinner.show("", "Loading announcements...");
    this.userService.announcements(this.pager).subscribe(
      (resp) => {
        this.spinner.hide();
        const { announcements } = resp;
        if (announcements.length > 0) {
          this.announcements.push(...resp.announcements);
          this.load_more_data = true;
        } else {
          this.load_more_data = false;
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
