import {
  Component,
  OnInit,
  NgZone,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import {
  AlertController,
  ModalController,
  PopoverController,
} from "@ionic/angular";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { HelperService } from "../../services/helper.service";
import { UserService } from "../../services/user.service";
import { EmailService } from "../../services/email.service";
import { PopoverComponent } from "../component/popover/popover.component";
import { ChatRoomService } from "../../services/chat-room.service";
import { EventService } from "../../services/event.service";
import { ProgressLevelComponent } from "../progress-level/progress-level.component";
import { UpdateOnlineStatusComponent } from "../component/update-online-status/update-online-status.component";
import { TransferService } from "src/services/transfer.service";
import { Subscription } from "rxjs";
import { LoaderService } from "src/services/loader.service";

@Component({
  selector: "app-home",
  templateUrl: "home.page.html",
  styleUrls: ["home.page.scss"],
})
export class HomePage implements OnInit, OnDestroy, AfterViewInit {
  public unsubscribeBackEvent: any;
  subscription: any;
  unread_email = 0;
  online_friend = 0;
  public friends = [];
  public FriendsList = [];
  public allUsers = [];
  public Conversations = [];
  public notifications;
  user: any;
  levelImgPath: string = "";
  bgColor: string = "#4caf50";
  public subs: Subscription = new Subscription();
  refreshFriendList = false;

  constructor(
    public ngZone: NgZone,
    public modalCtrl: ModalController,
    public popoverController: PopoverController,
    public authService: AuthService,
    public router: Router,
    public helperService: HelperService,
    public userService: UserService,
    public alertCtrl: AlertController,
    public emailService: EmailService,
    public popover: PopoverController,
    public chatRoom: ChatRoomService,
    private eventService: EventService,
    private transferService: TransferService,
    private spinner: LoaderService
  ) {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  // ngOnInit function to load conversation and all firends
  ngOnInit() {
    this.initHomePage();
    this.onGetStatusSubject();
    this.eventService.updateUser.subscribe((user) => {
      this.user = user;
    });
  }

  onGetStatusSubject() {
    this.subs.add(
      this.transferService.getStatusSubject().subscribe((resp) => {
        this.user.appearance = resp.appearance;
      })
    );
  }

  async presentPopover(event: any) {
    const popover = await this.popoverController.create({
      component: UpdateOnlineStatusComponent,
      cssClass: "appearance-popover",
      event,
    });
    await popover.present();
  }

  public searchFriend() {
    this.router.navigateByUrl("/users");
  }

  async modalLevel() {
    const modal = await this.modalCtrl.create({
      component: ProgressLevelComponent,
      cssClass: "progress-level-modal",
    });
    modal.present();
  }

  public initHomePage() {
    this.loadProfile();
    this.loadFriendList();
    this.unreadEmail();
    this.eventService.friends.subscribe((data) => {
      this.friends.push(data);
    });
  }

  async friendActions(ev, friend) {
    const popover = await this.popover.create({
      component: PopoverComponent,
      translucent: true,
      cssClass: "profile-item-options",
      event: ev,
      componentProps: { user_id: friend.id, username: friend.username },
    });
    return await popover.present();
  }

  public unreadEmail() {
    this.emailService.unread().subscribe((resp) => {
      if (resp.success) {
        this.unread_email = resp.emails.length;
      }
    });
  }

  public loadFriendList() {
    if (this.refreshFriendList) {
      this.spinner.show("", "Refreshing friend list...");
    }
    this.userService.friendList().subscribe(
      (resp) => {
        this.resetOnRefreshFriendList();
        if (resp.success) {
          this.friends = resp.friends;
          this.online_friend = resp.friends.filter(
            (friend) => friend.online_status
          ).length;
        }
      },
      (err) => {
        this.resetOnRefreshFriendList();
      }
    );
  }

  resetOnRefreshFriendList() {
    if (this.refreshFriendList) {
      this.spinner.hide();
      this.refreshFriendList = false;
    }
  }

  public loadProfile() {
    this.authService.getCurrentUser().subscribe(
      (resp) => {
        if (resp.success) {
          this.user = resp.profile;
          this.levelImgPath = this.helperService.setLevelImagePath(
            this.user.level
          );
        }
      },
      (err) => {
        this.helperService.showToast("Unable to load profile this moment");
        if (err.status === 401) {
          window.localStorage.clear();
        }
      }
    );
  }

  // proceed to notifications page
  goNotification() {
    this.router.navigateByUrl("notifications");
  }

  ionRefresh(event) {
    setTimeout(() => {
      event.target.complete();
      this.loadFriendList();
      this.unreadEmail();
    }, 1000);
  }

  // procced to users list to find user
  findUser() {
    this.router.navigateByUrl("users");
  }

  public updateStatusMessage(event) {
    if (event.key == "Enter") {
      this.userService.updateStatusMessage(this.user.status_message).subscribe(
        (resp) => {
          this.helperService.showToast(
            "Your status has been updated.",
            "success"
          );
        },
        (err) => {
          this.helperService.showToast("Unable to update status message");
        }
      );
    }
  }
}
