import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  IonContent,
  ModalController,
  AlertController,
  ActionSheetController,
  PopoverController,
} from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { ChatRoomService } from "../../../services/chat-room.service";
import { HelperService } from "../../../services/helper.service";
import { EventService } from "../../../services/event.service";
import { ChatService } from "../../../services/chat.service";
import { ParticipantsComponent } from "../participants/participants.component";
import { RoomInfoComponent } from "../room-info/room-info.component";
import { Base } from "../../../services/base";
import { UserService } from "../../../services/user.service";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { LoaderService } from "../../../services/loader.service";
import { Message } from "../../models/message";
import { RxStompService } from "@stomp/ng2-stompjs";
import { Observable, Subscription, VirtualTimeScheduler } from "rxjs";
import { RoomActionsComponent } from "src/app/component/room-actions/room-actions.component";
import { TransferService } from "src/services/transfer.service";
import { game_actions as Actions } from "../game_actions";
import { AllMessageReaction } from "../../../assets/js/message_reaction.js";

@Component({
  selector: "app-room",
  templateUrl: "./room.component.html",
  styleUrls: ["./room.component.scss"],
  providers: [Keyboard],
})
export class RoomComponent implements OnInit, OnDestroy {
  @ViewChild(IonContent)
  content: IonContent;
  @ViewChild("messageInput") inputElement: ElementRef;
  @Input() room_id: any;
  public hideStatus: boolean = true;
  public show_room_action = false;
  public newmessage = "";
  has_new_message = false;
  scroll_offset = 0;
  public new_member = false;
  public emoji;
  chat_room: any;
  user: any;
  hide_bottom_actions: boolean;
  showEmojiPicker = false;
  messages = [];
  open_bid = true;
  subscription: any;
  base_url = Base.base_url;
  roomUsers: string = "";
  private topicSubscription: Subscription;
  username: string;
  ownUsername: string;
  sendFilePermission: boolean = false;
  isOnBottom: boolean = true;
  private subs: Subscription = new Subscription();
  copyReplay: any;
  reply_message: any;
  reset: any;
  MessageReactions: any = [];
  friends: any = [];
  data$: Observable<any>;
  msgId: number;
  openReplyWindow: boolean = false;
  currentMessage: Message | null = null;
  messageActionsInSm: boolean;

  constructor(
    public modalCtrl: ModalController,
    public chatService: ChatService,
    public authService: AuthService,
    public roomService: ChatRoomService,
    public helperService: HelperService,
    private route: ActivatedRoute,
    public actionSheetCtrl: ActionSheetController,
    public alertCtrl: AlertController,
    public spinnerDialog: LoaderService,
    public popoverController: PopoverController,
    public router: Router,
    public userService: UserService,
    private keyboard: Keyboard,
    private rxStompService: RxStompService,
    private eventService: EventService,
    private transferService: TransferService
  ) {}

  ngOnInit() {
    let room_id = this.room_id || this.route.snapshot.paramMap.get("id");
    this.loadChatRoom(room_id);
    this.subscribeToStickerData();
    this.MessageReactions = AllMessageReaction;
    this.hideKeyboard();
  }

  subscribeToStickerData(): void {
    this.subs.add(
      this.transferService.getStickerData().subscribe((resp) => {
        const message = {
          text: resp.imgPath,
          category: "sticker",
          created_at: new Date().toLocaleString(),
          id: new Date().getMilliseconds(),
          user: {
            id: this.user ? this.user.id : "",
            username: this.user ? this.user.username : "",
            online_status: this.user.online_status,
          },
        };
        this.messages.push(message);
        this.ScrollToBottom();
        this.sendMessage(resp.imgPath, "sticker", this.messages.length);
      })
    );
  }

  subscribeToChannel() {
    this.topicSubscription = this.rxStompService
      .watch(`/amq/queue/chat_${this.room_id}_room_${this.user.id}_user`)
      .subscribe(
        (resp) => {
          this.eventService.emitChatNotification(this.room_id);
          let resp_body = JSON.parse(resp.body);
          const response_message = resp_body.message;
          let disp_message = response_message.message;
          if (response_message.type == "draw_notification") {
            disp_message = response_message.draw_notifications;
          }

          if (
            disp_message.category === "text" &&
            this.user &&
            response_message.user_id === this.user.id
          ) {
          } else if (disp_message.category === "notification") {
            if (
              response_message.action &&
              response_message.action === "leave" &&
              this.user &&
              this.user.id === response_message.user_id
            ) {
              this.helperService.removeRoom(response_message.room_id);
            }
            this.appendMessage(disp_message);
          } else {
            if (this.scroll_offset > 700) {
              this.has_new_message = true;
            }

            this.updateMessage(disp_message);
          }
          setTimeout(() => {
            this.ScrollToBottom(true);
          }, 100);
        },
        (err) => {
          console.log("err: ", err);
        }
      );
  }

  // When the current begins to start typing the ion-content scroll to bottom
  onFocus() {
    this.hideStatus = !this.hideStatus;
    this.showEmojiPicker = false;
    this.ScrollToBottom();
  }

  public hideKeyboard() {
    this.keyboard.hide();
  }

  public resizeInput() {
    let scroll_height = this.inputElement.nativeElement.scrollHeight;
    scroll_height = scroll_height < 36 ? 20 : scroll_height;
    this.inputElement.nativeElement.style.height = scroll_height + "px";
    this.inputElement.nativeElement.scrollTop = scroll_height;
  }

  getWithUsername(): void {
    this.ownUsername = JSON.parse(window.localStorage.getItem("auth")).username;
    this.username = this.chat_room.name
      .replace("Personal:", "")
      .replace(`${this.ownUsername}_`, "")
      .replace(`_${this.ownUsername}`, "");
  }

  setSendFilePermission() {
    const { type, share_image, role } = this.chat_room;
    const { roles } = this.user;
    if (type === "Personal") {
      this.sendFilePermission = true;
    } else if (type === "Private") {
      if (
        share_image &&
        (role === "moderator" || role === "super_moderator" || role === "admin")
      ) {
        this.sendFilePermission = true;
      } else {
        this.sendFilePermission = roles.some(
          (role) =>
            role.name === "admin" ||
            role.name === "admin_copy" ||
            role.name === "super_admin" ||
            role.name === "staff" ||
            role.name === "staff_copy"
        );
      }
    } else if (type === "Public") {
      this.sendFilePermission = roles.some(
        (role) =>
          role.name === "admin" ||
          role.name === "admin_copy" ||
          role.name === "super_admin" ||
          role.name === "staff" ||
          role.name === "staff_copy" ||
          role.name === "shield"
      );
    }
  }

  public loadChatRoom(id) {
    this.roomService.room(id).subscribe(
      (resp) => {
        if (resp.success) {
          this.chat_room = resp.chat_room;
          this.loadUser();
          for (let i = 0; i < this.chat_room.messages.length; i++) {
            this.updateMessage(this.chat_room.messages[i]);
            this.inputElement.nativeElement.focus();
          }
          this.ScrollToBottom();
          //this.createChannel(id);
          this.helperService.addRoom(this.chat_room);
          this.new_member = resp.chat_room.new_join || false;
          this.roomUsers = this.chat_room.users
            .map((user) => user.username)
            .join(", ");
          this.getWithUsername();
        } else {
          this.helperService.showToast(resp.errors);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to load chat room");
      }
    );
  }

  public loadUser() {
    this.authService.getCurrentUser().subscribe((resp) => {
      this.user = resp.profile;

      this.setSendFilePermission();
      if (this.user) {
        this.chatService
          .checkCreateQueue(`public_${this.user.id}_channel`)
          .subscribe((res) => {
            this.subscribeToChannel();
          });
      }
    });
  }

  public copyUsername(username) {
    this.newmessage += " " + username;
    this.inputElement.nativeElement.focus();
  }

  copyToClipboard(text) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", text);
      e.preventDefault();
    };
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  }

  async messageOptions(message) {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: "Copy username",
          role: "destructive",
          icon: "copy-outline",
          handler: () => {
            this.copyToClipboard(message.user.username);
          },
        },
        {
          text: "Copy only text",
          role: "destructive",
          icon: "copy-outline",
          handler: () => {
            this.copyToClipboard(message.text);
          },
        },
        {
          text: "Copy text with username",
          role: "destructive",
          icon: "copy-outline",
          handler: () => {
            this.copyToClipboard(message.user.username + " " + message.text);
          },
        },
        {
          text: "View user profile",
          icon: "person-circle-outline",
          handler: () => {
            if (this.user.id == message.user.id) {
              this.router.navigateByUrl("/profile");
            } else {
              this.router.navigateByUrl("/profile/" + message.user.id);
            }
          },
        },
        {
          text: "Kick user",
          icon: "walk-outline",
          handler: () => {
            if (message.user.id) {
              this.kickUser(message.user);
            }
          },
        },
        {
          text: "Transfer cent",
          icon: "swap-horizontal-outline",
          handler: () => {
            this.router.navigate(["/transfer-coin"], {
              queryParams: { username: message.user.username },
            });
          },
        },
        {
          text: "Send email",
          icon: "mail-outline",
          handler: () => {
            this.router.navigate(["/emails/new"], {
              queryParams: { username: message.user.username },
            });
          },
        },
        {
          text: "Cancel",
          icon: "close",
          role: "cancel",
          handler: () => {},
        },
      ],
    });
    if (
      !["command", "gift", "notification", "bot"].includes(message.category)
    ) {
      await actionSheet.present();
    }
  }

  public loadParticipants(kick = false) {
    this.show_room_action = false;
    this.modalCtrl
      .create({
        component: ParticipantsComponent,
        cssClass: "half-screen-modal",
        componentProps: {
          room_id: this.chat_room.id,
          name: this.chat_room.name,
          kick: kick,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }

  async kickUser(user) {
    const alert = await this.alertCtrl.create({
      header: "Kick User",
      message: "Are you sure want to kick <b>" + user.username + "?</b>",
      mode: "ios",
      buttons: [
        {
          text: "Cancel",
          handler: (data) => {},
        },
        {
          text: "Kick",
          handler: (data) => {
            this.confirmKick(user);
          },
        },
      ],
    });
    await alert.present();
  }

  public confirmKick(user) {
    this.roomService.kickUser(user.id, this.room_id).subscribe(
      (resp) => {
        if (resp.success) {
          this.helperService.showToast(
            "You kicked the user " + user.username,
            "success"
          );
        } else {
          this.helperService.showAlert(resp.message);
        }
      },
      (err) => {
        this.helperService.showAlert("Unable to kick this moment");
      }
    );
  }

  public selectPhoto(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imgPath = reader.result;
      let message = {
        text: "",
        category: "image",
        created_at: new Date().toLocaleString(),
        id: new Date().getMilliseconds(),
        user: {
          id: this.user ? this.user.id : "",
          username: this.user ? this.user.username : "",
          online_status: this.user.online_status,
        },
        image: {
          url: imgPath,
          thumb: {
            url: imgPath,
          },
        },
      };
      this.messages.push(message);
      this.ScrollToBottom();
      this.sendPhoto(imgPath);
    };
  }

  // Send message when the message is a text
  // when message is sent the message input becomes empty and the content scroll to bottom
  public addMessage(type = "") {
    this.inputElement.nativeElement.style.height = "20px";
    let message = this.newmessage.trim();
    if (message.length === 0) {
      this.newmessage = "";
      this.helperService.showAlert("Text can't be blank");
      return;
    }

    this.newmessage = "";

    if (type == "") {
      type = this.messageType(message);
      if (type === "game" && this.busyBid(message)) {
        this.helperService.showAlert("Have wait 1 sec for next bid!");
        return false;
      }
    }

    if (type == "text") {
      let resp_msg = this.responseMessage(message);
      this.appendMessage(this.parseMessage(resp_msg));
    }

    if (type === "image") {
      let resp_msg = this.responseMessage(message);
    }

    this.sendMessage(
      message,
      type,
      type == "text" ? this.messages.length : null
    );
    document?.getElementById("inputMessage")?.focus();
  }

  sendMessageWithEnter(event): void {
    if (event.keyCode === 13) {
      this.addMessage();
    }
  }

  public checkMessage() {
    return !this.newmessage.trim();
  }

  public messageType(message) {
    if (message.startsWith("/")) {
      let command = message.split(" ")[0] || "";
      if (command == "/gift") {
        return "gift";
      }
      if (command == "/bot") {
        return "game";
      } else {
        return "command";
      }
    } else if (message.startsWith("!")) {
      let command = message.split(" ")[0] || "";
      if (
        [
          "!start",
          "!j",
          "!d",
          "!b",
          "!stop",
          "!restart",
          "!c",
          "!p",
          "!deal",
          "!s",
          "!a",
        ].includes(command)
      ) {
        return "game";
      } else {
        return "text";
      }
    } else {
      return "text";
    }
  }

  public sendMessage(message, type, last_msg_index = null) {
    const parsedMessage = Message.parse(message, type);

    let category = this.reply_message ? "reply" : null;
    let data = {
      message: parsedMessage,
      type: type,
      message_id: (this.reply_message || {}).id,
      category: category,
      to_reply: this.reply_message?.user?.id,
    };

    this.chatService.send(data, this.chat_room.id).subscribe(
      (resp) => {
        this.msgId = resp.message;
        this.inputElement.nativeElement.focus();
        if (resp.success) {
          this.onFocus();
          if (resp.action == "action") {
            this.helperService.showAlert(resp.message);
          }
          if (last_msg_index) {
            resp.message_new.parse_text = resp.message_new.text;
            this.messages[last_msg_index - 1] = resp.message_new;
            if (this.reply_message) {
              this.messages[last_msg_index - 1].quote = this.reply_message;
            }
          }
          this.copyReplay = this.reset;
          this.reply_message = this.reset;
        } else {
          this.helperService.showAlert(resp.errors);
        }
      },
      (err) => {
        this.inputElement.nativeElement.focus();
        this.helperService.showToast("Unable to message delivery!");
      }
    );
  }

  public sendPhoto(imageData) {
    this.chatService
      .send(
        { base64_file: imageData, message: "", type: "image" },
        this.chat_room.id
      )
      .subscribe(
        (resp) => {
          this.inputElement.nativeElement.focus();
          if (resp.success) {
            this.onFocus();
            if (resp.action == "action") {
              this.helperService.showAlert(resp.message);
            }
          } else {
            this.helperService.showAlert(resp.errors);
          }
        },
        (err) => {
          this.inputElement.nativeElement.focus();
          this.helperService.showToast("Unable to message delivery!");
        }
      );
  }

  public parseMessage(message, required = true) {
    message.parse_text = required
      ? Message.parse(message.text, message.category)
      : message.text;
    return message;
  }

  public busyBid(command) {
    if (command.startsWith("!j") || command.startsWith("!b")) {
      if (this.open_bid) {
        this.open_bid = false;
        setTimeout(() => {
          this.open_bid = true;
        }, 1100);
      } else {
        return true;
      }
    }
    return false;
  }

  async EmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    if (this.showEmojiPicker) {
      this.ScrollToBottom();
    }
    if (!this.showEmojiPicker) {
      document?.getElementById("inputMessage")?.focus();
    }
  }

  public roomAction() {}

  async openRoomActions(ev: any) {
    const popover = await this.popoverController.create({
      component: RoomActionsComponent,
      event: ev,
      cssClass: "room-action",
      componentProps: {
        chatRoom: this.chat_room,
        username: this.username,
      },
    });
    await popover.present();
  }

  public responseMessage(text) {
    let message = {
      text: text,
      category: "text",
      created_at: new Date().toLocaleString(),
      id: new Date().getMilliseconds(),
      user: {
        id: this.user ? this.user.id : "",
        username: this.user ? this.user.username : "",
        online_status: this.user.online_status,
      },
    };
    return this.parseMessage(message);
  }

  trackMessage(index, message): number {
    return message.id;
  }

  async checkBalance(amount) {
    this.show_room_action = false;
    const alert = await this.alertCtrl.create({
      header: "Account balance",
      message: "Your available balance is " + amount,
      mode: "ios",
      buttons: ["ok"],
    });
    await alert.present();
  }

  public loadBalance() {
    this.userService.balance().subscribe(
      (resp) => {
        this.checkBalance(resp.balance);
      },
      (err) => {
        this.helperService.showToast("Unable to load balance this moment");
      }
    );
  }

  async roomInfo() {
    this.show_room_action = false;
    this.modalCtrl
      .create({
        component: RoomInfoComponent,
        componentProps: {
          room_id: this.chat_room.id,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }

  public leaveRoom() {
    this.show_room_action = false;
    this.spinnerDialog.show("", "Leaving room");
    this.chatService.leave(this.chat_room.id).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.helperService.removeRoom(this.chat_room.id);
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        if (err.status === 404) {
          this.helperService.removeRoom(this.chat_room.id);
        } else {
          this.helperService.showToast(
            "Unable to leave the room, Please try after sometimes!"
          );
        }
      }
    );
  }

  public hidePopups() {
    this.showEmojiPicker = false;
    this.show_room_action = false;
  }

  public selectEmoji(emoji) {
    this.newmessage += emoji;
    this.inputElement.nativeElement.focus();
    this.showEmojiPicker = true;
    this.resizeInput();
  }

  public viewProfile() {
    this.router.navigateByUrl("/profile/" + this.username);
  }

  public closeChat() {
    this.spinnerDialog.show("", "Closing chat...");
    this.chatService.closeChat(this.chat_room.id).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.helperService.removeRoom(this.chat_room.id);
        } else {
          this.helperService.showToast(resp.errors);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showToast("Unable to close chat this moment!");
      }
    );
  }

  private updateMessage(message) {
    if (message.to_id) {
      if (this.user && message.to_id === this.user.id) {
        this.appendMessage(message);
      }
    } else {
      if (
        message.action == "reaction_update" ||
        message.action == "reaction_delete"
      ) {
        this.updateReact(message.id, message.reactions_count);
      } else {
        this.appendMessage(message);
      }
    }
  }

  private updateReact(id, react) {
    for (let msg of this.messages) {
      if (msg.id == id) {
        msg.reactions_count = react;
        break;
      }
    }
  }

  private appendMessage(message) {
    this.openReplyWindow = false;
    if (Actions.includes(message.action)) return;

    const parsedMessage = this.parseMessage(message, true);
    if (this.messages.length > 150) {
      this.messages.shift();
    }
    if (!this.messages.includes(parsedMessage)) {
      this.messages.push(parsedMessage);
    }
  }

  // if you want to scroll bottom from top use this function
  ScrollToBottom(is_reading = false) {
    if (is_reading && this.scroll_offset > 1000) {
      this.has_new_message = true;
    } else {
      this.has_new_message = false;
      setTimeout(() => {
        this.content.scrollToBottom(500);
      }, 100);
    }
  }

  public getScrollPosition(event) {
    this.content.getScrollElement().then((el) => {
      this.scroll_offset = el.scrollHeight - event.detail.scrollTop;

      if (this.scroll_offset > 700) {
        this.isOnBottom = false;
      } else {
        this.isOnBottom = true;
        this.has_new_message = false;
      }
    });
  }

  public scrollToNewMessage() {
    this.scroll_offset = 0;
    this.has_new_message = false;
    this.ScrollToBottom();
  }

  unSubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.topicSubscription) {
      this.topicSubscription.unsubscribe();
    }
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public viewImage(src: string, title: string, description: string) {
    this.helperService.imageViewer(src, title, description);
  }

  public checkVideoPlay(e) {
    const { id } = e.target;
    const players = document.querySelectorAll(".audio-player");

    for (let i = 0; i < players.length; i++) {
      if (players[i].id !== id) {
        (players[i] as HTMLAudioElement).pause();
      }
    }
  }

  scrollToBottom() {
    this.content.scrollToBottom(500);
  }

  messageReaction(message) {
    message.react_on = !message.react_on;
    message.removeAndForward = false;
  }

  removeAndForwardToggle(message) {
    message.removeAndForward = !message.removeAndForward;
    message.react_on = false;
  }

  messageReply(message) {
    this.openReplyWindow = true;
    message.react_on = false;
    message.removeAndForward = false;

    this.reply_message = message;
    if (message.text.length >= 50) {
      this.copyReplay = message.text.split(" ").slice(0, 15).join(" ") + "...";
    } else {
      this.copyReplay = message.text;
    }
  }

  removeReply() {
    this.openReplyWindow = false;
    this.reply_message = this.reset;
  }

  messageReact(type, message) {
    console.log("message react: ", type, message);
    message.react_on = false;
    this.chatService
      .messageReaction(this.chat_room.id, message.id, type)
      .subscribe(
        (resp) => {},
        (err) => {}
      );
  }

  RemoveFor(data: any, message) {
    let removeForSelection = data.detail.value;
    this.chatService
      .removeMessage(this.chat_room.id, message.id, removeForSelection)
      .subscribe(() => {
        this.messageActionsInSm = false;
        this.messages = this.messages.filter((msg) => msg.id !== message.id);
      });
  }

  forwardTo(data: any, message) {
    let forwardTo = data.detail.value;
    let requestBody = {
      message: message.text,
      message_id: message.id,
      category: "forward",
      to_reply: forwardTo,
    };

    this.chatService.send(requestBody, this.chat_room.id).subscribe(
      (resp) => {
        if (resp.success) {
          this.helperService.showToast("Message forwarded successfully");
          this.currentMessage.messageReactionsInSm = false;
          this.messageActionsInSm = false;
        } else {
          this.helperService.showAlert(resp.errors);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to message delivery!");
      }
    );
  }

  loadFriends() {
    this.userService.friendList().subscribe(
      (res) => {
        this.friends = res.friends;
        this.hide_bottom_actions = true;
      },
      (err) => {
        this.helperService.showToast("Unable to load friend list");
      }
    );
  }

  onContextMenu(event: MouseEvent, message: Message): void {
    event.preventDefault();
    this.currentMessage = message;
    this.currentMessage.messageReactionsInSm = true;
    this.messageActionsInSm = true;
    this.hide_bottom_actions = false;
  }

  @HostListener("document:click", ["$event"])
  onBodyClick(event: MouseEvent): void {
    const classList = (event.target as Element).classList;
    this.currentMessage.messageReactionsInSm = false;
    this.hide_bottom_actions = true;
  }

  ngOnDestroy() {
    this.unSubscribe();
  }
}
