import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { HomePage } from "./home/home.page";
import { WelcomeComponent } from "./welcome/welcome.component";
import { AuthGuard } from "../services/auth.guard";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { ProfileComponent } from "./profile/profile.component";
import { NotificationComponent } from "./notification/notification.component";
import { NotificationListComponent } from "./notification/notification-list/notification-list.component";
import { MentionsComponent } from "./notification/mentions/mentions.component";
import { UsersComponent } from "./users/users.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { EditProfilePage } from "./edit-profile/edit-profile.page";
import { FriendsComponent } from "./friends/friends.component";
import { FeedComponent } from "./feed/feed.component";
import { AccountComponent } from "./account/account.component";
import { ExploreComponent } from "./explore/explore.component";
import { FriendRequestComponent } from "./friends/request/request.component";
import { FriendListComponent } from "./friends/list/list.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { TransferCoinComponent } from "./transfer-coin/transfer-coin.component";
import { AnnouncementsComponent } from "./announcements/announcements.component";
import { GiftStoresComponent } from "./gift-stores/gift-stores.component";
import { GiftDetailsComponent } from "./gift-stores/details/details.component";
import { ChatRoomsComponent } from "./chat-rooms/chat-rooms.component";
import { RoomComponent } from "./chat-rooms/room/room.component";
import { RoomSearchComponent } from "./chat-rooms/search/search.component";
import { SettingsComponent } from "./settings/settings.component";
import { ParticipantsComponent } from "./chat-rooms/participants/participants.component";
import { CommandComponent } from "./command/command.component";
import { AnnouncementDetailsComponent } from "./announcements/details/details.component";
import { EmailsComponent } from "./emails/emails.component";
import { EmailDetailsComponent } from "./emails/details/details.component";
import { ComposeComponent } from "./emails/compose/compose.component";
import { RoomInfoComponent } from "./chat-rooms/room-info/room-info.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ChatLayoutComponent } from "./chat-layout/chat-layout.component";
import { AccountActivationComponent } from "./account-activation/account-activation.component";
import { ResendActivationComponent } from "./resend-activation/resend-activation.component";
import { RatingComponent } from "./user-profile/rating/rating.component";
import { MerchantPanelComponent } from "./merchant-panel/merchant-panel.component";
import { LeaderBoardComponent } from "./leader-board/leader-board.component";
import { PeopleComponent } from "./people/people.component";
import { LeaderBoardDetailsComponent } from "./leader-board-details/leader-board-details.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { GiftsComponent } from "./gifts/gifts.component";
import { FootprintsComponent } from "./footprints/footprints.component";
import { LegendCampaignComponent } from "./legend-campaign/legend-campaign.component";

const routes: Routes = [
  {
    path: "",
    component: ChatLayoutComponent,
    children: [
      {
        path: "",
        component: HomePage,
        canActivate: [AuthGuard],
      },
      {
        path: "home",
        component: HomePage,
        canActivate: [AuthGuard],
      },
      {
        path: "chat-rooms",
        component: ChatRoomsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "chat-rooms/:id",
        component: RoomComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "account-activation",
    component: AccountActivationComponent,
  },
  {
    path: "resend-activation",
    component: ResendActivationComponent,
  },
  {
    path: "welcome",
    component: WelcomeComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "announcements",
    component: AnnouncementsComponent,
  },
  {
    path: "announcements/:id",
    component: AnnouncementDetailsComponent,
  },
  {
    path: "gift-stores",
    component: GiftStoresComponent,
  },
  {
    path: "gift/:id",
    component: GiftDetailsComponent,
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings",
    component: SettingsComponent,
  },
  {
    path: "profile/:id",
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    // path: "notifications",
    // component: NotificationComponent,
    // canActivate: [AuthGuard],
    path: "notifications",
    component: NotificationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: NotificationListComponent,
      },
      {
        path: "notification-list",
        component: NotificationListComponent,
      },
      {
        path: "mentions",
        component: MentionsComponent,
      },
    ],
  },
  {
    path: "users",
    component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "emails",
    component: EmailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "emails/new",
    component: ComposeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "emails/:id/details",
    component: EmailDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "edit-profile",
    component: EditProfilePage,
    canActivate: [AuthGuard],
  },
  {
    path: "friends",
    component: FriendsComponent,
    children: [
      {
        path: "",
        component: FriendListComponent,
      },
      {
        path: "list",
        component: FriendListComponent,
      },
      {
        path: "request",
        component: FriendRequestComponent,
      },
    ],
  },
  {
    path: "feeds",
    component: FeedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "chat-rooms/:id/participants",
    component: ParticipantsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "room-search",
    component: RoomSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "chat-rooms/:id/room_info",
    component: RoomInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account",
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "transfer-coin",
    component: TransferCoinComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "explore",
    component: ExploreComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "explore/people",
    component: PeopleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "leader-board",
    component: LeaderBoardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "leader-board-details",
    component: LeaderBoardDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "merchant-panel",
    component: MerchantPanelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "commands",
    component: CommandComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "feedback",
    component: FeedbackComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "terms-condition",
    component: TermsConditionComponent,
  },
  {
    path: "users/:user_id/rating",
    component: RatingComponent,
  },
  {
    path: "users/:user_id/rating_details",
    component: RatingComponent,
  },
  {
    path: "alerts",
    component: AlertsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "gifts",
    component: GiftsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "footprints",
    component: FootprintsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "legend-campaign",
    component: LegendCampaignComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
