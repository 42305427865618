import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  AlertController,
  ToastController,
  ModalController,
} from "@ionic/angular";
import { UserService } from "../../services/user.service";
import { HelperService } from "../../services/helper.service";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  public users = [];
  public loadedUserList: any;
  public term = "";
  phone_model = "iPhone";
  results = "Type username to search";
  public toast;

  constructor(
    public usersProvider: UserService,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public helperService: HelperService,
    public spinnerDialog: LoaderService,
    public router: Router
  ) {}

  // Get all users
  ngOnInit() {}

  // initialize loadeduserlist with list of users
  initializeItems() {
    this.users = this.loadedUserList;
  }

  // Search for user with name
  // getUser(searchbar) {
  //   this.initializeItems();
  //   let q = searchbar.srcElement.value;
  //   if (!q) {
  //     return;
  //   }
  //   this.users = this.users.filter((v) => {
  //     if (v.username && q) {
  //       if (v.username.toLowerCase().indexOf(q.toLowerCase()) > -1) {
  //         return true;
  //       }
  //       return false;
  //     }
  //   });
  //   console.log(q, this.users.length);
  //   this.results = this.users.length === 0 ? 'No user found' : '';
  // }

  public searchUser() {
    if (this.term.trim().length > 0) {
      this.spinnerDialog.show("", "Searching new friends...");
      this.usersProvider.allUsers(this.term).subscribe(
        (resp) => {
          this.spinnerDialog.hide();
          this.users = resp.users;
          this.loadedUserList = resp.users;
          this.results = this.users.length === 0 ? "No user found" : "";
        },
        (err) => {
          this.spinnerDialog.hide();
          this.helperService.showToast("Unable to load users");
        }
      );
    } else {
      this.helperService.showAlert("Can't search empty names!");
    }
  }

  public gotoProfile(user_id) {
    this.router.navigateByUrl("/profile/" + user_id);
  }

  public removeFromList(user) {
    let index = this.users.indexOf(user);
    if (index !== -1) {
      this.users.splice(index, 1);
    }
  }

  // view user image in large form at modal page
  viewImage(src: string, title: string, description: string) {
    this.helperService.imageViewer(src, title, "User profile photo");
  }
}
