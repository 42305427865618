import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {HelperService} from '../../services/helper.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss'],
})
export class TermsConditionComponent implements OnInit {
  terms = '';

  constructor(public userService: UserService, public helperService: HelperService) { }

  ngOnInit() {
    this.loadTerms();
  }

  public loadTerms() {
    this.userService.appSettings().subscribe(resp => {
      this.terms = resp.terms_condition;
    }, err => {
      this.helperService.showToast('Unable to load terms & condition!');
    });
  }
}
