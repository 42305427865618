import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Base } from "./base";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  api_url = Base.api_url;
  isLoggedIn: boolean;

  constructor(public http: HttpClient) {}

  public register(userDetails): Observable<any> {
    return this.http.post(this.api_url + "/users/registration", {
      user: userDetails,
    });
  }

  public login(userDetails): Observable<any> {
    return this.http.post(this.api_url + "/users/login", userDetails);
  }

  public forgotPassword(login): Observable<any> {
    return this.http.post(this.api_url + "/users/forgot_password", {
      login: login,
    });
  }

  public resetPassword(credential, token): Observable<any> {
    return this.http.post(
      this.api_url + "/users/reset_password?reset_password_token=" + token,
      { user: credential }
    );
  }

  public activation(code): Observable<any> {
    return this.http.get(this.api_url + "/users/confirm_account?token=" + code);
  }

  public resend_activation(email): Observable<any> {
    return this.http.post(this.api_url + "/users/resend_confirmation?", {
      email: email,
    });
  }

  public checkUsername(username): Observable<any> {
    return this.http.get(
      this.api_url + "/users/check_username?username=" + username,
      { headers: { Authorization: `Bearer ${this.getAuthToken()}` } }
    );
  }

  getCurrentUser(): Observable<any> {
    // let auth_token = JSON.parse(window.localStorage.getItem("auth")).auth_token;
    return this.http.get(this.api_url + "/users/profile", {
      headers: { Authorization: `Bearer ${this.getAuthToken()}` },
    });
  }

  public changePassword(data): Observable<any> {
    return this.http.patch(
      this.api_url + "/users/update_password",
      { user: data },
      { headers: { Authorization: `Bearer ${this.getAuthToken()}` } }
    );
  }

  public changeEmail(email, token): Observable<any> {
    return this.http.patch(
      this.api_url + "/users/change_email",
      {
        email: email,
        token: token,
      },
      { headers: { Authorization: `Bearer ${this.getAuthToken()}` } }
    );
  }

  public changeEmailToken(): Observable<any> {
    return this.http.patch(
      this.api_url + "/users/change_email",
      {},
      { headers: { Authorization: `Bearer ${this.getAuthToken()}` } }
    );
  }

  public changeSecurityQuestion(data): Observable<any> {
    return this.http.post(
      this.api_url + "/users/change_security_question",
      data,
      this.requestHeader()
    );
  }

  public changePin(pin_data): Observable<any> {
    return this.http.post(this.api_url + "/users/change_pin", pin_data, {
      headers: { Authorization: `Bearer ${this.getAuthToken()}` },
    });
  }

  public updateProfile(data): Observable<any> {
    return this.http.post(
      this.api_url + "/users/update_profile",
      { user: data },
      this.requestHeader()
    );
  }

  public userID() {
    let auth = window.localStorage.getItem("auth");
    return auth ? JSON.parse(auth).user_id : 0;
  }

  public logout(): Observable<any> {
    return this.http.post(
      this.api_url + "/users/logout",
      {},
      this.requestHeader()
    );
  }

  public getAuthToken() {
    let auth = JSON.parse(window.localStorage.getItem("auth"));
    return auth ? auth.auth_token : "";
  }

  private requestHeader() {
    return { headers: { Authorization: `Bearer ${this.getAuthToken()}` } };
  }
}
