import { emojis } from "../../assets/js/emojis.js";

export class Message {
  messageReactionsInSm: boolean = true;

  static parse(message: string, category: string): string {
    if (!message) {
      return "";
    }
    const imgPrefix = new RegExp("\\[img src=", "g");
    const imgSuffix = new RegExp("/\\]", "g");
    message = message
      .replace(imgPrefix, "<img src=assets/game/")
      .replace(imgSuffix, ".png> ");
    if (category !== "text") {
      return message;
    }
    for (const emoji of emojis) {
      message = message.split(emoji.shortCode).join(emoji.convertedCode);
    }
    return message;
  }

  static convertToEmojiTxt(message: string) {
    const img = "<img src=${emoji.imgPath}></img>;";
    for (const emoji of emojis) {
      if (message.includes(emoji.convertedCode)) {
        const textToConvert = new RegExp(emoji.shortCode);
        message = message.replace(
          textToConvert,
          `<img src=${emoji.imgPath} alt=${emoji.shortCode}/> `
        );
      }
    }
    return message;
  }
}
