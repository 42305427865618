import { Component, OnInit, ViewChild } from "@angular/core";
import {
  IonInfiniteScroll,
  ActionSheetController,
  IonContent,
  PopoverController,
} from "@ionic/angular";
import { HelperService } from "../../services/helper.service";
import { PostService } from "../../services/post.service";
import { AuthService } from "../../services/auth.service";
import { Base } from "../../services/base";
import { LoaderService } from "../../services/loader.service";
import { PostForm } from "../models/post-form";
import { FeedOptionComponent } from "../component/feed-option/feed-option.component";
import { TransferService } from "src/services/transfer.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-feed",
  templateUrl: "./feed.component.html",
  styleUrls: ["./feed.component.scss"],
})
export class FeedComponent implements OnInit {
  //test: any;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  postForm = new PostForm();
  posts = [];
  base_url = Base.base_url;
  user_id = 0;
  pager = 0;
  load_more_data = false;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  isOnTop: boolean = true;
  imagePath: string = "";
  private subs: Subscription = new Subscription();

  constructor(
    public actionSheetCtrl: ActionSheetController,
    public popoverController: PopoverController,
    public helperService: HelperService,
    public postService: PostService,
    public authService: AuthService,
    public spinnerDialog: LoaderService,
    private transferService: TransferService
  ) {}

  ngOnInit() {
    this.user_id = this.authService.userID();
    this.loadPosts();
    this.getFeedDelete();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  getFeedDelete(): void {
    this.subs.add(
      this.transferService.getFeedDelete().subscribe((resp) => {
        this.posts = this.posts.filter((post) => post.id !== resp.id);
        this.helperService.showAlert("Story has been deleted!");
      })
    );
  }

  async openFeedOption(event, postId, postUserId) {
    const popover = await this.popoverController.create({
      component: FeedOptionComponent,
      event,
      cssClass: "feed-option",
      componentProps: { postId, postUserId },
    });

    await popover.present();
  }

  public selectPhoto(event) {
    const file = event.target.files[0];
    this.imagePath = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.postForm.files = reader.result;
    };
  }

  checkFeedScroll() {
    this.content.getScrollElement().then((elem) => {
      const scrollTop = elem.scrollTop;
      this.isOnTop = scrollTop > 0 ? false : true;
    });
  }

  scrollToTop() {
    this.content.scrollToTop(500);
  }

  public loadPosts() {
    // this.spinnerDialog.show("", "Loading Story...");
    this.postService.all(this.pager).subscribe(
      (resp) => {
        // this.spinnerDialog.hide();
        if (resp.posts.length <= 0) {
          this.infiniteScroll.disabled = true;
        } else {
          this.posts = this.posts.concat(resp.posts);
        }
        this.load_more_data = true;
      },
      (err) => {
        // this.spinnerDialog.hide();
      }
    );
  }

  public clearImageData() {
    this.imagePath = "";
    this.postForm.content = "";
  }

  public loadMorePosts(event) {
    this.pager += 1;
    this.loadPosts();
    if (this.load_more_data) {
      setTimeout(() => {
        event.target.complete();
      }, 1000);
      this.load_more_data = false;
    }
  }

  public postLike(post) {
    this.postService.like(post.id).subscribe(
      (resp) => {
        if (resp.liked) {
          post.votes_for.push({ voter_id: resp.user_id });
        } else {
          post.votes_for = post.votes_for.filter((obj) => {
            return obj.voter_id != this.user_id;
          });
        }
      },
      (err) => {}
    );
  }

  public isLiked(likes) {
    let liked = likes.filter((obj) => obj.voter_id == this.user_id);
    return liked.length > 0;
  }

  public submitPost() {
    this.postForm.content = this.postForm.content.trim();
    if (this.postForm.content.length > 0) {
      this.createPost();
    } else {
      this.helperService.showAlert("Post content can not be empty");
    }
  }

  public createPost() {
    this.spinnerDialog.show("", "Creating New Story...");
    this.postService.create(this.postForm).subscribe(
      (resp) => {
        if (resp.success) {
          this.spinnerDialog.hide();
          this.helperService.showToast("Post success!", "success");
          this.posts.unshift(JSON.parse(resp.post));
          this.postForm = {
            content: "",
            files: "",
          };
          this.imagePath = "";
          this.scrollToTop();
        } else {
          this.spinnerDialog.hide();
          this.helperService.showAlert(resp.errors);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
      }
    );
  }

  public postComments(post) {
    if (post.new_comments && post.new_comments != "") {
      this.postService.comment(post).subscribe((resp) => {
        post.new_comments = "";
        post.comments.unshift(JSON.parse(resp.comment));
      });
    }
  }

  public viewImage(src: string, title: string, description: string) {
    this.helperService.imageViewer(src, title, description);
  }
}
