import {Component, OnInit} from "@angular/core";
import {EventService} from "../../../services/event.service";
import {Router} from "@angular/router";
import {UserService} from "../../../services/user.service";
import {HelperService} from "../../../services/helper.service";
import {LoaderService} from "../../../services/loader.service";

@Component({
    selector: 'app-mentions',
    templateUrl: './mentions.component.html',
    styleUrls: ['./mentions.component.scss'],
})

export class MentionsComponent implements OnInit {
    mentions = [];
    constructor(
        public event: EventService,
        public router: Router,
        public userService: UserService,
        private helperService: HelperService,
        public spinner: LoaderService,
    ) {}
    ngOnInit() {
        this.loadMentions();
    }

    loadMentions() {
        this.userService.mentions().subscribe(
            resp => {
                console.log(resp.mentions);
                this.mentions = resp.mentions;
            }, err => {
                this.spinner.hide();
                this.helperService.showToast('Unable to load campaign data this moment!');
            }
        );
    }
}