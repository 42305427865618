import { Component, OnInit, ViewChild } from "@angular/core";
import { ChatRoomService } from "../../../services/chat-room.service";
import { HelperService } from "../../../services/helper.service";
import { Router } from "@angular/router";
import { IonSearchbar, ModalController } from "@ionic/angular";
import { EventService } from "../../../services/event.service";
import { LoaderService } from "../../../services/loader.service";

@Component({
  selector: "app-room-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class RoomSearchComponent implements OnInit {
  @ViewChild("searchBar") searchBar: IonSearchbar;
  rooms = [];
  search_q = "";
  results = "Type to search room";

  constructor(
    public roomService: ChatRoomService,
    public helperService: HelperService,
    private router: Router,
    public modalCtrl: ModalController,
    private eventService: EventService,
    private spinner: LoaderService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.searchBar.setFocus();
    }, 500);
  }

  public searchRoom() {
    if (this.search_q.length > 2) {
      this.spinner.show("", "Searching...");
      this.roomService.search(this.search_q).subscribe(
        (resp) => {
          this.spinner.hide();
          this.rooms = resp.chat_rooms;

          this.results = this.rooms.length === 0 ? "No chat room found" : "";
        },
        (err) => {
          this.spinner.hide();
          this.helperService.showToast("Unable to search chat rooms");
        }
      );
    } else {
      this.rooms = [];
    }
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  public gotoChat(room_id) {
    let active_rooms = this.helperService.rooms();
    let active_room = active_rooms.filter((room) => room.id == room_id);
    if (active_room.length > 0) {
      this.modalCtrl.dismiss();
      this.eventService.emitChatRoom("exist", active_room[0]);
    } else {
      this.spinner.show("", "Joining chat room...");
      this.roomService.joinChatRoom(room_id).subscribe(
        (resp) => {
          this.spinner.hide();
          if (resp.success) {
            this.helperService.addRoom(resp.chat_room);
            this.modalCtrl.dismiss();
          } else {
            this.helperService.showToast(resp.errors);
          }
        },
        (err) => {
          this.spinner.hide();
          this.helperService.showToast("Unable to load chat room this moment!");
        }
      );
    }
  }
}
