import { Component, OnInit } from "@angular/core";
import { HelperService } from "../../services/helper.service";
import { LoaderService } from "../../services/loader.service";
import { ModalController } from "@ionic/angular";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-progress-level",
  templateUrl: "./progress-level.component.html",
  styleUrls: ["./progress-level.component.scss"],
})
export class ProgressLevelComponent implements OnInit {
  level_data: any;
  levelImgPath: string;

  constructor(
    public helperService: HelperService,
    public spinner: LoaderService,
    private modalCtrl: ModalController,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loadLevel();
  }

  public loadLevel() {
    this.userService.levelProgress().subscribe(
      (resp) => {
        this.level_data = resp;
        this.levelImgPath = this.helperService.setLevelImagePath(resp.level);
      },
      (err) => {
        this.helperService.showToast(
          "Unable to show level progress this moment!",
          "error"
        );
      }
    );
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
