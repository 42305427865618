import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ProgressLevelComponent } from "../progress-level/progress-level.component";

@Component({
  selector: "app-explore",
  templateUrl: "./explore.component.html",
  styleUrls: ["./explore.component.scss"],
})
export class ExploreComponent implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  handleClick(title: string): void {
    if (title === "Level Progress") {
      this.modalLevel();
    }
  }

  async modalLevel() {
    const modal = await this.modalCtrl.create({
      component: ProgressLevelComponent,
      cssClass: "progress-level-modal",
    });
    modal.present();
  }

  items = [
    {
      title: "Announcement",
      url: "/announcements",
      imgUrl: "assets/images/Announcement.svg",
    },
    {
      title: "Gift Store",
      url: "/gift-stores",
      imgUrl: "assets/images/GiftStore.svg",
    },
    {
      title: "Level Progress",
      url: ".",
      imgUrl: "assets/images/Announcement.svg",
    },
    {
      title: "Leader Board",
      url: "/leader-board",
      imgUrl: "assets/images/LeaderBoard.svg",
    },
    {
      title: "Legend Campaign Leader Board",
      url: "/legend-campaign",
      imgUrl: "assets/images/Legend.svg",
    },
    {
      title: "People",
      url: "/explore/people",
      imgUrl: "assets/images/People.svg",
    },
    // {
    //   title: "Become a Merchant",
    //   url: ".",
    //   imgUrl: "assets/images/BecomeAMerchant.svg",
    // },
    {
      title: "Merchant Panel",
      url: "/merchant-panel",
      imgUrl: "assets/images/MerchantPanel.svg",
    },
    {
      title: "Command",
      url: "/commands",
      imgUrl: "assets/images/Command.svg",
    },
  ];
}
