import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { GiftDetailsComponent } from "./details/details.component";
import { GiftService } from "../../services/gift.service";
import { Base } from "../../services/base";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-gift-stores",
  templateUrl: "./gift-stores.component.html",
  styleUrls: ["./gift-stores.component.scss"],
})
export class GiftStoresComponent implements OnInit {
  gifts = [];
  all_gifts = [];
  term = "";
  results = "";
  base_url = Base.base_url;

  constructor(
    public ctrlModal: ModalController,
    public giftService: GiftService,
    private spinner: LoaderService
  ) {}

  ngOnInit() {
    this.loadGifts();
  }

  public loadGifts() {
    this.spinner.show("", "Loading gift store");
    this.giftService.gifts().subscribe(
      (resp) => {
        this.spinner.hide();
        this.gifts = resp.gifts;
        this.all_gifts = resp.gifts;
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  public giftDetails(gift) {
    this.ctrlModal
      .create({
        component: GiftDetailsComponent,
        cssClass: "no-fullscreen-modal",
        componentProps: {
          name: gift.name,
          credit: gift.credit,
          image: gift.image.url,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }

  public searchGift(searchbar) {
    this.gifts = this.all_gifts;
    this.initAllGift();
    let q = searchbar.srcElement.value;
    if (!q) {
      return;
    }
    this.gifts = this.gifts.filter((v) => {
      if (v.name && q) {
        return v.name.toLowerCase().indexOf(q.toLowerCase()) > -1;
      }
    });
    this.results = this.gifts.length === 0 ? "No gift found" : "";
  }

  public initAllGift() {
    this.gifts = this.all_gifts;
  }
}
