import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Base } from "./base";

@Injectable({
  providedIn: "root",
})
export class LeaderBoardService {
  apiUrl = Base.api_url;

  constructor(public http: HttpClient) {}

  public leaderBoardDetails(params: any): Observable<any> {
    return this.http.get(
      this.apiUrl + `/users/leader_board?type=${params.type}&official=${params.official}&is_weekly=${params.isWeekly}`,
      Base.requestHeader()
    );
  }
}
