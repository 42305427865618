import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { HelperService } from "src/services/helper.service";
import { LoaderService } from "src/services/loader.service";
import { TransferService } from "src/services/transfer.service";
import { UserService } from "src/services/user.service";

@Component({
  selector: "app-update-online-status",
  templateUrl: "./update-online-status.component.html",
  styleUrls: ["./update-online-status.component.scss"],
})
export class UpdateOnlineStatusComponent implements OnInit {
  statusOptions = [
    {
      text: "Appear as offline",
      param: "offline",
      bgColor: "#c8c8c8",
    },
    {
      text: "Online",
      param: "online",
      bgColor: "#4caf50",
    },
    {
      text: "Away",
      param: "away",
      bgColor: "#ffc107",
    },
    {
      text: "Busy",
      param: "busy",
      bgColor: "#f44336",
    },
  ];

  constructor(
    private userService: UserService,
    private helperService: HelperService,
    public spinnerDialog: LoaderService,
    private popoverController: PopoverController,
    private transferService: TransferService
  ) {}

  ngOnInit() {}

  updateStatus(appearance: string): void {
    this.dismissClick();
    this.spinnerDialog.show("", "Updating status...");
    this.userService.updateOnlineStatus(appearance).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        this.helperService.showAlert(resp.message);
        this.transferService.setStatusSubject(appearance);
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showAlert("Unable to update status!");
      }
    );
  }

  async dismissClick() {
    await this.popoverController.dismiss();
  }
}
