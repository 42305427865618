export const game_actions = [
    'show_character',
    'show_dmk_character',
    'show_wheel_number',
    'remove_cards',
    'show_join_button',
    'remove_start_button',
    'remove_join_button',
    'show_join_button',
    'show_start_button',
    'hide_wheel_number',
    'wheel',
    'user_drawn_card',
    'current_board_card',
    'player_serial',
    'user_cards',
    'hide_character',
    'head_or_tail_board',
    'remove_board',
    'head_and_tail',
    'head_and_tail_board',
    'hide_dmk_character',
    'played_card',
    'call_board',
    'hide_call_button',
    'show_call_button',
    'bridge_board_cards',
    'bridge_cards',
]

