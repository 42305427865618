import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { HelperService } from "src/services/helper.service";
import { PostService } from "src/services/post.service";
import { TransferService } from "src/services/transfer.service";

@Component({
  selector: "app-feed-option",
  templateUrl: "./feed-option.component.html",
  styleUrls: ["./feed-option.component.scss"],
})
export class FeedOptionComponent implements OnInit {
  @Input() postId: number;
  @Input() postUserId: number;
  userId = JSON.parse(localStorage.getItem("auth")).user_id;

  constructor(
    private popOverController: PopoverController,
    private postService: PostService,
    private helperService: HelperService,
    private transferService: TransferService
  ) {}

  ngOnInit() {}

  deletePost(): void {
    this.popOverController.dismiss();
    this.postService.delete(this.postId).subscribe(
      (resp) => {
        this.transferService.setFeedDelete(this.postId);
      },
      (err) => {
        this.helperService.showAlert("Sorry, story can't be deleted!");
      }
    );
  }

  reportPost(): void {
    this.popOverController.dismiss();
    this.postService.report(null).subscribe(
      (resp) => {
        this.helperService.showAlert(
          "You have successfully reported this story!"
        );
      },
      (err) => {
        this.helperService.showAlert("Sorry, story can't be reported!");
      }
    );
  }
}
