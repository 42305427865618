import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Base} from './base';

@Injectable({
    providedIn: 'root'
})

export class RatingService {
    api_url = Base.api_url;

    constructor(public http: HttpClient) {}

    public new(user_id): Observable<any> {
      return this.http.get(this.api_url + '/reviews/new?reviewable_type=User&reviewable_id=' + user_id, this.requestHeader());
    }

    public index(user_id): Observable<any> {
        return this.http.get(this.api_url + '/reviews?reviewable_type=User&reviewable_id=' + user_id, this.requestHeader());
    }

    public create(data): Observable<any> {
        return this.http.post(this.api_url + '/reviews', {review: data}, this.requestHeader());
    }

    private requestHeader() {
        return {headers: {Authorization: `Bearer ${Base.getAuthToken()}`}};
    }
}
