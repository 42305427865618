import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { HelperService } from "../../../services/helper.service";
import { AlertController } from "@ionic/angular";
import { LoaderService } from "../../../services/loader.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class FriendListComponent implements OnInit {
  friends = [];

  constructor(
    public usersProvider: UserService,
    public helperService: HelperService,
    public spinnerDialog: LoaderService,
    public alertCtrl: AlertController,
    public router: Router
  ) {}

  ngOnInit() {
    this.loadFriend();
  }

  public loadFriend() {
    this.usersProvider.friendList().subscribe(
      (resp) => {
        this.friends = resp.friends;
      },
      (err) => {
        this.helperService.showToast("Unable to load friend list");
      }
    );
  }

  async blockFriend(user) {
    const confirm = await this.alertCtrl.create({
      header: "Block Friend Request",
      message: "Block friendship of " + (user.name || user.username),
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Send",
          handler: () => {
            this.sendBlockFriend(user);
          },
        },
      ],
    });
    confirm.present();
  }

  public gotoProfile(user_id) {
    this.router.navigateByUrl("/profile/" + user_id);
  }

  public sendBlockFriend(user) {
    this.usersProvider.blockFriendship(user.id).subscribe(
      (resp) => {
        if (resp.success) {
          this.helperService.showToast("Block friend!");
          this.removeFromList(user);
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to block friend!");
      }
    );
  }

  async removeFriend(user) {
    const confirm = await this.alertCtrl.create({
      header: "Remove Friend",
      message: "Remove friendship of " + (user.name || user.username),
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Send",
          handler: () => {
            this.sendRemoveFriend(user);
          },
        },
      ],
    });
    confirm.present();
  }

  public sendRemoveFriend(user) {
    this.usersProvider.removeFriendship(user.id).subscribe(
      (resp) => {
        if (resp.success) {
          this.helperService.showToast("Friend removed!");
          this.removeFromList(user);
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to remove friend!");
      }
    );
  }

  public removeFromList(user) {
    let index = this.friends.indexOf(user);
    if (index !== -1) {
      this.friends.splice(index, 1);
    }
  }

  public viewImage(src: string, title: string, description: string) {
    this.helperService.imageViewer(src, title, "User profile photo");
  }

  public profilePicture(profile_photo) {
    if (profile_photo && profile_photo.url) {
      return profile_photo.small.url;
    } else {
      return "assets/images/profile.png";
    }
  }
}
