import { Component, OnInit } from "@angular/core";
import { ModalController, AlertController } from "@ionic/angular";
import { ChatRoomService } from "../../services/chat-room.service";
import { HelperService } from "../../services/helper.service";
import { Router } from "@angular/router";
import { EventService } from "../../services/event.service";
import { RoomSearchComponent } from "./search/search.component";
import { CreateRoomComponent } from "./create-room/create-room.component";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-chat-rooms",
  templateUrl: "./chat-rooms.component.html",
  styleUrls: ["./chat-rooms.component.scss"],
})
export class ChatRoomsComponent implements OnInit {
  ownRooms = [];
  recentRooms = [];
  officialRooms = [];
  randomRooms = [];
  gameRooms = [];

  constructor(
    public alertCtrl: AlertController,
    public roomService: ChatRoomService,
    public helperService: HelperService,
    public spinnerDialog: LoaderService,
    private eventService: EventService,
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.loadRooms();
  }

  public loadRooms() {
    this.roomService.rooms().subscribe((resp) => {
      if (resp.success) {
        this.ownRooms = resp.own_rooms;
        this.recentRooms = resp.recent;
        this.officialRooms = resp.official;
        this.randomRooms = resp.random;
        this.gameRooms = resp.game;
      }
    });
  }

  public reloadRooms(category) {
    this.spinnerDialog.show("", "Loading...");
    this.roomService.categoryRooms(category).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.reloadResults(category, resp[category]);
        } else {
          this.helperService.showAlert(resp.errors);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
      }
    );
  }

  async createChatRoom() {
    const modal = await this.modalCtrl.create({
      component: CreateRoomComponent,
      componentProps: {},
      cssClass: "create-room-modal",
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data.chat_room) {
      this.ownRooms.push(data.chat_room);
    }
  }

  public searchRoom() {
    this.modalCtrl
      .create({
        component: RoomSearchComponent,
      })
      .then((modal) => {
        modal.present();
      });
  }

  public gotoChat(room_id) {
    let active_rooms = this.helperService.rooms();
    let active_room = active_rooms.filter((room) => room.id == room_id);
    if (active_room.length > 0) {
      this.eventService.emitChatRoom("exist", active_room[0]);
    } else {
      this.spinnerDialog.show("", "Joining chat room...");
      this.roomService.joinChatRoom(room_id).subscribe(
        (resp) => {
          this.spinnerDialog.hide();
          if (resp.success) {
            this.helperService.addRoom(resp.chat_room);
          } else {
            this.helperService.showToast(resp.errors);
          }
        },
        (err) => {
          this.spinnerDialog.hide();
          this.helperService.showToast("Unable to load chat room this moment!");
        }
      );
    }
  }

  private reloadResults(category, result) {
    switch (category) {
      case "game": {
        this.gameRooms = result;
        break;
      }
      case "official": {
        this.officialRooms = result;
        break;
      }
      case "recent": {
        this.recentRooms = result;
        break;
      }
      case "random": {
        this.randomRooms = result;
        break;
      }

      case "own_rooms": {
        this.ownRooms = result;
        break;
      }
    }
  }
}
