import { Component, NgZone, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import {
  ActionSheetController,
  AlertController,
  ModalController,
} from "@ionic/angular";
import { Camera } from "@ionic-native/camera/ngx";
import { Router } from "@angular/router";
import { HelperService } from "../../services/helper.service";
import { UserService } from "../../services/user.service";
import { SecurityQuestionComponent } from "./security-question/security-question.component";
import { LoaderService } from "../../services/loader.service";
import { BlockListComponent } from "./block-list/block-list.component";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  user: any;
  online_status = true;
  notification = true;
  app_version = "0.0.1";

  constructor(
    public authProvider: AuthService,
    public alertCtrl: AlertController,
    public camera: Camera,
    public router: Router,
    public actionSheetCtrl: ActionSheetController,
    private helperService: HelperService,
    public userService: UserService,
    private spinner: LoaderService,
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.appVersion();
    this.spinner.show("", "Load settings...");
    this.authProvider.getCurrentUser().subscribe(
      (resp) => {
        this.spinner.hide();
        this.user = resp.profile;
        this.user.cover = "/assets/images/cover.png";
        // this.getProfilePic();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  public notificationStatus() {}

  public onlineStatus() {}

  public appVersion() {
    this.userService.appSettings().subscribe(
      (resp) => {
        this.app_version = resp.app_version;
      },
      (err) => {}
    );
  }

  // The currentPassword is first checked, after which the new password should be entered twice.
  // Uses password validator from Validator.ts.
  async setPassword() {
    const alert = await this.alertCtrl.create({
      header: "Change Password",
      message: "Enter Password",
      mode: "ios",
      inputs: [
        {
          name: "current_password",
          placeholder: "Enter Current Password",
          type: "password",
        },
        {
          name: "new_password",
          placeholder: "New Password",
          type: "password",
        },
        {
          name: "confirm_password",
          placeholder: "Confirm New Password",
          type: "password",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          handler: (data) => {},
        },
        {
          text: "Save",
          handler: (data) => {
            let current_password = data["current_password"];
            let new_password = data["new_password"];
            let confirm_password = data["confirm_password"];
            if (new_password == confirm_password) {
              if (new_password.length >= 6) {
                let data = {
                  current_password: current_password,
                  password: new_password,
                  password_confirmation: confirm_password,
                };
                this.authProvider.changePassword(data).subscribe(
                  (resp) => {
                    if (resp.success) {
                      this.helperService.showToast(
                        "Change password successfully. Please login in.",
                        "success"
                      );
                      setTimeout(() => {
                        this.authProvider.logout();
                      }, 1500);
                    } else {
                      this.helperService.showToast(resp.message);
                    }
                  },
                  (err) => {
                    this.helperService.showToast(
                      "Unable to change password, Please try again!"
                    );
                  }
                );
              } else {
                this.helperService.showToast(
                  "Password must be at least 6 characters"
                );
              }
            } else {
              this.helperService.showToast("Confirm password doesn't match");
            }
          },
        },
      ],
    });
    await alert.present();
  }

  // Change user's email. Uses Validator.ts to validate the entered email. After, update the userData on database.
  // When the user changed their email, they have to confirm the new email address.
  async setEmail(email) {
    const alert = await this.alertCtrl.create({
      header: "Change Email",
      message:
        "Sent you an email to <b>" +
        email +
        "</b>! Please check and find the token.",
      mode: "ios",
      inputs: [
        {
          name: "email",
          placeholder: "New email address",
        },
        {
          name: "token",
          placeholder: "Change email token",
        },
      ],

      buttons: [
        {
          text: "Cancel",
          handler: (data) => {},
        },
        {
          text: "Save",
          handler: (data) => {
            let email = data["email"];
            let token = data["token"];
            this.authProvider.changeEmail(email, token).subscribe(
              (resp) => {
                if (resp.success) {
                  this.helperService.showToast(resp.message);
                  this.logOutAndRedirect(email);
                } else {
                  this.helperService.showAlert(resp.message);
                }
              },
              (err) => {
                this.helperService.showToast(
                  "Unable to change email. Please try again!"
                );
              }
            );
            return false;
          },
        },
      ],
    });
    await alert.present();
  }

  async sendTokenEmail() {
    const alert = await this.alertCtrl.create({
      header: "Change Email",
      message:
        "We will send you an verification email to your current email address! Please check and find the verification token.",
      mode: "ios",
      buttons: [
        {
          text: "Cancel",
          handler: (data) => {},
        },
        {
          text: "Send",
          handler: (data) => {
            this.changeEmailToken();
          },
        },
      ],
    });
    await alert.present();
  }

  public changeEmailToken() {
    this.spinner.show("", "Sending email...");
    this.authProvider.changeEmailToken().subscribe(
      (resp) => {
        this.setEmail(resp.email);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.helperService.showToast("Unable to change email this moment!");
      }
    );
  }

  async logOutAndRedirect(new_email) {
    this.authProvider.logout().subscribe(
      (resp) => {
        this.helperService.showToast(
          "Email has been changed. Please confirm your account.",
          "success"
        );
        window.localStorage.removeItem("auth");
        window.location.href = "/account-activation?email=" + new_email;
      },
      (err) => {
        this.helperService.showAlert(
          "Email has been changed! Logout and confirm account"
        );
      }
    );
  }

  async setPin() {
    const alert = await this.alertCtrl.create({
      header: "Change Pin",
      message:
        "Your pin should be exactly 6 digits. If you are setting pin for the first time, use 123456 as old pin.",
      mode: "ios",
      inputs: [
        {
          name: "old_pin",
          placeholder: "Old pin",
          type: "number",
        },
        {
          name: "new_pin",
          placeholder: "New pin",
          type: "number",
        },
        {
          name: "confirm_pin",
          placeholder: "Confirm pin",
          type: "number",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          handler: (data) => {},
        },
        {
          text: "Change",
          handler: (data) => {
            let pin_data = {
              old_pin: data["old_pin"],
              new_pin: data["new_pin"],
              confirm_pin: data["confirm_pin"],
            };
            if (
              pin_data.new_pin.length != 6 ||
              !Number.isInteger(+pin_data.new_pin)
            ) {
              this.helperService.showAlert(
                "Pin number should be exactly 6 digits number"
              );
              return false;
            }
            this.authProvider.changePin(pin_data).subscribe(
              (resp) => {
                if (resp.success) {
                  this.helperService.showToast(resp.message, "success");
                  alert.dismiss();
                } else {
                  this.helperService.showAlert(resp.message);
                }
              },
              (err) => {
                this.helperService.showToast(
                  "Unable to change pin. Please try again!"
                );
              }
            );
            return false;
          },
        },
      ],
    });
    await alert.present();
  }

  async setSecurityQuestion() {
    const modal = await this.modalCtrl.create({
      component: SecurityQuestionComponent,
      componentProps: {},
    });
    await modal.present();
  }

  async blockList() {
    const modal = await this.modalCtrl.create({
      component: BlockListComponent,
      componentProps: {},
    });
    await modal.present();
  }
}
