import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-gift-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class GiftDetailsComponent implements OnInit {
  @Input() name: string;
  @Input() credit: string;
  @Input() image: string;

  constructor(public modalCtrl: ModalController) {}

  ngOnInit() {}

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
