import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Base } from "./base";

@Injectable({
  providedIn: "root",
})
export class GiftService {
  api_url = Base.api_url;

  constructor(public http: HttpClient) {}

  public gifts(): Observable<any> {
    return this.http.get(this.api_url + "/gift_stores");
  }
}
