import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  ModalController,
  AlertController,
  ToastController,
} from "@ionic/angular";
import { UserService } from "../../services/user.service";
import { HelperService } from "../../services/helper.service";
import { RatingComponent } from "./rating/rating.component";
import { RatingService } from "../../services/rating.service";
import { LoaderService } from "../../services/loader.service";
import { ChatRoomService } from "../../services/chat-room.service";
import { EventService } from "../../services/event.service";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  user: any;
  friend = false;
  friends = 0;
  sent_request = false;
  pending = false;
  profile_picture: any;
  total_gifts = 0;
  recent_activities = [];
  balance = 0;
  posts = 0;
  edit_rating = false;

  constructor(
    public usersProvider: UserService,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public helperService: HelperService,
    public spinnerDialog: LoaderService,
    public router: Router,
    public rateService: RatingService,
    public route: ActivatedRoute,
    public eventService: EventService,
    private userService: UserService,
    public chatRoom: ChatRoomService
  ) {
    this.route.params.subscribe((params) => {
      this.loadUserProfile(params["id"]);
    });
  }

  ngOnInit() {}

  public loadUserProfile(user_id) {
    this.spinnerDialog.show("Profile", "Loading...");
    this.usersProvider.userProfile(user_id).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.user = resp.profile;
          this.friend = resp.friend;
          this.total_gifts =
            this.user.gifts > 1000
              ? (this.user.gifts / 1000).toFixed(2) + "K"
              : this.user.gifts;
          this.friends = resp.friends;
          this.sent_request = resp.sent_request;
          this.pending = resp.pending;
          this.balance =
            resp.balance > 1000
              ? (resp.balance / 1000).toFixed(1) + "K"
              : resp.balance;
          this.posts = resp.posts;
          this.checkRating();
          this.loadRecentActivity();
        } else {
          this.helperService.showToast(resp.errors);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to load user profile");
      }
    );
  }

  // send friend request to the user
  async sendRequest(user) {
    const confirm = await this.alertCtrl.create({
      header: "Send Friend Request",
      message: "The Request will be send to " + (user.name || user.username),
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Send",
          handler: () => {
            this.send(user);
          },
        },
      ],
    });
    confirm.present();
  }

  async cancelRequest(user) {
    const confirm = await this.alertCtrl.create({
      header: "Cancel Friend Request",
      message: "The Request will cancel " + (user.name || user.username),
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Send",
          handler: () => {
            this.cancelFriendRequest(user);
          },
        },
      ],
    });
    confirm.present();
  }

  async blockUser(user) {
    const confirm = await this.alertCtrl.create({
      header: "Block User",
      message:
        "Are you sure want to block <b>" +
        (user.name || user.username) +
        "</b>?",
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Send",
          handler: () => {
            this.sendBlockFriend(user);
          },
        },
      ],
    });
    confirm.present();
  }

  public gotoProfile(user_id) {
    this.router.navigateByUrl("/profile/" + user_id);
  }

  public sendBlockFriend(user) {
    this.usersProvider.blockFriendship(user.id).subscribe(
      (resp) => {
        if (resp.success) {
          this.helperService.showToast("Block successfully!", "success");
          this.router.navigateByUrl("/");
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to block this moment!");
      }
    );
  }

  public userRole() {
    if (this.user.role == "super_admin" || this.user.role == "super_admin") {
      return "A";
    } else if (this.user.role == "merchant") {
      return "M";
    } else {
      return "U";
    }
  }

  async writeReview() {
    const modal = await this.modalCtrl.create({
      component: RatingComponent,
      cssClass: "review-modal",
      componentProps: {
        form: true,
        user_id: this.user.id,
        username: this.user.username,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data.score) {
      this.user.score = data.score;
    }
  }

  async detailsReview() {
    const modal = await this.modalCtrl.create({
      component: RatingComponent,
      componentProps: {
        form: false,
        user_id: this.user.id,
        username: this.user.username,
      },
    });
    await modal.present();
  }

  async removeFriend(user) {
    const confirm = await this.alertCtrl.create({
      header: "Remove Friend",
      message: "Remove friendship of " + (user.name || user.username),
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Send",
          handler: () => {
            this.sendRemoveFriend(user);
          },
        },
      ],
    });
    confirm.present();
  }

  public loadRecentActivity() {
    this.userService.recentActivity(this.user.id).subscribe(
      (resp) => {
        this.recent_activities = resp.activities;
      },
      (err) => {
        this.helperService.showToast(
          "Unable to load recent activity this moment!"
        );
      }
    );
  }

  public sendRemoveFriend(user) {
    this.usersProvider.removeFriendship(user.id).subscribe(
      (resp) => {
        if (resp.success) {
          this.helperService.showToast("Friend removed!");
          this.friend = false;
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to remove friend!");
      }
    );
  }

  // send friend request to the user
  async send(user) {
    this.spinnerDialog.show("Friend Request", "Sending...", false);
    this.usersProvider.sendRequest(user.id).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.helperService.showAlert("Friend request sent successfully");
          this.pending = true;
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showToast(
          "Unable to send friend request. Please try again!"
        );
      }
    );
  }

  // cancel friend request

  async cancelFriendRequest(user) {
    this.spinnerDialog.show("Friend Request", "Cancelling...", false);
    this.usersProvider.cancelFriendRequest(user.id).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.helperService.showAlert("Friend request cancel successfully");
          this.pending = false;
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showToast(
          "Unable to cancel friend request. Please try again!"
        );
      }
    );
  }

  async rejectRequest(user) {
    const confirm = await this.alertCtrl.create({
      header: "Decline Friend Request",
      message: "Decline friend request from " + (user.name || user.username),
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Send",
          handler: () => {
            this.sendRejectRequest(user);
          },
        },
      ],
    });
    confirm.present();
  }

  public sendRejectRequest(user) {
    this.usersProvider.rejectRequest(user.id).subscribe(
      (resp) => {
        if (resp.success) {
          this.helperService.showToast("Friend declined!");
          this.sent_request = false;
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to decline friend request!");
      }
    );
  }

  async acceptRequest(user) {
    const confirm = await this.alertCtrl.create({
      header: "Accept Friend Request",
      message: "Accept friend request from " + (user.name || user.username),
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Send",
          handler: () => {
            this.sendAcceptRequest(user);
          },
        },
      ],
    });
    confirm.present();
  }

  public sendAcceptRequest(user) {
    this.usersProvider.acceptRequest(user.id).subscribe(
      (resp) => {
        if (resp.success) {
          this.eventService.friends.next(user);
          this.helperService.showToast("Friend request accepted!", "success");
          this.friend = true;
          this.sent_request = false;
        } else {
          this.helperService.showToast(resp.message);
        }
      },
      (err) => {
        this.helperService.showToast("Unable to accept request!");
      }
    );
  }

  public checkRating() {
    this.rateService.new(this.user.id).subscribe(
      (resp) => {
        this.edit_rating = !!resp.review.id;
      },
      (err) => {}
    );
  }

  viewImage(src: string, title: string, description: string) {
    this.helperService.imageViewer(src, title, description);
  }

  async goChat() {
    this.spinnerDialog.show("", "Starting chat...");
    this.chatRoom.personal_chat(this.user.username).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        this.router.navigateByUrl("/");
        if (resp.success) {
          let active_rooms = this.helperService.rooms();
          let active_room = active_rooms.filter(
            (room) => room.id == resp.chat_room.id
          );
          if (active_room.length > 0) {
            this.eventService.emitChatRoom("exist", active_room[0]);
          } else {
            this.helperService.addRoom(resp.chat_room);
          }
        } else {
          this.helperService.showToast(resp.errors);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
        this.helperService.showToast("Unable to create chat this moment");
      }
    );
  }
}
