import { Component, OnInit, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import {
  ModalController,
  ActionSheetController,
  AlertController,
} from "@ionic/angular";
import {
  Camera,
  CameraOptions,
  PictureSourceType,
} from "@ionic-native/camera/ngx";
import { AuthService } from "../../services/auth.service";
import { HelperService } from "../../services/helper.service";
import { EventService } from "../../services/event.service";
import { UserService } from "../../services/user.service";
import { RatingComponent } from "../user-profile/rating/rating.component";
import { LoaderService } from "../../services/loader.service";
import { ProgressLevelComponent } from "../progress-level/progress-level.component";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  public user: any;
  myNotify = true;
  chatSound = true;
  showOnline: any = false;
  status = true;
  profile_picture: any;
  total_gifts = 0;
  recent_activities = [];
  balance = 0;
  posts = 0;
  friends = 0;
  levelImgPath: string = "";

  constructor(
    public ngZone: NgZone,
    public authProvider: AuthService,
    public alertCtrl: AlertController,
    public camera: Camera,
    public router: Router,
    public actionSheetCtrl: ActionSheetController,
    private helperService: HelperService,
    public spinnerDialog: LoaderService,
    public eventService: EventService,
    private userService: UserService,
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.spinnerDialog.show("My Profile", "Loading...");
    this.authProvider.getCurrentUser().subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.user = resp.profile;
          this.levelImgPath = this.helperService.setLevelImagePath(
            this.user.level
          );
          this.total_gifts =
            this.user.gifts > 1000
              ? (this.user.gifts / 1000).toFixed(2) + "K"
              : this.user.gifts;
          this.friends = resp.friends;
          this.posts = resp.posts;
          this.balance =
            resp.balance > 1000
              ? (resp.balance / 1000).toFixed(1) + "K"
              : resp.balance;
          this.userWatcher();
          this.loadRecentActivity();
        } else {
          this.helperService.showToast("Unable to load user profile");
        }
      },
      (err) => {
        this.spinnerDialog.hide();
      }
    );
  }

  async modalLevel() {
    const modal = await this.modalCtrl.create({
      component: ProgressLevelComponent,
      cssClass: "progress-level-modal",
    });
    modal.present();
  }

  ionViewDidEnter() {}

  // Upload user profile picture, the user picture is store in firebase storage with user id
  // The profile is updated in user profile list in firebase
  async getProfilePicture(sourceType: PictureSourceType) {
    try {
      const options: CameraOptions = {
        quality: 100,
        targetWidth: 400,
        targetHeight: 400,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        correctOrientation: true,
        sourceType: sourceType,
        saveToPhotoAlbum: false,
      };
      const result = await this.camera.getPicture(options);
      const image = "data:image/jpeg;base64," + result;
      let data = { base64_file: image };
      this.spinnerDialog.show("", "Uploading profile picture...");
      this.authProvider.updateProfile(data).subscribe(
        (resp) => {
          if (resp.success) {
            this.spinnerDialog.hide();
            this.helperService.showToast(
              "Profile picture changes successfully",
              "success"
            );
            this.user.profile_picture = resp.user.profile_picture;
            this.eventService.emitUpdateUser(resp.user);
          } else {
            this.helperService.showToast(resp.message);
          }
        },
        (err) => {
          this.helperService.showToast("Unable to update profile picture");
        }
      );
    } catch (e) {
      this.helperService.showToast(
        "Unable to get photo from camera or gallery"
      );
    }
  }

  async getCoverPicture(sourceType: PictureSourceType) {
    try {
      const options: CameraOptions = {
        quality: 100,
        targetWidth: 400,
        targetHeight: 400,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        correctOrientation: true,
        sourceType: sourceType,
        saveToPhotoAlbum: false,
      };
      const result = await this.camera.getPicture(options);
      const image = "data:image/jpeg;base64," + result;
      let data = { base64_cover_photo: image };
      this.spinnerDialog.show("", "Uploading cover photo...");
      this.authProvider.updateProfile(data).subscribe(
        (resp) => {
          if (resp.success) {
            this.spinnerDialog.hide();
            this.helperService.showToast(
              "Cover photo changes successfully",
              "success"
            );
            this.user.cover_photo = resp.user.cover_photo;
            this.eventService.emitUpdateUser(resp.user);
          } else {
            this.helperService.showToast(resp.message);
          }
        },
        (err) => {
          this.helperService.showToast("Unable to update cover photo");
        }
      );
    } catch (e) {
      this.helperService.showToast(
        "Unable to get photo from camera or gallery"
      );
    }
  }

  // upload user profile picture
  // The user has two option to select using camera and Gallery
  async proPic() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: "Select Profile Photo",
      buttons: [
        {
          text: "From Camera",
          icon: "camera",
          role: "destructive",
          handler: () => {
            this.getProfilePicture(this.camera.PictureSourceType.CAMERA);
          },
        },
        {
          text: "From Gallery",
          icon: "albums",
          handler: () => {
            this.getProfilePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          },
        },
        {
          text: "Cancel",
          icon: "close",
          role: "cancel",
          handler: () => {},
        },
      ],
    });
    actionSheet.present();
  }

  setImageType(type: string): string {
    let fileName: string;

    if (
      ["friend", "transfer", "send_gift", "moderator", "level_up"].includes(
        type
      )
    ) {
      fileName = type;
    } else if (["received_gift", "tag_trail"].includes(type)) {
      fileName = "received_gift";
    } else if (["remove_merchant", "reject_merchant"].includes(type)) {
      fileName = "merchant";
    } else if (type === "game") {
      fileName = "AllGames";
    } else {
      fileName = "alerts";
    }

    return `/assets/images/${fileName}.svg`;
  }

  extractImgUrl(data): void {
    for (const item of data) {
      const text = item.text;
      const imgTxt = text.substring(
        text.lastIndexOf("<img"),
        text.lastIndexOf(">") + 1
      );
      item.text = text.replace(imgTxt, "").trim();
      const imgUrl = imgTxt.substring(
        text.lastIndexOf("https://"),
        text.indexOf("' ")
      );
      item.imgUrl = imgUrl;
      item.altImgUrl = this.setImageType(item.type);
    }

    return data;
  }

  public loadRecentActivity() {
    this.userService.recentActivity(this.user.id).subscribe(
      (resp) => {
        const { activities } = resp;
        this.recent_activities = this.recent_activities.concat(
          this.extractImgUrl(activities)
        );
      },
      (err) => {
        this.helperService.showToast(
          "Unable to load recent activity this moment!"
        );
      }
    );
  }

  public changeProfilePic() {
    this.proPic();
  }

  public userWatcher() {
    this.eventService.updateUser.subscribe((user) => {
      this.user = user;
    });
  }

  public userRole() {
    if (this.user.role === "super_admin") {
      return "S";
    } else if (this.user.role === "admin") {
      return "A";
    } else if (this.user.role === "mentor") {
      return "M";
    } else if (this.user.role === "merchant") {
      return "B";
    } else {
      return "U";
    }
  }

  async coverPic() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: "Select Cover Photo",
      cssClass: "action-sheets-basic-page",
      buttons: [
        {
          text: "From Camera",
          icon: "camera",
          role: "destructive",
          handler: () => {
            this.getCoverPicture(this.camera.PictureSourceType.CAMERA);
          },
        },
        {
          text: "From Gallery",
          icon: "albums",
          handler: () => {
            this.getCoverPicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          },
        },
        {
          text: "Cancel",
          icon: "close",
          role: "cancel",
          handler: () => {},
        },
      ],
    });
    actionSheet.present();
  }

  editProfile() {
    this.router.navigateByUrl("edit-profile");
  }

  async detailsReview() {
    const modal = await this.modalCtrl.create({
      component: RatingComponent,
      componentProps: {
        form: false,
        user_id: this.user.id,
        username: this.user.username,
      },
    });
    await modal.present();
  }

  // view image in large form in modal component
  viewImage(src: string, title: string, description: string) {
    this.helperService.imageViewer(src, title, description);
  }
}
