import {Pipe, PipeTransform, NgZone, ChangeDetectorRef } from '@angular/core';
@Pipe({
    name: 'hashtag',
    pure: false
})
export class HashTagPipe implements PipeTransform {
    constructor() {}
    transform(value: string) {
        let parse_content = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        parse_content = parse_content.replace(/#(\w+)/g, `<span class="hashtag">#$1</span>`);
        return parse_content;
    }
}
