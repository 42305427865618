import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../services/email.service';
import {HelperService} from '../../../services/helper.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {LoaderService} from '../../../services/loader.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.scss'],
})
export class ComposeComponent implements OnInit {
  email = {to: '', subject: '', body: '', base64_file: ''};
  user_id = '';

  constructor(public emailService: EmailService, public helperService: HelperService, public spinner: LoaderService,
              public route: ActivatedRoute, public router: Router, public camera: Camera, private auth: AuthService) {
    this.user_id = auth.userID();
  }

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      if(params['email_id']) {
        this.loadEmail(params['email_id'], params['reply']);
      }
      else if(params['username']) {
        this.email.to = params['username'];
      }
    });
  }

  public send() {
    this.spinner.show('', 'Sending email...');
    this.emailService.send(this.email).subscribe(resp => {
      this.spinner.hide();
      if(resp.success) {
        this.email.to = '';
        this.email.subject = '';
        this.email.body = '';
        this.email.base64_file = '';
        this.helperService.showToast('Sent email successfully', 'success');
        this.router.navigateByUrl('/');
      }
      else {
       this.helperService.showToast(resp.errors);
      }
    }, err => {
      this.spinner.hide();
      this.helperService.showToast('Unable to send email');
    });
  }

  public sendEmail() {
    if(this.email.to == '') {
      this.helperService.showToast('Username is required');
      return false;
    }

    if(this.email.subject == '') {
      this.helperService.showToast('Email subject is required');
      return false;
    }

    if(this.email.body == '') {
      this.helperService.showToast('Email body is required');
      return false;
    }
    this.send();
  }

  async getAttachment() {
    try {
      const options: CameraOptions = {
        quality: 100,
        targetWidth: 400,
        targetHeight: 400,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        correctOrientation: true,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        saveToPhotoAlbum: false,
      };
      const result = await this.camera.getPicture(options);
      this.email.base64_file = 'data:image/jpeg;base64,' + result;
    } catch (e) {
      this.helperService.showToast('Unable to get photo from gallery');
    }
  }

  public loadEmail(id, reply) {
    this.spinner.show('', 'Loading email...');
    this.emailService.show(id).subscribe(resp => {
      this.spinner.hide();
      if(reply === '1') {
        this.email.to = (resp.email.receiver_id === this.user_id) ? resp.email.sender.username : resp.email.receiver.username;
      }
      else {
        this.email.body = resp.email.body;
      }
      this.email.subject = resp.email.subject;
    }, err => {
      this.spinner.hide();
      this.helperService.showToast('Unable to load email');
    });
  }
}

