import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LeaderBoardService } from "src/services/leader-board.service";
import { LoaderService } from "src/services/loader.service";

@Component({
  selector: "app-leader-board-details",
  templateUrl: "./leader-board-details.component.html",
  styleUrls: ["./leader-board-details.component.scss"],
})
export class LeaderBoardDetailsComponent implements OnInit {
  leaderBoards = [];
  routeState = {
    imgUrl: "",
    title: "",
    room: "",
  };

  constructor(
    private route: ActivatedRoute,
    private leaderBoardService: LeaderBoardService,
    private spinner: LoaderService
  ) {}

  ngOnInit() {
    this.subscribeToQueryParams();
    this.getSetRouteState();
  }

  subscribeToQueryParams(): void {
    this.route.queryParams.subscribe((params) => {
      this.getLeaderBoardDetails(params);
    });
  }

  getSetRouteState() {
    if (history.state.imgUrl && history.state.title) {
      localStorage.setItem("routeState", JSON.stringify(history.state));
    }
    this.routeState = JSON.parse(localStorage.getItem("routeState"));
  }

  getLeaderBoardDetails(params: any): void {
    this.spinner.show("", "Loading leaderboard...");
    this.leaderBoardService.leaderBoardDetails(params).subscribe(
      (resp) => {
        this.spinner.hide();
        this.leaderBoards = resp.leader_board;
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  ngOnDestroy() {
    localStorage.removeItem("routeState");
  }
}
