import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { HelperService } from "../../services/helper.service";
import { EventService } from "../../services/event.service";
import { UserService } from "../../services/user.service";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { ChatRoomService } from "../../services/chat-room.service";
import { LoaderService } from "src/services/loader.service";
import { Subscription } from "rxjs";
import { TransferService } from "src/services/transfer.service";

@Component({
  selector: "app-chat-layout",
  templateUrl: "./chat-layout.component.html",
  styleUrls: ["./chat-layout.component.scss"],
  providers: [Keyboard],
})
export class ChatLayoutComponent implements OnInit, AfterViewInit {
  private subs: Subscription = new Subscription();
  @ViewChild("tabSlider") slider: any | ElementRef;
  active_slider = 0;
  currentIndex = 0;
  totalTabs = 2;
  chat_rooms = [];
  has_notification = false;
  didInit = false;
  notifications = [];
  slideOpts = {
    initialSlide: 0,
    scrollbar: true,
    pager: false,
    noSwipingClass: "room-footer-actions",
    threshold: 20,
  };

  constructor(
    public helperService: HelperService,
    public eventService: EventService,
    public userService: UserService,
    public transferService: TransferService,
    private keyboard: Keyboard,
    public chatRoomService: ChatRoomService,
    public spinnerDialog: LoaderService
  ) {}

  ngOnInit() {
    this.switchChatTabByArrows();
    this.loadRooms();

    this.eventService.chatRoomDispatcher.subscribe((data) => {
      setTimeout(() => {
        if (data.type === "new") {
          this.addNewRoom(data.room);
        } else if (data.type === "remove") {
          this.removeRoom(data.room);
        }
        this.slider.length().then((index) => {
          if (data.type === "remove") {
            let { removeIndex } = data.room;
            index -= 1;
            if (removeIndex === index) {
              this.active_slider = removeIndex - 1;
            } else {
              this.active_slider = removeIndex;
            }
            this.activateTab(this.active_slider);
          } else if (data.type === "exist") {
            let room_index = this.getRoomIndex(data.room.id);
            this.activateTab(room_index + 2, data.room);
          } else {
            if (this.chat_rooms.length <= 1) {
              setTimeout(() => {
                this.slider.update();
                this.activateTab(3, data.room);
              }, 500);
            } else {
              this.activateTab(index, data.room);
            }
          }
        });
      }, 100);
    });

    this.eventService.chatNotificationDispatcher.subscribe((room_id) => {
      if (!this.isActiveRoom(room_id)) {
        for (let i = 0; i < this.chat_rooms.length; i++) {
          if (this.chat_rooms[i].id === room_id) {
            this.chat_rooms[i].highlight = true;
            break;
          }
        }
      }
    });

    this.loadNotification();

    this.eventService.notificationDispatcher.subscribe((data) => {
      if (data.type === "read") {
        this.notifications = [];
      } else if (data.type === "new") {
        this.notifications.push(data.notification);
      }
    });
  }

  setInnerHeight() {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  }

  ngAfterViewInit() {
    this.setInnerHeight();
    this.didInit = true;
    this.updateSlider();
  }

  updateSlider() {
    setTimeout(() => {
      this.slider.update();
    }, 5000);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public switchChatTabByArrows() {
    window.addEventListener("keydown", (event) => {
      const code = event.keyCode;

      this.getActiveIndex();
      this.getTotalTabs();

      if (code === 37 && this.currentIndex > 0) {
        this.slider.slidePrev();
      }

      if (code === 39 && this.currentIndex < this.totalTabs) {
        this.slider.slideNext();
      }
    });
  }

  public getActiveIndex() {
    this.slider.getActiveIndex().then((index) => {
      this.active_slider = this.currentIndex = index;
    });
  }

  public getTotalTabs() {
    this.slider.length().then((length) => {
      this.totalTabs = length;
    });
  }

  public isActiveRoom(room_id) {
    if (this.active_slider > 1) {
      let room = this.getRoomByIndex(this.active_slider);
      return room && room.id == room_id;
    }
  }

  public getRoomByIndex(index) {
    return this.chat_rooms[index - 2];
  }

  public getRoomIndex(room_id) {
    for (let i = 0; i < this.chat_rooms.length; i++) {
      if (this.chat_rooms[i].id == room_id) {
        return i;
      }
    }
    return 0;
  }

  public activateTab(index, room = null) {
    if (room) {
      room.highlight = false;
    }
    this.active_slider = index;
    setTimeout(() => {
      this.slider.slideTo(index);
    }, 100);
  }

  public slideNewTab(parent, event) {
    this.slider.getActiveIndex().then((index) => {
      this.active_slider = index;
      this.keyboard.hide();
      let room = this.getRoomByIndex(index);
      if (room) {
        room.highlight = false;
        if (parent) {
          (
            document.getElementsByClassName(
              "message-input-" + room.id
            )[0] as any
          ).focus();
        }
      }
    });
  }

  loadNotification() {
    this.userService.notifications().subscribe((resp) => {
      if (resp.success) {
        this.notifications = resp.notifications;
      }
    });
  }

  public loadRooms() {
    this.chatRoomService.categoryRooms("all").subscribe(
      (resp) => {
        if (resp.success && resp.all) {
          this.chat_rooms = resp.all.map((room) => {
            return { id: room.id, name: this.roomName(room) };
          });
        }
        this.updateSlider();
      },
      (err) => {}
    );
  }

  public addNewRoom(room) {
    let existing = this.chat_rooms.filter((croom) => croom.id === room.id);
    if (existing.length <= 0) {
      this.chat_rooms.push({ id: room.id, name: this.roomName(room) });
    }
  }

  public roomName(room) {
    let room_name = room.name;
    if (room.type === "Personal") {
      room_name = room_name.replace("Personal:", "");
      let participants = room_name.split("_");
      room_name =
        participants[0] === this.helperService.username()
          ? participants[1]
          : participants[0];
    }
    return room_name;
  }

  public removeRoom(room) {
    let index = this.getRoomIndex(room.id);
    this.chat_rooms.splice(index, 1);
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.setInnerHeight();
  }
}
