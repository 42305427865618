import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { TransferService } from "src/services/transfer.service";

@Component({
  selector: "app-send-sticker",
  templateUrl: "./send-sticker.component.html",
  styleUrls: ["./send-sticker.component.scss"],
})
export class SendStickerComponent implements OnInit {
  @Input() imgPath: string;

  constructor(
    private popoverController: PopoverController,
    private transferService: TransferService
  ) {}

  ngOnInit() {}

  sendAsSticker() {
    this.popoverController.dismiss();
    this.transferService.setStickerData({
      imgPath: this.imgPath,
    });
  }
}
