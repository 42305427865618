import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  // canActivate determine whether the customer has logged in or not
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      let auth = window.localStorage.getItem("auth");
      let parse_auth = JSON.parse(auth);
      if (parse_auth && parse_auth.auth_token) {
        resolve(true);
      } else {
        window.location.href = "/login";
        resolve(false);
      }
    });
  }
}
