import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { emojiItems } from "../assets/js/emojis.js";

@Injectable({
  providedIn: "root",
})
export class EmojiService {
  private emojiTabs = [
    "/assets/emoji/uni_0.png",
    "/assets/emoji/uni_102.png",
    "/assets/emoji/emotion_0.png",
    "/assets/emoji/black_0.png",
    "/assets/emoji/batman_0.png",
    "/assets/emoji/thumb_0.png",
  ];

  private URL = "https://api.github.com/emojis";

  constructor(private http: HttpClient) {}

  getStickers(): Observable<Object> {
    return this.http.get<Object>(this.URL).pipe(catchError(this.handleError()));
  }

  getEmojiTabs() {
    return this.emojiTabs;
  }

  getEmojis() {
    return emojiItems;
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
}
