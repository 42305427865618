import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-leader-board",
  templateUrl: "./leader-board.component.html",
  styleUrls: ["./leader-board.component.scss"],
})
export class LeaderBoardComponent implements OnInit {
  leader_boards = [];

  constructor() {}

  ngOnInit() {
    // this.loadLeaderBoard();
  }

  // public loadLeaderBoard() {
  //   this.spinner.show("", "Loading...");
  //   this.userService.leaderBoard().subscribe(
  //     (resp) => {
  //       this.spinner.hide();
  //       this.leader_boards = resp.leader_board;
  //     },
  //     (err) => {
  //       this.spinner.hide();
  //     }
  //   );
  // }

  leaderBoards = [
    {
      title: "All Games",
      description: "LowCard, Dice, Millionaire and Cricket",
      imgUrl: "assets/images/AllGames.svg",
      type: "All",
      official: false,
      isWeekly: false,
      room: "all the rooms",
    },
    {
      title: "iNwe Official LowCard",
      description:
        "Winner's leaderboard for LowCard game played in iNwe Official LowCard ",
      imgUrl: "assets/images/LowCard.svg",
      type: "LowCard",
      official: true,
      isWeekly: false,
      room: "iNwe Official LowCard room",
    },
    {
      title: "iNwe Official LowCard Weekly",
      description:
        "Winner's leaderboard for LowCard game played in iNwe Official LowCard ",
      imgUrl: "assets/images/LowCard.svg",
      type: "LowCard",
      official: true,
      isWeekly: true,
      room: "iNwe Official LowCard room",
    },
    {
      title: "LowCard",
      description:
        "Winner's leaderboard for LowCard game played in all the rooms ",
      imgUrl: "assets/images/LowCard.svg",
      type: "LowCard",
      official: false,
      isWeekly: false,
      room: "all the rooms",
    },
    {
      title: "Millionaire",
      description:
        "Winner's leaderboard for Millionaire game played in all the rooms ",
      imgUrl: "assets/images/AllGames.svg",
      type: "Millionaire",
      official: false,
      isWeekly: false,
      room: "all the rooms",
    },
    {
      title: "iNwe Official Cricket",
      description:
        "Winner's leaderboard for Cricket game played in iNwe Official Cricket room",
      imgUrl: "assets/images/Cricket.svg",
      type: "Cricket",
      official: true,
      isWeekly: false,
      room: "iNwe Official Cricket room",
    },
    {
      title: "iNwe Official Cricket Weekly",
      description:
        "Winner's leaderboard for Cricket game played in iNwe Official Cricket room",
      imgUrl: "assets/images/Cricket.svg",
      type: "Cricket",
      official: true,
      isWeekly: true,
      room: "iNwe Official Cricket room",
    },
    {
      title: "Cricket",
      description:
        "Winner's leader board for Cricket game played in all the rooms",
      imgUrl: "assets/images/Cricket.svg",
      type: "Cricket",
      official: false,
      isWeekly: false,
      room: "all the rooms",
    },
    {
      title: "iNwe Official Dice",
      description:
        "Winner's leaderboard for Dice game played in iNwe Official Dice room",
      imgUrl: "assets/images/Dice.svg",
      type: "Dice",
      official: true,
      isWeekly: false,
      room: "iNwe Official Dice room",
    },
    {
      title: "iNwe Official Dice Weekly",
      description:
        "Winner's leaderboard for Dice game played in iNwe Official Dice room",
      imgUrl: "assets/images/Dice.svg",
      type: "Dice",
      official: true,
      isWeekly: true,
      room: "iNwe Official Dice room",
    },
    {
      title: "Dice",
      description:
        "Winner's leader board for Dice game played in all the rooms",
      imgUrl: "assets/images/Dice.svg",
      type: "Dice",
      official: false,
      isWeekly: false,
      room: "all the rooms",
    },
    {
      title: "Wheel",
      description:
        "Winner`s leader board for Wheel game played in all the rooms",
      imgUrl: "assets/game/inwe without selection.png",
      type: "Wheel",
      official: false,
      isWeekly: false,
      room: "all the rooms",
    },
    {
      title: "UNO",
      description:
        "Winner`s leader board for UNO game played in all the rooms",
      imgUrl: "assets/images/uno.svg",
      type: "Uno",
      official: false,
      isWeekly: false,
      room: "all the rooms",
    },
    {
      title: "Call Bridge",
      description:
        "Winner`s leader board for Bridge game played in all the rooms",
      imgUrl: "assets/images/call_bridge.png",
      type: "Bridge",
      official: false,
      isWeekly: false,
      room: "all the rooms",
    },
    {
      title: "Call Bridge Weekly",
      description:
        "Winner`s leader board for Bridge game played in all the rooms",
      imgUrl: "assets/images/call_bridge.png",
      type: "Bridge",
      official: false,
      isWeekly: true,
      room: "all the rooms",
    },
    // {
    //   title: "Gift",
    //   description: "Top gift sender, receiver leaderboard",
    //   imgUrl: "assets/images/AllGames.svg",
    //   type: "Gift",
    //   official: null,
    //   isWeekly: null,
    // },
    {
      title: " Legend Leader Board",
      description: "Legend Leaderboard for Daily Award Ranking",
      imgUrl: "assets/images/Legend.svg",
      type: "Legend",
      official: null,
      isWeekly: null,
    },
  ];
}
