import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/services/loader.service";
import { NotificationService } from "src/services/notification.service";
import { UserService } from "src/services/user.service";

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"],
})
export class AlertsComponent implements OnInit {
  alerts = [];
  pager = 0;
  load_more_data = false;

  constructor(
    private userService: UserService,
    public spinner: LoaderService,
    public notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.getUserAlerts();
  }

  getUserAlerts(): void {
    this.spinner.show("", "Loading alerts");
    this.userService.getAlerts(this.pager).subscribe(
      (resp) => {
        this.spinner.hide();
        const { notifications } = resp;
        if (notifications.length > 0) {
          this.alerts = this.alerts.concat(
            this.notificationService.extractImgUrl(notifications)
          );
          this.load_more_data = true;
        } else {
          this.load_more_data = false;
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  public loadMoreData(event) {
    if (this.load_more_data) {
      this.pager += 1;
      this.getUserAlerts();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
      this.load_more_data = false;
    } else {
      event.target.complete();
    }
  }
}
