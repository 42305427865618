import { Component, Input, OnInit } from "@angular/core";
import { ChatRoomService } from "src/services/chat-room.service";
import { EventService } from "src/services/event.service";
import { HelperService } from "src/services/helper.service";
import { LoaderService } from "src/services/loader.service";

@Component({
  selector: "app-room-section",
  templateUrl: "./room-section.component.html",
  styleUrls: ["./room-section.component.scss"],
})
export class RoomSectionComponent implements OnInit {
  @Input() title: string;
  @Input() param: string;
  @Input() rooms: any[];
  expanded = true;

  constructor(
    public roomService: ChatRoomService,
    public spinnerDialog: LoaderService,
    public helperService: HelperService,
    private eventService: EventService
  ) {}

  ngOnInit() {}

  public reloadRooms(category) {
    this.spinnerDialog.show("", "Loading...");
    this.roomService.categoryRooms(category).subscribe(
      (resp) => {
        this.spinnerDialog.hide();
        if (resp.success) {
          this.reloadResults(category, resp[category]);
        } else {
          this.helperService.showAlert(resp.errors);
        }
      },
      (err) => {
        this.spinnerDialog.hide();
      }
    );
  }

  public gotoChat(room_id) {
    let active_rooms = this.helperService.rooms();
    let active_room = active_rooms.filter((room) => room.id == room_id);
    if (active_room.length > 0) {
      this.eventService.emitChatRoom("exist", active_room[0]);
    } else {
      this.spinnerDialog.show("", "Joining chat room...");
      this.roomService.joinChatRoom(room_id).subscribe(
        (resp) => {
          this.spinnerDialog.hide();
          if (resp.success) {
            this.helperService.addRoom(resp.chat_room);
          } else {
            this.helperService.showToast(resp.errors);
          }
        },
        (err) => {
          this.spinnerDialog.hide();
          this.helperService.showToast("Unable to load chat room this moment!");
        }
      );
    }
  }

  private reloadResults(category, result) {
    switch (category) {
      case "game": {
        this.rooms = result;
        break;
      }
      case "official": {
        this.rooms = result;
        break;
      }
      case "recent": {
        this.rooms = result;
        break;
      }
      case "random": {
        this.rooms = result;
        break;
      }

      case "own_rooms": {
        this.rooms = result;
        break;
      }
    }
  }
}
