import {Injectable} from '@angular/core';
import {Base} from './base';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class BlockService {
    api_url = Base.api_url;

    constructor(public http: HttpClient) {
    }

    public block(id): Observable<any> {
        return this.http.post(this.api_url + '/friendship/block_user', {user_id: id}, Base.requestHeader());
    }

    public unBlock(id) {
        return this.http.post(this.api_url + '/friendship/unblock_user', {user_id: id}, Base.requestHeader());
    }

    public list(): Observable<any> {
        return this.http.get(this.api_url + '/friendship/blocked_lists', Base.requestHeader());
    }
}
