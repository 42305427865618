import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor() {}

  public setImageType(type: string): string {
    let fileName: string;

    if (
      ["friend", "transfer", "send_gift", "moderator", "level_up"].includes(
        type
      )
    ) {
      fileName = type;
    } else if (["received_gift", "tag_trail"].includes(type)) {
      fileName = "received_gift";
    } else if (["remove_merchant", "reject_merchant"].includes(type)) {
      fileName = "merchant";
    } else if (type === "game") {
      fileName = "AllGames";
    } else {
      fileName = "alerts";
    }

    return `/assets/images/${fileName}.svg`;
  }

  public extractImgUrl(data: any[]): any[] {
    for (const item of data) {
      const text = item.text;
      const imgTxt = text.substring(
        text.lastIndexOf("<img"),
        text.lastIndexOf(">") + 1
      );
      item.text = text.replace(imgTxt, "").trim();
      const imgUrl = imgTxt.substring(
        text.lastIndexOf("https://"),
        text.indexOf("' ")
      );
      item.imgUrl = imgUrl;
      item.altImgUrl = this.setImageType(item.notification_type);
    }

    return data;
  }
}
